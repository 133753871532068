import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popover from '@material-ui/core/Popover';
import { useForm, Controller } from 'react-hook-form';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { DropzoneArea } from 'material-ui-dropzone';
import { connect } from 'react-redux';
import axios from "../VisionServices/axios";
import CloseCase from "./CloseCase";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Avatar from '@material-ui/core/Avatar';
import Divider from "@material-ui/core/Divider";
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import IconButton from '@material-ui/core/IconButton';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import DocxIcon from '../Assets/docx.svg';
import XlsxIcon from '../Assets/xlsx.svg';
import PptxIcon from '../Assets/pptx.svg';
import ImageIcon from '../Assets/image.svg';
import FileIcon from "../Assets/file.svg";
import PDFIcon from "../Assets/pdf.svg";
import ZipIcon from "../Assets/zip.svg";
import PageNotFound from "../PageNotFound/PageNotFound"
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommentIcon from '@material-ui/icons/Comment';
import EmailIcon from '@material-ui/icons/Email';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Badge from '@material-ui/core/Badge';
import { Alert, AlertTitle } from '@material-ui/lab/';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import './Cases.css';
Quill.register('modules/imageResize', ImageResize);

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  container: {
    padding: theme.spacing(2)
  },
  commentsSummary: {
    userSelect: 'text',
    alignItems: 'baseline',
    wordBreak: 'break-word',
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  innerContainer: {
    margin: 0,
    padding: "0 !important",
    alignContent: "flex-start",
  },
  fileIcon: {
    width: "48px",
    height: "auto",
  },
  textAlignRight: {
    textAlign: "right",
  },
  greenBackground: {
    backgroundColor: "#429644",
  },
  blueBackground: {
    backgroundColor: "#2aa9e0",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  summaryPreview: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
  fileName: {
    overflowWrap: 'anywhere',
  },
  caseDescription: {
    whiteSpace: 'pre-wrap',
  },
  overflowEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  spacerGrid: {
    flexGrow: 1,
    flexShrink: 1,
  },
  hidden: {
    display: 'none',
  },
  error: {
    color: '#ED1B34',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    padding: theme.spacing(2),
    maxWidth: '600px',
    margin: theme.spacing(0, 2, 0, 0)
  },
  editor: {
    background: 'rgba(0, 0, 0, 0.09)',
    borderRadius: '4px',
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: '177px',
      },
    },
  }
});

function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 MB';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

function CaseDetails(props) {
  const classes = useStyles();
  const [expandAll, setExpandAll] = useState([]);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [permission, setPermission] = useState(null);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [submittingComment, setSubmittingComment] = useState(false);
  const [resetDropzone, setResetDropzone] = useState(false);
  const [caseDetailsData, setCaseDetailsData] = useState([]);
  const [caseNotesData, setCaseNotesData] = useState([]);
  const [files, setFiles] = useState([]);
  const [attachmentData, setAttachmentData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updatingCase, setUpdatingCase] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const supportedFileTypes = [
    'image/*', 'video/*', 'audio/*',
    '.pdf', '.doc', '.docx', '.odt', '.tex', '.txt', '.wpd',
    '.xls', '.xlsm', '.xlsx', '.csv',
    '.key', '.odp', '.pps', '.ppt', '.pptx', '.ods',
    '.7z', '.arj', '.deb', '.pkg', '.rar', '.rpm', '.tar.gz', '.z', '.zip',
    '.email', '.eml', '.emlx', '.msg', '.oft', '.ost', '.pst', '.vcf',
  ]

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'img' && node.attribs["data-attachment-id"]) {
      node.attribs.style = 'width:auto;height:auto;max-width:100%'
      node.attribs.width = 'auto'
      node.attribs.height = 'auto'
      node.attribs.src = attachmentData[node.attribs["data-attachment-id"]]
      return convertNodeToElement(node, index, transform);
    }

    if (node.type === 'tag' && node.name === 'img' && !node.attribs["data-attachment-id"]) {
      node.attribs.style = node.attribs["style"] + 'cursor:auto;height:auto;max-width:100%'
      node.attribs.height = 'auto'
      return convertNodeToElement(node, index, transform);
    }
  }

  const options = {
    decodeEntities: true,
    transform
  };

  const loadCaseDetails = () => {
    setLoadingDetails(true);
    axios
      .get("/cases/" + props.match.params.caseId)
      .then((response) => {
        document.title = `${response.data[0].ticketnumber} | Vision Customer Portal`;
        setCaseDetailsData(response.data[0]);
        setLoadingDetails(false);
      })
      .catch((error) => {
        setLoadingDetails(false)
        console.log(error)
      });
  };

  const loadNotes = (isLoadmore = false) => {
    isLoadmore ? setLoadingComments(true) : setLoadingNotes(true);
    reset();
    axios
      .get("/Comments/" + props.match.params.caseId)
      .then((response) => {
        setCaseNotesData(response.data);
        var newAttachmentData = {}
        response.data.forEach(mimeData => {
          mimeData.mimeattachments && mimeData.mimeattachments.forEach((x) => {
            newAttachmentData[x.activitymimeattachmentid] = 'data:' + x.mimetype + ';base64,' + x.body;
          })
        })

        setAttachmentData(newAttachmentData);
        isLoadmore ? setLoadingComments(false) : setLoadingNotes(false);
      })
      .catch((error) => {
        isLoadmore ? setLoadingComments(false) : setLoadingNotes(false);
        console.log(error)
      });
  }

  const downloadFile = (filename, fileid) => {
    const data = {
      _objectid_value: fileid,
      filename: filename
    };

    const config = {
      onDownloadProgress: function (progressEvent) {
        console.log(progressEvent)
      },
    }

    axios.post("/attachment", data, config)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          var link = document.getElementById('dwnldLnk');
          var objUrl = URL.createObjectURL(dataURItoBlob(response.data));
          link.href = objUrl;
          link.download = filename;
          link.click();
        }
      });
  }

  const dataURItoBlob = (dataURI) => {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  }

  const handleChange = (panel) => (event, isExpanded) => {
    if (!isExpanded) {
      const filteredIndex = expandAll.filter((index) => index !== panel)
      setExpandAll(filteredIndex);
    }
    else {
      setExpandAll([...expandAll, panel]);
    }
  };

  const handleChangeAll = () => (event) => {
    if (expandAll.length === 0) {
      let indexes = caseNotesData.map(
        (noteValue, noteIndex) =>
          noteIndex
      )
      setExpandAll(indexes);
    } else {
      setExpandAll([]);
    }
  };

  const { errors, control, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      comment: "",
    }
  });

  const handleFiles = (files) => {
    const totalSize = files.reduce((total, file) => {
      return total + file.size;
    }, 0)
    setTotalFileSize(totalSize)
    setValue('totalFileSize', totalSize, { shouldValidate: true })
    setFiles(files);
  }

  const onSubmit = (data) => {
    setSubmittingComment(true)
    let formData = new FormData()
    formData.set('noteId', caseDetailsData.incidentid);
    formData.set('noteDescription', data.comment);
    files.forEach(file => {
      formData.append('attachments', file);
    });
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(percentCompleted)
      }
    }

    axios.post("Comments/createComments", formData, config)
      .then((response) => {
        loadNotes()
        if (files.length > 0) {
          setFiles([]);
          setResetDropzone(true);
          setResetDropzone(false);
        }
        setSubmittingComment(false);
        setUploadProgress(0);
      })
      .catch((error) => {
        console.log(error)
        setSubmittingComment(false);
      })
      .then(() => {
        reset();
      });
  }

  const { errors: errors2, control: control2, handleSubmit: handleEdit, reset: reset2 } = useForm();

  const handleEditMode = () => {
    setEditMode(!editMode);
  }

  const onUpdate = (data) => {
    setUpdatingCase(true)

    const caseUpdateData = {
      incidentId: caseDetailsData.incidentid,
      hsal_clientreference: data.clientReference,
      hsal_relatedurl: data.relatedURL,
    };

    axios.post("Cases/updateCase", caseUpdateData)
      .then((response) => {
        loadCaseDetails()
        setUpdatingCase(false);
        setEditMode(false);
      })
      .catch((error) => {
        setUpdatingCase(false);
      });
  }

  const fileIcon = (filename) => {
    let fileIcon = "";
    let nameArray = filename?.split(".");
    let fileExtension = nameArray[nameArray?.length - 1];
    switch (fileExtension) {
      case 'docx': case 'doc': case 'odt':
        fileIcon = DocxIcon;
        break;
      case 'pdf':
        fileIcon = PDFIcon;
        break;
      case 'png': case 'jpg': case 'jpeg': case 'bmp': case 'gif': case 'svg':
        fileIcon = ImageIcon;
        break;
      case 'xlsx':
        fileIcon = XlsxIcon;
        break;
      case 'pptx':
        fileIcon = PptxIcon;
        break;
      case 'zip':
        fileIcon = ZipIcon;
        break;
      default:
        fileIcon = FileIcon;
    }
    return fileIcon;
  }

  const AttachmentButton = (props) => {
    let subnote = props.subnote;
    let index = props.index;
    let date = new Date(props.subnote.createdon).toLocaleString('en-US', { timeZoneName: 'short' })

    return (
      <Box key={index} display="flex" flexDirection="row" p={1} mr={1} mb={1} border={1} borderColor="grey.300" borderRadius="borderRadius" maxWidth="300px">
        <Box><img src={fileIcon(subnote.filename)} className={classes.fileIcon} alt={subnote.filename} /></Box>
        <Box display="flex" flexDirection="column">
          <Box className={classes.fileName} fontWeight="fontWeightMedium" >{subnote.filename}</Box>
          <Box display="flex" flexDirection="row">
            <Box flexGrow="1">{date.toLocaleString('en-US', { timeZoneName: 'short' })}</Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Box flexGrow="1" textAlign="right" alignSelf="flex-end">{bytesToSize(subnote.filesize)}</Box>
            <Box>
              <IconButton aria-label="download" size="small" onClick={(e) => downloadFile(subnote.filename, subnote._objectid_value)}>
                <SaveAltRoundedIcon fontSize="inherit" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ]
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    }
  }

  useEffect(() => {
    axios.get("Cases/CheckPermission/" + props.computedMatch.params.caseId)
      .then((response) => {
        if (response.data === true) {
          setPermission(true);
          loadCaseDetails()
          loadNotes()
        } else {
          setPermission(false);
        }
      })
      .catch((error) => setPermission(false))
    // eslint-disable-next-line
  }, []);

  return permission === null ? (
    <LinearProgress />
  ) : permission === false ? (
    <PageNotFound />
  ) : (
    <Container maxWidth="lg" className={classes.root}>
      <Paper>
        <form id="caseDetailForm" noValidate autoComplete="off" onSubmit={handleEdit(data => onUpdate(data))}>
          <Grid container spacing={2} className={classes.container}>
            <Grid item>
              <a id="dwnldLnk" className={classes.hidedata} />
              <Typography variant="subtitle2">Case ID</Typography>
              <Typography variant="h5">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : caseDetailsData.ticketnumber}</Typography>
            </Grid>
            <Grid item className={classes.spacerGrid}></Grid>
            {(!loadingDetails && caseDetailsData.status_text !== "Closed" && !submittingComment) && (
              <>
                {editMode && (
                  <>
                    <Grid item>
                      <Button variant="outlined" color="primary" disabled={updatingCase} onClick={handleEditMode}>Cancel</Button>
                    </Grid>
                    <Grid item>
                      <div className={classes.wrapper}>
                        <Button variant="contained" color="primary" type="submit" disabled={updatingCase}>Update Case</Button>
                        {updatingCase && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </Grid>
                  </>
                )}
                {!editMode && (
                  <>
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={handleEditMode}>Edit</Button>
                    </Grid>
                    <Grid item className={classes.textAlignRight}>
                      <CloseCase incidentid={caseDetailsData.incidentid} closedCase={() => loadCaseDetails()} />
                    </Grid>
                  </>
                )}
              </>
            )}

            <Grid item xs={12}>
              <Typography variant="subtitle2">Title</Typography>
              <Typography variant="h4">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : caseDetailsData.title}</Typography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={6}
              spacing={2}
              className={classes.innerContainer}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle2">Account</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.customer_text || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Project</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.project_text || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Case Type</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.casetype_text || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Priority</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.priority_text || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Account Priority</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.customer_severity || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">3rd Party Reference</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.hsal_3rdpartyreference || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                {(loadingDetails || !editMode) && <Typography variant="subtitle2">Client Reference</Typography>}
                <Typography variant="body1">
                  {loadingDetails ? (
                    <Skeleton animation="wave" width="100%" />
                  ) : editMode ? (
                    <Controller as={TextField}
                      fullWidth
                      size="small"
                      variant="filled"
                      id="client-reference"
                      name="clientReference"
                      label="Client Reference"
                      defaultValue={caseDetailsData.hsal_clientreference}
                      control={control2}
                    />
                  ) : (
                    caseDetailsData.hsal_clientreference || '----------'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {(loadingDetails || !editMode) && <Typography variant="subtitle2">Related URL</Typography>}
                <Typography variant="body1" className={classes.overflowEllipsis}>
                  {loadingDetails ? (
                    <Skeleton animation="wave" width="100%" />
                  ) : editMode ? (
                    <Controller as={TextField}
                      fullWidth
                      size="small"
                      variant="filled"
                      id="related-url"
                      name="relatedURL"
                      label="Related URL"
                      defaultValue={caseDetailsData.hsal_relatedurl}
                      control={control2}
                    />
                  ) : caseDetailsData.hsal_relatedurl ? (
                    <Link href={caseDetailsData.hsal_relatedurl} target="_blank" rel="noreferrer">{caseDetailsData.hsal_relatedurl}</Link>
                  ) : (
                    '----------'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">DevOps Work Item ID</Typography>
                <Typography variant="body1">
                  {loadingDetails ? (
                    <Skeleton animation="wave" width="100%" />
                  ) : caseDetailsData.hsl_devopsworkitemid ? (
                    <Link href={caseDetailsData.hsl_devopsworkitemurl} target="_blank" rel="noreferrer">{caseDetailsData.hsl_devopsworkitemid}</Link>
                  ) : (
                    '----------'
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={6}
              spacing={2}
              className={classes.innerContainer}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle2">Status</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.status_text || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Final Estimate</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.new_changefinalestimate || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Hours Utilized</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.new_hoursutilized || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Budget Remaining</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.new_budgetremaining || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Account Contact</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.contact || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Hitachi Owner</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.fullname || '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Created On</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.createdon ? new Date(caseDetailsData.createdon).toLocaleString('en-US', { timeZoneName: 'short' }) : '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Last Updated</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.modifiedon ? new Date(caseDetailsData.modifiedon).toLocaleString('en-US', { timeZoneName: 'short' }) : '----------')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">Closed By</Typography>
                <Typography variant="body1">{loadingDetails ? <Skeleton animation="wave" width="100%" /> : (caseDetailsData.hsal_closedby || '----------')}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {(loadingDetails || loadingNotes) && (
                <>
                  <Typography variant="subtitle2">Attachments</Typography>
                  <Skeleton animation="wave" variant="rect" width={280} height={80} />
                </>
              )}
              {caseNotesData.find(note => note.description === "Attachments") &&
                (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Attachments</Typography>
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                      {caseNotesData.filter(note => note.description === "Attachments").map((note, index) => (
                        <>
                          {note.subnotes.map((subnote, index) => (
                            <AttachmentButton subnote={subnote} index={index} />
                          ))}
                        </>
                      ))}
                    </Box>
                  </Grid>
                )
              }
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Description</Typography>
              <Typography variant="body1">
                {loadingDetails ? (
                  <>
                    <Skeleton animation="wave" width="100%" />
                    <Skeleton animation="wave" width="100%" />
                  </>
                ) : (
                  ReactHtmlParser(caseDetailsData.description, options) || '----------'
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" >Comments</Typography>
            </Grid>
          </Grid>
        </form>
        {(!loadingDetails && caseDetailsData.status_text !== "Closed" && editMode) && (
          <Grid container spacing={2} justify="flex-end" className={classes.container}>
            <Grid item xs={12}>
              <Alert variant="outlined" severity="info" color="info">
                <AlertTitle>Adding a comment is disabled in the edit mode.</AlertTitle>
                To enable adding comments, Update Case or Cancel edit mode.
                <Grid container spacing={1}>
                  <Grid item>
                    <Button variant="outlined" color="primary" size="small" disabled={updatingCase} onClick={handleEditMode}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" color="primary" size="small" form="caseDetailForm" type="submit">
                      Update Case
                    </Button>
                  </Grid>
                </Grid>
              </Alert>
            </Grid>
          </Grid>
        )}
        {(!loadingDetails && caseDetailsData.status_text !== "Closed" && !editMode) && (
          <form noValidate autoComplete="off" onSubmit={handleSubmit(data => onSubmit(data))}>
            <Grid container spacing={2} justify="flex-end" className={classes.container}>
              <Grid item xs={12}>
                <FormControl fullWidth variant="filled" required error={!!errors.comment}>
                  <Controller
                    fullWidth
                    id="comment"
                    name="comment"
                    control={control}
                    rules={{
                      required: "Required",
                      minLength: { value: 12, message: "Description needs to be longer" }
                    }}
                    error={!!errors.comment}
                    as={
                      <ReactQuill
                        theme="snow"
                        className={`${classes.editor} ${!!errors.comment ? "error" : ""}`}
                        placeholder="Add a Comment *"
                        modules={modules}
                        readOnly={submittingComment}
                      />
                    }
                  />
                  <FormHelperText>{errors?.comment?.message}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {!resetDropzone &&
                  <>
                    <Typography className={errors?.totalFileSize && classes.error}>Total File Size: {bytesToSize(totalFileSize)} / 100MB</Typography>
                    <Button
                      color="primary"
                      size="small"
                      aria-owns={open ? 'mouse-over-popover' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    >
                      Supported File Types
                    </Button>
                    <Popover
                      id="mouse-over-popover"
                      className={classes.popover}
                      classes={{
                        paper: classes.popoverPaper,
                      }}
                      open={open}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <Typography variant="subtitle2">The following file types are supported:</Typography>
                      <Typography>{supportedFileTypes.join(', ')}</Typography>
                    </Popover>
                    <MuiThemeProvider theme={theme}>
                      <DropzoneArea
                        filesLimit={10}
                        maxFileSize={50000000}
                        dropzoneClass={errors?.totalFileSize && classes.error}
                        dropzoneText={errors?.totalFileSize && errors?.totalFileSize?.message}
                        showFileNamesInPreview={true}
                        showPreviewsInDropzone={false}
                        showPreviews={true}
                        onChange={(files) => handleFiles(files)}
                        acceptedFiles={supportedFileTypes}
                      />
                    </MuiThemeProvider>
                  </>
                }
                <Controller as={TextField}
                  required
                  fullWidth
                  className={classes.hidden}
                  type="number"
                  variant="filled"
                  id="total-file-size"
                  name="totalFileSize"
                  label="Total File Size"
                  control={control}
                  rules={{ required: "Required", max: { value: 100000000, message: "Total file size exceeding 100MB." } }}
                  error={!!errors.totalFileSize}
                  helperText={errors?.totalFileSize?.message}
                />
              </Grid>
              <Grid item>
                <div className={classes.wrapper}>
                  <Button variant="contained" color="primary" type="submit" disabled={loadingDetails || submittingComment}>
                    {submittingComment ? `${uploadProgress}%` : "Add Comment"}
                  </Button>
                  {submittingComment && <CircularProgress size={24} className={classes.buttonProgress} variant="determinate" value={uploadProgress} />}
                </div>
              </Grid>
            </Grid>
          </form>
        )}
        {caseNotesData.length > 1 && (
          <Grid container spacing={2} justify="flex-end" className={classes.container}>
            <Grid item >
              <Button onClick={handleChangeAll()} color="primary" >
                {expandAll.length === 0 ? "Expand All" : "Collapse All"}
              </Button>
            </Grid>
          </Grid>
        )}
        {loadingNotes && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.commentsSummary}
              aria-controls="comment-content"
              id="-1"
            >
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box mb={1} display="flex" flexDirection="row" alignItems="flex-end" flexWrap="wrap">
                  <Box mr={1}>
                    <Avatar />
                  </Box>
                  <Box mr={1}>
                    <Typography variant="h6"><Skeleton animation="wave" width={175} /></Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1"><Skeleton animation="wave" width={175} /></Typography>
                  </Box>
                </Box>
                <Box>
                  <Skeleton animation="wave" />
                </Box>
              </Box>
            </AccordionSummary>
          </Accordion>
        )}
        {caseNotesData.map((note, index) =>
        ((note.description === "Attachments" && note.subnotes.length > 0) ? null :
          <Accordion key={index} expanded={expandAll.indexOf(index) !== -1} onChange={handleChange(index)} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.commentsSummary}
              aria-controls="comment-content"
              id={index}
            >
              <Box display="flex" flexDirection="column">
                <Box mb={1} display="flex" flexDirection="row" alignItems="flex-end" flexWrap="wrap">
                  <Box mr={1} display="flex">
                    <Badge
                      overlap="circle"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      badgeContent={
                        note.subnotes?.length > 0 && <SmallAvatar className={classes.small} ><AttachFileIcon fontSize="small" /></SmallAvatar>
                      }
                    >
                      <Avatar className={note.activityname === "email" ? classes.blueBackground : classes.greenBackground}>
                        {note.activityname === "email" ? <EmailIcon /> : <CommentIcon />}
                      </Avatar>
                    </Badge>
                  </Box>
                  <Box mr={1} display="flex">
                    <Typography variant="h6">{note.activityname === "email" ? ReactHtmlParser(String(note.from).replace("&nbsp;", " "), options) : note.modifiedby}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="subtitle1">{new Date(note.createdon).toLocaleString('en-US', { timeZoneName: 'short' })}</Typography>
                  </Box>
                </Box>
                <Box display="flex" flex={1} whiteSpace="pre-wrap">
                  <Typography className={classes.summaryPreview}>{note.activityname === "email" ? (<><strong>Subject:</strong> {note.subject}</>) : (expandAll.indexOf(index) === -1 ? ReactHtmlParser(note.description, options) : null)}</Typography>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column" flex={1} width="100%" whiteSpace="pre-wrap">
                <Box><Typography>{ReactHtmlParser(note.description, options)}</Typography></Box>
                {note.subnotes?.length > 0 && (
                  <>
                    <Box mb={1} mt={1}>
                      <Divider />
                    </Box>
                    <Box display="flex" flexDirection="row" flexWrap="wrap">
                      {note.subnotes.map((subnote, index) => (
                        subnote.filename && <AttachmentButton subnote={subnote} index={index} />
                      ))}
                    </Box>
                  </>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        )
        )}
         {loadingComments && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.commentsSummary}
              aria-controls="comment-content"
              id="-1"
            >
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box mb={1} display="flex" flexDirection="row" alignItems="flex-end" flexWrap="wrap">
                  <Box mr={1}>
                    <Avatar />
                  </Box>
                  <Box mr={1}>
                    <Typography variant="h6"><Skeleton animation="wave" width={175} /></Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1"><Skeleton animation="wave" width={175} /></Typography>
                  </Box>
                </Box>
                <Box>
                  <Skeleton animation="wave" />
                </Box>
              </Box>
            </AccordionSummary>
          </Accordion>
        )}
        {caseNotesData.length > 4 && (
          <Grid container spacing={2} justify="flex-end" className={classes.container}>
            <Grid item >
              <Button onClick={() => loadNotes(true)} color="primary" >
                Load More
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    isCustomer: state.auth.role === "customer",
    customerCompanyID: state.auth.authCompany,
  };
};
export default connect(mapStateToProps, null)(CaseDetails);