import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import axios from '../VisionServices/axios';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='100%' height='100%'><defs><pattern id='pillPattern' x='10' y='10' width='116' height='60' patternUnits='userSpaceOnUse' patternTransform='rotate(-30)' ><rect x='0' y='0' width='96' height='40' ry='20' rx='20' style='stroke: none; fill:%23e2e5e7; opacity: .25' /></pattern></defs><rect x='0' y='0' width='100%25' height='100%' style='fill: url(%23pillPattern);' /></svg>")`,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 56px)',
    },
    minHeight: 'calc(100vh - 64px)',
    paddingTop: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#FFFFFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function RequestPassword(props) {
  const classes = useStyles();
  const [passwordSent, setPasswordSent] = React.useState(false);
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      email: props.location.state.userName,
    }
  });

  const [userName, setUserName] = React.useState(props.location.state.userName);

  const handleFormSubmit = (event) => {
    if (!loading) {
      setLoading(true);
      setInvalidEmail(false);
      const authData = {
        userName: userName
      };
      axios.post("/Security/RequestPassword", authData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (response.data === "SUCCESS") {
              // alert("password sent");
              setPasswordSent(true);
            }
            if (response.data === "FAILED") {
              // alert("email not exist")
              setLoading(false);
              setInvalidEmail(true);
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error)
        });
    }
  }

  return (
    <div className={classes.root}>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={3}>
          <Typography component="h1" variant="h5">
            Request Password
          </Typography>
          {passwordSent ? (
            <Typography variant="body1">A temporary password has been sent to your email. Click <Link component={RouterLink} to="/">
              here
          </Link> to go back to the login page.</Typography>
          ) : (
              <form className={classes.form} onSubmit={handleSubmit(handleFormSubmit)} noValidate>
                <TextField
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  type="email"
                  name="email"
                  autoComplete="email"
                  onChange={e => { setUserName(e.target.value); }}
                  inputRef={register({
                    required: { value: true, message: "Email address is required" },
                    pattern: { value: /^\S+@\S+$/i, message: "Please enter a valid email address" }
                  })}
                  error={!!errors.email || invalidEmail}
                  helperText={errors?.email?.message}
                />
                {invalidEmail && (<Typography variant="body2">The email address provided is invalid. Please check and try again. Contact <Link href="mailto:vision@hitachisolutions.com">
                  vision@hitachisolutions.com​ </Link> if you need further assistance.</Typography>)}
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.submit}
                  >
                    Request Password
                </Button>
                  {loading && <CircularProgress size={32} className={classes.buttonProgress} />}
                </div>
              </form>
            )}
        </Paper>
      </Container>
    </div>
  );
}

export default RequestPassword;