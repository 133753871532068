import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from "@material-ui/core/Paper";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

  },
  heading: {
    width: "100%",
    textAlign: "center",
  },
  panelSummary: {
    padding: 0,
    userSelect: 'text',
  },
  panelDetails: {
    padding: 0,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  expandIconExpanded: {
    transform: "rotate(180deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  tableHeading: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    color: "#616161",
    backgroundColor: "#ffffff",
  },
}));

function CasesByOwner(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  let tableData = props.tableData;
  let startDate = new Date(props.startDate).toLocaleDateString();
  let endDate = new Date(props.endDate).toLocaleDateString();
  let csmName = props.csmName || "";
  let customerName = props.customerName || "";
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const RowSkeleton = () => {
    return (
      <TableRow>
        <TableCell component="th" scope="row"><Skeleton animation="wave" /></TableCell>
        <TableCell align="right"><Skeleton animation="wave" /></TableCell>
      </TableRow>
    )
  }

  const TableSkeleton = () => {
    return (
      <>
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
      </>
    )
  }

  const OwnerTableRows = (props) => {
    return (
      <>
        {props.tableData.map((owner,index) => (
          <TableRow key={index}>
            <TableCell component="th" scope="row">
            <Link 
              component={RouterLink} 
              to={
                "/cases?owner=" + owner.Owner.toString()+
                "&startdate=" + startDate + 
                "&enddate=" + endDate +
                "&csm=" + csmName +
                "&customer=" + customerName +
                "&activeonly=false"
              }
            >
              {owner.Owner}
            </Link>
            </TableCell>
            <TableCell align="right">{owner.Count}</TableCell>
          </TableRow>
        ))}
      </>
    )
  }

  const OwnerTable = (props) => {
    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Owner</TableCell>
                <TableCell align="right">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData ? <OwnerTableRows tableData={tableData} /> : <TableSkeleton />}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  const TableWrapper = (props) => {
    return (
      <Paper className={classes.title}>
        <Typography
          className={classes.tableHeading}
          variant="h6"
          component="h6"
        >
          Cases By Owner
        </Typography>
        <OwnerTable tableData={tableData} />
      </Paper>
    )
  }

  if (tableData === null || tableData.length <= 20) {
    return (
      <TableWrapper tableData={tableData} />
    )
  } else {
    return (
      <Accordion className={classes.root} expanded={expanded === 'ownerpanel'} onChange={handleChange('ownerpanel')}>
        <AccordionSummary
          className={classes.panelSummary}
          aria-controls="ownerpanel-content"
          id="ownerpanel-header"
        >
          <Box display="flex" flexDirection="column" className={classes.heading} >
            <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column" flexGrow="1">
                <Typography variant="h6" component="h6">
                  Cases By Owner
                </Typography>
                <Typography variant="subtitle1">
                  {expanded ? "All Owners" : "Top 10"}
                </Typography>
              </Box>
              <Box>
                <IconButton>
                  <ExpandMoreIcon className={expanded ? classes.expandIconExpanded : classes.expandIcon} />
                </IconButton>
              </Box>
            </Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Owner</TableCell>
                    <TableCell align="right">Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <OwnerTableRows tableData={tableData.slice(0, 10)} />
                  { !expanded ? (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right">Show More...</TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.panelDetails}>
          <TableContainer>
            <Table>
              <TableBody>
                <OwnerTableRows tableData={tableData.slice(11)} />
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    )
  }
}

export default CasesByOwner;