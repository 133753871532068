import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import axios from '../VisionServices/axios';
import Link from '@material-ui/core/Link';
import { InputLabel } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#FFFFFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function RequestUserPassword(props) {
  const classes = useStyles();
  const [invalidEmail, setInvalidEmail] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [userName, setUserName] = React.useState([]);
  const [temporaryPassword, setTemporaryPassword] = React.useState(null)

  const { register, errors, handleSubmit } = useForm({
    defaultValues: {
      email: userName,
    }
  });

  const handleFormSubmit = (event) => {
    if (!loading) {
      setLoading(true);
      const authData = {
        userName: userName
      };
      axios.post("/Security/RequestUserPassword", authData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (response.data !== "FAILED") {
              setTemporaryPassword(response.data);
              setInvalidEmail(false);
            }
            if (response.data === "FAILED") {
              // alert("email not exist")
              setInvalidEmail(true);
            }
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error)
        });
    }
  }

  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <Paper className={classes.paper} elevation={3}>
        <Typography component="h1" variant="h5">
          Request User Password
          </Typography>
        <form className={classes.form} onSubmit={handleSubmit(handleFormSubmit)} noValidate>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            type="email"
            name="email"
            autoComplete="email"
            onChange={e => { setUserName(e.target.value); }}
            inputRef={register({
              required: { value: true, message: "Email address is required" },
              pattern: { value: /^\S+@\S+$/i, message: "Please enter a valid email address" }
            })}
            error={!!errors.email || invalidEmail}
            helperText={errors?.email?.message}
          />
          {invalidEmail && (<Typography variant="body2">The email address provided is invalid. Please check and try again. Contact <Link href="mailto:vision@hitachisolutions.com">
            vision@hitachisolutions.com​ </Link> if you need further assistance.</Typography>)}
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
            >
              Generate User Password
                </Button>
            {loading && <CircularProgress size={32} className={classes.buttonProgress} />}
          </div>
        </form>
        {temporaryPassword && (
          <FormControl variant="filled" size="small">
            <InputLabel htmlFor="temporary-password" shrink>Temporary Password</InputLabel>
            <FilledInput
              id="temporary-password"
              type="text"
              inputProps={{
                readOnly: true,
              }}
              value={temporaryPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => { navigator.clipboard.writeText(temporaryPassword) }}
                    edge="end"
                  >
                    <FileCopyRoundedIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        )}
      </Paper>
    </Container>
  );
}

export default RequestUserPassword;