import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import axios from "../VisionServices/axios";
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  labelIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  changePassword: {
    display: "flex",
    alignItems: "flex-end",
  },
  submit: {
    textAlign: 'right',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Profile(props) {
  const classes = useStyles();
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  
  const { errors, control, handleSubmit, reset } = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      phone: "",
      city: "",
      stateprovince: "",
      country: "",
      aboutme: "",
    }
  });

  const loadProfileData = () => {
    setLoading(true)
    axios
      .get("/profile")
      .then((response) => {
        reset({
          firstname: response.data[0].firstname || "",
          lastname: response.data[0].lastname || "",
          phone: response.data[0].mobilephone || "",
          city: response.data[0].address1_city || "",
          stateprovince: response.data[0].address1_stateorprovince || "",
          country: response.data[0].address1_country || "",
          aboutme: response.data[0].hsal_aboutme || "",
        })
        setProfileData(response.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const onSubmit = (data) => {
    setUpdating(true)
    const formData = {
      firstname: data.firstname,
      lastname: data.lastname,
      mobilephone : data.phone,
      address1_city : data.city ,
      address1_stateorprovince: data.stateprovince,
      address1_country: data.country,
      hsal_aboutme : data.aboutme,
    };
    axios.post("Profile/UpdateProfile", formData)
      .then((response) => {
        setUpdating(false)
        setOpenSuccess(true)
      })
      .catch((error) => {
        console.log(error)
        setUpdating(false)
        setOpenError(true)
      });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false)
    setOpenError(false)
  };

  useEffect(() => {
    document.title = "Profile | Vision Customer Portal";
  });
  
  useEffect(() => {
    loadProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  return (
    <Container component="main" maxWidth="md" className={classes.root}>
      <Paper className={classes.paper}>
        {loading ? (
          <>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box mr={1}><Skeleton variant="circle" width={40} height={40} /></Box>
                  <Box flexGrow={1}><Typography variant="h4" ><Skeleton animation="wave" /></Typography></Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Box mr={1}><Skeleton variant="circle" width={40} height={40} /></Box>
                  <Box flexGrow={1}><Typography variant="body1" ><Skeleton animation="wave" /></Typography></Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton animation="wave" width="100%">
                  <TextField variant="filled" margin="dense" />
                </Skeleton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton animation="wave" width="100%">
                  <TextField variant="filled" margin="dense" />
                </Skeleton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Skeleton animation="wave" width="100%">
                  <TextField variant="filled" margin="dense" />
                </Skeleton>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" width="100%">
                  <TextField variant="filled" margin="dense" />
                </Skeleton>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" width="100%">
                  <TextField variant="filled" margin="dense" />
                </Skeleton>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton animation="wave" width="100%">
                  <TextField variant="filled" margin="dense" />
                </Skeleton>
              </Grid>
              {!props.isCustomer &&
                <Grid item xs={12}>
                  <Skeleton animation="wave" width="100%">
                    <TextField 
                      multiline
                      rows={6}
                      margin="dense"
                      variant="filled"
                    />
                  </Skeleton>
                </Grid>
              }
              <Grid item xs={6}>
                <Skeleton animation="wave" width="100%"><Button>Change Password</Button></Skeleton>
              </Grid>
              <Grid item xs={6} className={classes.submit}>
                <Skeleton animation="wave" width="100%"><Button>Update Profile</Button></Skeleton>
              </Grid>
            </Grid>
          </>
        ) : (
          profileData.map((profile, index) => (
            <form key={index} noValidate autoComplete="off" onSubmit={handleSubmit(data => onSubmit(data))}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h4" >
                    <BusinessRoundedIcon className={classes.labelIcon} />{profile.company}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" >
                    <EmailRoundedIcon className={classes.labelIcon} />{profile.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller as={TextField} 
                    required
                    fullWidth
                    variant="filled"
                    margin="dense"
                    id="first-name"
                    name="firstname"
                    label="First Name"
                    control={control}
                    rules={{required: "Required"}}
                    error={!!errors.firstname}
                    helperText={errors?.firstname?.message} 
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller as={TextField} 
                    required
                    fullWidth
                    variant="filled"
                    margin="dense"
                    id="last-name"
                    name="lastname"
                    label="Last Name"
                    control={control}
                    rules={{required: "Required"}}
                    error={!!errors.lastname}
                    helperText={errors?.lastname?.message} 
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller as={TextField} 
                    fullWidth
                    variant="filled"
                    margin="dense"
                    id="phone"
                    name="phone"
                    label="Phone"
                    control={control}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <Controller as={TextField} 
                    fullWidth
                    variant="filled"
                    margin="dense"
                    id="city"
                    name="city"
                    label="City"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller as={TextField} 
                    fullWidth
                    variant="filled"
                    margin="dense"
                    id="state-province"
                    name="stateprovince"
                    label="State/Province"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller as={TextField} 
                    fullWidth
                    variant="filled"
                    margin="dense"
                    id="country"
                    name="country"
                    label="Country"
                    control={control}
                  />
                </Grid>
                {!props.isCustomer &&
                  <Grid item xs={12}>
                    <Controller as={TextField} 
                      fullWidth
                      multiline
                      rows={6}
                      variant="filled"
                      margin="dense"
                      id="about-me"
                      name="aboutme"
                      label="About Me"
                      control={control}
                      helperText={"This will be shown in the MY CSM page for your customers."} 
                    />
                  </Grid>
                }
                <Grid item xs={6} className={classes.changePassword}>
                  <Link component={RouterLink} to="/change-password/">
                    Change Password
                  </Link>
                </Grid>
                <Grid item xs={6} className={classes.submit}>
                  <span className={classes.wrapper}>
                    <Button variant="contained" color="primary" type="submit" disabled={updating} >
                      Update Profile
                    </Button>
                    {updating && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </span>
                  <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                      Profile updated successfully!
                    </Alert>
                  </Snackbar>
                  <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                      Error updating profile. Please try again.
                    </Alert>
                  </Snackbar>
                </Grid>
                <Grid item xs={12} className={classes.updatemessage}>
                  <span id="message"></span>
                </Grid>
              </Grid>
            </form>
          ))
        )}
      </Paper>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    isCustomer : state.auth.role  === "customer",
    customerCompanyID : state.auth.authCompany,
  };
};
export default connect( mapStateToProps, null )(Profile);