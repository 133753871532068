import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#991B1F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#111111',
    },
    concrete: {
      main: '#E2E5E7',
    },
    error: {
      main: '#ED1B34',
    },
    white: {
      main: '#FFFFFF'
    },
    text: {
      primary: '#616161',
    }
  },
  typography: {
    fontFamily: 'Fira Sans, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Fira Sans'],
      },
    },
  },
});

export default theme;