import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import axios from "../VisionServices/axios";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useForm, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function CloseCase(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [closingCase, setClosingCase] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { errors, control, handleSubmit } = useForm({
    defaultValues: {
      resolution: "",
      remarks: "",
    }
  });

  const handleCaseClose = (data) => {
    setClosingCase(true)
    const caseCloseData = {
      subject: data.resolution,
      description: data.remarks,
      incidentId : props.incidentid ,
    };
    axios.post("Cases/CloseCase", caseCloseData)
      .then((response) => {
        console.log(response)
        setOpen(false);
        setClosingCase(false)
        props.closedCase();
      })
      .catch((error) => {
        setClosingCase(false)
        console.log(error)
      });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Close Case
      </Button>
      <Dialog open={open} disableBackdropClick aria-labelledby="close-case">
        <DialogTitle id="close-case">Close Case</DialogTitle>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(data => handleCaseClose(data))}>
          <DialogContent>
            <DialogContentText>
              <Typography variant="subtitle2">Note:</Typography>
              <Typography variant="body1">This action will resolve the case. Should you need to re-open the case, contact your CSM.</Typography>
            </DialogContentText>
            <Controller as={TextField} 
              required
              fullWidth
              variant="filled"
              margin="normal"
              id="resolution"
              name="resolution"
              label="Resolution"
              control={control}
              rules={{required: "Required"}}
              error={!!errors.resolution}
              helperText={errors?.resolution?.message} 
            />
            <Controller as={TextField}
              fullWidth
              multiline
              rows={3}
              variant="filled"
              margin="normal"
              id="remarks"
              name="remarks"
              label="Remarks"
              control={control}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary" disabled={closingCase}>
              Cancel
            </Button>
            <div className={classes.wrapper}>
              <Button variant="contained" color="primary" type="submit" disabled={closingCase}>
                Close Case
              </Button>
              {closingCase && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default CloseCase;