import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import HitachiLogo from '../hitachi-logo.svg';
import StarsRoundedIcon from '@material-ui/icons/StarsRounded';
import ReactGA from 'react-ga';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '33.33%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  cardTitle: {
    padding: theme.spacing(2),
    color: "#FFFFFF",
    backgroundColor: "#F26722",
    opacity: "0.95",
  },
  logo: {
    height: "20px",
  }
}));

const offerings = [
  {featured: false, title: 'Managed Services', tagline: '', subtitle: 'Expert support for all your Microsoft products', description: 'We’ve come a long way since the days of traditional break/fix IT. However, most internal IT teams still aren’t equipped to keep up with the pace of innovation. Fortunately, Managed Services Providers (MSPs) have made it easy to stay ahead of the curve in adopting and maintaining new technology.', img: 'https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/public/datasheet-managed-services.png', url: 'https://global.hitachi-solutions.com/-/media/Files/Data-Sheets/DataSheet-ManagedServices_0620.pdf'},
  {featured: false, title: 'Operational Runbook', tagline: 'Managed Services:', subtitle: '', description: 'Every digital transformation solution requires support after the implementation goes live — are you prepared? Hitachi Solutions Managed Services can help. We’re able to create a personalized Operational Runbook for your post-implementation solution.', img: 'https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/public/operational-runbook.jpg', url: 'https://global.hitachi-solutions.com/-/media/Files/Offers/Offer-Managed-Services-Operational-Runbook_0221.pdf'},
  {featured: false, title: 'O365/M365 Managed Support', tagline: 'Managed Services:', subtitle: '', description: 'Modern devices that are easy to deploy, manage, and protect can reduce IT costs while making employees more productive. Hosting services on Microsoft Office 365/Microsoft 365 allows devices to perform better and are always up to date. The key challenge with achieving this modernization goal is finding the right blend of support for your ever-growing Microsoft 365 environment.', img: 'https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/public/o365-m365-managed-support.jpg', url: 'https://global.hitachi-solutions.com/-/media/Files/Offers/Offer-Managed-Services-O365-Support-0121-Final.pdf'},
  {featured: false, title: 'Infrastructure Assessment', tagline: 'Managed Services:', subtitle: '', description: 'Networks play a key role in supporting mission-critical business operations. If they become unavailable or unreliable, employee productivity, customer satisfaction, and the bottom line are negatively impacted. As applications evolve and technologies converge, networks are often required to carry more traffic with less margin of error. Even a well-managed, well-maintained network today may become an issue tomorrow. With so much on the line, you don’t want to wait until your network tells you it needs help.', img: 'https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/public/infrastructure-assessment.jpg', url: 'https://global.hitachi-solutions.com/-/media/Files/Offers/Offer-Managed-Services-Infrastructure-Assess-0620-Final.pdf'},
  {featured: false, title: 'NOC/SOC Monitoring', tagline: 'Managed Services:', subtitle: '', description: 'Whether you’re supporting a network operations center (NOC), security operations center (SOC), or both, you’re under constant pressure to maintain customer privacy, defend company IP, and ensure business continuity. Failure to do so could result in lost confidence, customers, and revenue. With ever evolving and daily security threats, the need for consistent uptime, and shrinking in-house resources, the best way to stay a step ahead is with dedicated NOC/SOC support. We have the right workload security and performance monitoring solution to give you the protection you need 24/7.', img: 'https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/public/noc-soc-monitoring.jpg', url: 'https://global.hitachi-solutions.com/-/media/Files/Offers/Offer-Managed-Services-NOC-SOC-Monitor-0620_Final.pdf'},
  {featured: false, title: 'Monitoring Solution', tagline: 'Managed Services:', subtitle: '', description: 'In an age when software solutions are being constructed and developed using the latest cloud technologies, gaining visibility and maintaining enterprise systems has become an increasingly bigger challenge for IT operations. Our Monitoring Solution provides centralized monitoring that can be viewed holistically from a single screen. Through a 24/7 operations center and dedicated service desk with telephone and remote support, we continuously monitor your most critical resources, provide proactive alert management, have specialized add on services, and more.', img: 'https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/public/monitoring-solution.jpg', url: 'https://global.hitachi-solutions.com/-/media/Files/Offers/Offer-Managed-Services-Monitoring-Services-0620_Final.pdf'},
  {featured: false, title: 'SQL Database Administration', tagline: 'Managed Services:', subtitle: '', description: 'Hitachi Solutions provides you with the Microsoft SQL Server expertise needed to take you from strategic planning through day-to-day operations. Your dedicated Microsoft SQL Server team is complemented by our cloud-certified professionals who have extensive server experience with Microsoft Azure (Azure SQL, SQL Data Warehouse, and Data Lake) and the Amazon (RDS) Cloud Platform. You’ll have access to their advanced support when and where you need them.', img: 'https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/public/sql-database-administration.jpg', url: 'https://global.hitachi-solutions.com/-/media/Files/Offers/Offer-Managed-Services-SQL-Database-Admin-0620_Final.pdf'},
];

function Offerings(props) {
  const classes = useStyles();
  useEffect(() => {
    document.title = "Offerings | Vision Customer Portal";
    return () => document.title = "Vision Customer Portal";
  });

  const event = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };

  return (
    <Container component="main" maxWidth="lg" className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                Offerings
              </Typography>
              <Typography variant="body2" color="textPrimary" component="p">
                Learn how your business can leverage Microsoft technologies to be more productive, serve customers better and improve the bottom line.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {offerings.map((offering) => (
          <Grid item key={offering.id} xs={12} sm={6}>
            <Card className={classes.card}>
              <CardMedia
                className={classes.cardMedia}
                image={offering.img}
                title={offering.title}
              >
                <Paper elevation={0} square className={classes.cardTitle}>
                  <Box display="flex">
                    <Box display="flex" flex={1}>
                      <img src={HitachiLogo} className={classes.logo} alt={offering.title} />
                    </Box>
                    <Box display="flex" flex={1} justifyContent="flex-end">
                      <Box position="absolute">{offering.featured && <StarsRoundedIcon />}</Box>
                    </Box>
                  </Box>
                  <Typography variant="subtitle2">{offering.tagline}</Typography>
                  <Typography variant="h5">
                    {offering.title}
                  </Typography>
                  <Typography variant="body2">{offering.subtitle}</Typography>
                </Paper>
              </CardMedia>
              <CardContent className={classes.cardContent}>
                <Typography>
                  {offering.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button color="primary" variant="outlined" href={offering.url} onClick={() => event("Offerings", "click", offering.title)} target="_blank">
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Offerings;