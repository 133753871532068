import React from "react";
import Chart from "react-google-charts";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";

const options = {
  fontName: "Fira Sans",
  fontSize: 16,
  slices: [
    { color: "#991B1F" },
    { color: "#F26722" },
    { color: "#FFD500" },
    { color: "#82C341" },
    { color: "#2aa9e0" },
    { color: "#1E4799" },
    { color: "#891D5A" },
    { color: "#A53E23" },
    { color: "#FBAA23" },
    { color: "#FEE180" },
    { color: "#429644" },
    { color: "#B7D433" },
    { color: "#16214B" },
    { color: "#9C4A70" },
    { color: "#A53E23" },
    { color: "#F493AD" },
    { color: "#E1EBAE" },
    { color: "#1F4A23" },
    { color: "#E2E5E7" },
    { color: "#16214B" },
    { color: "#3A1126" },
  ],
  legend: {
    position: "top",
    maxLines: 4,
    alignment: "center",
    textStyle: {
      color: "#616161",
    },
  },
  tooltip: {
    showColorCode: true,
  },
  chartArea: {
    top: "15%",
    width: "90%",
    height: "75%",
  },
}

export default function PieChart(props) {
  let history = useHistory();
  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper.getDataTable();
          const { row } = selectedItem;
          history.push("/cases?" + props.groupBy + "=" + dataTable.getValue(row, 0) +
            "&startdate=null&enddate=null" +
            (props.customerName === "" ? "" : "&customer=" + encodeURIComponent(props.customerName)) +
            (props.projectName ? "&project=" + encodeURIComponent(props.projectName) : "")
          );
        }
      },
    },
  ]
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="40vh"
      data={props.chartData}
      options={options}
      chartEvents={chartEvents}
      loader={<Skeleton animation="wave" variant="rect" width="100%" height="40vh" />}
    />
  );
}