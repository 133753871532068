import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from "@material-ui/core/Paper";
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    fontSize: 'large',
  },
  heading: {
    width: "100%",
    textAlign: "center",
  },
  panelSummary: {
    padding: 0,
    userSelect: 'text',
  },
  panelDetails: {
    padding: 0,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  expandIconExpanded: {
    transform: "rotate(180deg)",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  tableHeading: {
    padding: theme.spacing(2, 2, 0),
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    color: "#616161",
    backgroundColor: "#ffffff",
  },
  columnHeader: {
    fontWeight: 500,
  },
}));

function CasesByOwner(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  let tableData = props.tableData?.filter(owner => owner.ActiveCount > 0);
  let customerName = props.customerName;
  let projectName = props.projectName;
  let loading = props.tableData === null;
  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  return loading ? (
    <Paper className={classes.title}>
      <Typography className={classes.tableHeading} variant="h6" component="h6" >
        Active Cases by Owner
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography className={classes.columnHeader}>Owner</Typography></TableCell>
              <TableCell align="right"><Typography className={classes.columnHeader}>Cases</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from(Array(11).keys()).map((index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row"><Typography><Skeleton animation="wave" /></Typography></TableCell>
                <TableCell align="right"><Typography><Skeleton animation="wave" /></Typography></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  ) : tableData?.length < 20 ? (
    <Paper className={classes.title}>
      <Typography className={classes.tableHeading} variant="h6" component="h6" >
        Active Cases by Owner
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography className={classes.columnHeader}>Owner</Typography></TableCell>
              <TableCell align="right"><Typography className={classes.columnHeader}>Cases</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.length === 0 ? (
              <TableRow>
                <TableCell component="th" scope="row"><Typography>No Data</Typography></TableCell>
                <TableCell align="right"><Typography>No Data</Typography></TableCell>
              </TableRow>
            ) : (
                tableData?.map((row) => (
                  <TableRow key={row.Owner}>
                    <TableCell component="th" scope="row">
                      <Typography>
                        <Link
                          component={RouterLink}
                          to={
                            "/cases?owner=" + row.Owner.toString() +
                            "&startdate=null&enddate=null" +
                            (customerName === "" ? "" : ("&customer=" + encodeURIComponent(customerName))) +
                            (projectName ? "&project=" + encodeURIComponent(projectName) : "")
                          }
                        >
                          {row.Owner}
                        </Link>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        <Link
                          component={RouterLink}
                          to={
                            "/cases?owner=" + row.Owner.toString() +
                            "&startdate=null&enddate=null" +
                            (customerName === "" ? "" : ("&customer=" + encodeURIComponent(customerName))) +
                            (projectName ? "&project=" + encodeURIComponent(projectName) : "")
                          }
                        >
                          {row.ActiveCount}
                        </Link>
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  ) : (
        <Accordion className={classes.root} expanded={expanded} onChange={handleChange}>
          <AccordionSummary className={classes.panelSummary} aria-controls="ownerpanel-content" id="ownerpanel-header">
            <Box display="flex" flexDirection="column" className={classes.heading} >
              <Box display="flex" flexDirection="row" mx={1}>
                <Box display="flex" flexDirection="column" flexGrow="1">
                  <Typography variant="h6" component="h6">
                    Active Cases by Owner
              </Typography>
                  <Typography variant="subtitle1">
                    {expanded ? "All Owners" : "Top 10"}
                  </Typography>
                </Box>
                <Box>
                  <IconButton aria-label="show more active cases by owner">
                    <ExpandMoreIcon className={expanded ? classes.expandIconExpanded : classes.expandIcon} />
                  </IconButton>
                </Box>
              </Box>
              <TableContainer>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell><Typography className={classes.columnHeader}>Owner</Typography></TableCell>
                      <TableCell align="right"><Typography className={classes.columnHeader}>Cases</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData?.slice(0, 10).map((row) => (
                      <TableRow key={row.Owner}>
                        <TableCell component="th" scope="row">
                          <Typography>
                            <Link
                              component={RouterLink}
                              to={
                                "/cases?owner=" + row.Owner.toString() +
                                "&startdate=null&enddate=null" +
                                (customerName === "" ? "" : ("&customer=" + encodeURIComponent(customerName))) +
                                (projectName ? "&project=" + encodeURIComponent(projectName) : "")
                              }
                            >
                              {row.Owner}
                            </Link>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography>
                            <Link
                              component={RouterLink}
                              to={
                                "/cases?owner=" + row.Owner.toString() +
                                "&startdate=null&enddate=null" +
                                (customerName === "" ? "" : ("&customer=" + encodeURIComponent(customerName))) +
                                (projectName ? "&project=" + encodeURIComponent(projectName) : "")
                              }
                            >
                              {row.ActiveCount}
                            </Link>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    {!expanded && (
                      <TableRow>
                        <TableCell colSpan={2} align="right"><Typography>Show More...</Typography></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </AccordionSummary>
          <AccordionDetails className={classes.panelDetails}>
            <TableContainer>
              <Table>
                <TableBody>
                  {tableData?.slice(11).map((row) => (
                    <TableRow key={row.Owner}>
                      <TableCell component="th" scope="row" >
                        <Typography>
                          <Link
                            component={RouterLink}
                            to={
                              "/cases?owner=" + row.Owner.toString() +
                              "&startdate=null&enddate=null" +
                              (customerName === "" ? "" : ("&customer=" + encodeURIComponent(customerName))) +
                              (projectName ? "&project=" + encodeURIComponent(projectName) : "")
                            }
                          >
                            {row.Owner}
                          </Link>
                        </Typography>
                      </TableCell>
                      <TableCell align="right" >
                        <Typography>
                          <Link
                            component={RouterLink}
                            to={
                              "/cases?owner=" + row.Owner.toString() +
                              "&startdate=null&enddate=null" +
                              (customerName === "" ? "" : ("&customer=" + encodeURIComponent(customerName))) +
                              (projectName ? "&project=" + encodeURIComponent(projectName) : "")
                            }
                          >
                            {row.ActiveCount}
                          </Link>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      )
}

export default CasesByOwner;