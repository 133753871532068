import React from "react";
import Chart from "react-google-charts";
import Skeleton from "@material-ui/lab/Skeleton";

const BarChart = (props) => {
  const data = props.tableData;
  let startDate = new Date(props.startDate);
  let endDate = new Date(props.endDate);
  let csmName = props.csmName || "";
  let customerName = props.customerName || "";
  
  // Normalize createdon date to the first day of the month for aggregation
  let casesByMonth = []
  data && data.forEach((row) => {
    let date = row.createdon
    casesByMonth.push([(new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1)).getTime(), row.priority])
  })

  // Get distinct Priority
  let priority = new Set()
  casesByMonth.map((caseItem, index) => (
    priority.add(caseItem[1])
  ))
  // Sort distinct Priority
  let sortedPriority = Array.from(priority).sort()

  // Get distinct normalized dates
  let date = new Set()
  casesByMonth.map((caseItem, index) => (
    date.add(caseItem[0])
  ))
  // Sort distinct normalized dates
  let sortedDate = Array.from(date).sort()

  // Create cases data table for cases groupby Month and Priority
  let caseGrid = []
  sortedDate.forEach((date, y) => {
    caseGrid.push([new Date(date).toLocaleDateString('en-US', {month: 'short', year: 'numeric'})])
    let casesThisMonth = casesByMonth.filter(caseItem => caseItem[0] === date)
    sortedPriority.forEach((priority, x) => {
      let currentPriorityCases = casesThisMonth.filter(caseItem => caseItem[1] === priority)
      caseGrid[y].splice(x+1,1,currentPriorityCases.length)
    })
  })
  // Create column headers for caseGrid
  let columns = [
    { type: 'string', label: 'Month' },
  ]
  sortedPriority.forEach((priority) => {
    columns.push({type:'number', label: priority})
  })

  // Combine column headers with cases data table
  let chartData = [columns, ...caseGrid]

  const options = {
    fontName: 'Fira Sans',
    legend: { 
      position: "top", 
      maxLines: 3,
      alignment: "center",
    },
    colors: [
      "#991B1F",
      "#F26722",
      "#FFD500",
      "#82C341",
      "#2aa9e0",
      "#1E4799",
      "#9C4A70",
      "#​ED1B34",
      "#FBAA23",
      "#FEE180",
      "#429644",
      "#B7D433",
      "#16214B",
      "#891D5A",
      "#A53E23",
      "#F493AD",
      "#E1EBAE",
      "#1F4A23",
      "#E2E5E7",
      "#16214B",
      "#3A1126",
    ],
    chartArea: {width: "85%"},
    orientation: "horizontal",
  };
  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper.getDataTable();
          const maxRow = dataTable.cache.length - 1
          const { row, column } = selectedItem;
          if (row !== null) {
            startDate = (row === 0 ? startDate : new Date(dataTable.getValue(row, 0)))
            endDate = (row === maxRow ? endDate :new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0))
          }
          window.location.href =
            "/cases?priority=" + dataTable.getColumnLabel(column) +
            "&startdate=" + startDate.toLocaleDateString() + 
            "&enddate=" + endDate.toLocaleDateString() +
            "&csm=" + csmName +
            "&customer=" + customerName +
            "&activeonly=false";
        }
      },
    },
  ];

  return data === null ? (
    <Skeleton animation="wave" variant="rect" width="100%" height="40vh" />
  ) : (
    <Chart
        chartType="BarChart"
        width="100%"
        height="40vh"
        data={chartData}
        options={options}
        chartEvents={chartEvents}
        loader={<Skeleton animation="wave" variant="rect" width="100%" height="40vh" />}
    />
  )
};

export default BarChart;
