import React, { useEffect, useRef } from 'react';
import { useReport } from 'powerbi-report-component';
import { makeStyles } from '@material-ui/core/styles';
import axios from "../VisionServices/axios";
import Skeleton from '@material-ui/lab/Skeleton';
const MicrosoftAXReports = (ReportId) => {
  const reportRef = useRef(null);
  const loadingreport = false;
  const [report, setEmbed] = useReport();
  const useStyles = makeStyles((theme) => ({
    report : {
      height : "80vh",
    }
  }));
 
  useEffect(() => {
    axios
    .get("/Monitoring?reportId="+ ReportId.ReportId + "&workspaceId=" +  ReportId.WorkSpaceId
     )
    .then((response) => {
      //setReportParameter(response.data);
      let accessToken = response.data.PowerBIToken;
      let embedUrl = response.data.EmbedUrl;
      let embedId = response.data.Id;
      const myReportConfig = {
        embedType: 'report',
        tokenType: 'Embed',
        accessToken: accessToken,
        embedUrl: embedUrl,
        embedId: embedId,
        reportMode: "View", // "Edit"
        permissions: "View", // "All" (when using "Edit" mode)
        extraSettings: {
          filterPaneEnabled: true,
          navContentPaneEnabled: true,
        },
      }
      setEmbed(reportRef, myReportConfig);
      loadingreport = true;
    })
    .catch((error) => {
      console.log(error)
    });
    // call inside useEffect so the we have the reportRef (reference available)
    
  }, []);
  
  const classes = useStyles();
  return (
    <div>
      {loadingreport ? <Skeleton animation="wave" variant="rect" width="100%" height="40vh"/> :
      <div className={classes.report} ref={reportRef} />
    }
    </div>
    
    
  );
};
export default MicrosoftAXReports;