import React, { useEffect } from 'react';
import HitachiLogo from './hitachi-logo.svg';
import Logo from './logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Hidden from '@material-ui/core/Hidden';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Link from '@material-ui/core/Link';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import Login from './Login/Login';
import RequestPassword from './RequestPassword/RequestPassword';
import RequestUserPassword from './RequestUserPassword/RequestUserPassword';
import Dashboard from './Dashboard/Dashboard';
import Cases from './Cases/Cases';
import NewCase from './Cases/NewCase';
import CaseDetails from './Cases/CaseDetails';
import MyCSM from './MyCSM/MyCSM';
import CSMStats from './CSMStats/CSMStats';
import Offerings from './Offerings/Offerings';
import Monitoring from './Monitoring/Monitoring';
import Environments from './Environments/Environments';
import Profile from './Profile/Profile';
import ChangePassword from './ChangePassword/ChangePassword';
import PageNotFound from './PageNotFound/PageNotFound';
import Logout from './Logout/Logout';
import {
  Switch,
  Route,
  NavLink,
  useLocation,
} from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from './store/actions/auth';
import ProtectedRoute from './ProtectedRoute';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: '1300'
  },
  appLogo: {
    height: '25px',
    marginTop: '6px',
  },
  toolbarTabs: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  toolbarTab: {
    color: '#FFFFFF !important',
    textDecoration: 'none !important',
  },
  toolbarMenuButton: {
    whiteSpace: 'nowrap',
    borderRadius: '21px'
  },
  toolbarMenuItem: {
    textDecoration: 'none !important',
    color: 'inherit !important',
  },
  tabWrapper: {
    flexDirection: 'row',
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
  };
}

function App(props) {
  props.usePageViews();
  let location = useLocation();
  useEffect(() => {
    props.onTryAutoSignup();
  });

  useEffect(() => {
    props.onTryAutoSignup();
    setValue(props.authenticate && filteredNavigation.findIndex(tab => location.pathname.startsWith(tab.url)))
  }, [location, props]);

  const handleLogout = () => {
    props.onLogout();
  };

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigation = [
    {
      label: "Dashboard", url: "/dashboard/", path: "/dashboard/",
      exact: true, routeOnly: false, component: Dashboard,
      forAdmin: true, forCSM: true, forCustomer: true,
    },
    {
      label: "Cases", url: "/cases/", path: "/cases/",
      exact: true, routeOnly: false, component: Cases,
      forAdmin: true, forCSM: true, forCustomer: true,
    },
    {
      label: "My CSM", url: "/my-csm/", path: "/my-csm/",
      exact: true, routeOnly: false, component: MyCSM,
      forAdmin: false, forCSM: false, forCustomer: true,
    },
    {
      label: "CSM Stats", url: "/csm-stats/", path: "/csm-stats/",
      exact: true, routeOnly: false, component: CSMStats,
      forAdmin: true, forCSM: false, forCustomer: false,
    },
    {
      label: "Monitoring", url: "/monitoring/", path: "/monitoring/",
      exact: true, routeOnly: false, component: Monitoring,
      forAdmin: true, forCSM: true, forCustomer: true, forMonitoring: true,
    },
    {
      label: "Environments", url: "/environments/", path: "/environments/:environmentName?/:environmentId?/",
      exact: false, routeOnly: false, component: Environments,
      forAdmin: true, forCSM: true, forCustomer: true, forEnvironments: true,
    },
    {
      label: "CSP", url: "https://hs.cspcontrolcenter.net/", path: "/csp/",
      exact: false, routeOnly: false, externalLink: true, component: PageNotFound,
      forAdmin: true, forCSM: false, forCustomer: true, forCSP: true,
    },
    {
      label: "Offerings", url: "/offerings/", path: "/offerings/",
      exact: false, routeOnly: false, component: Offerings,
      forAdmin: true, forCSM: true, forCustomer: true,
    },
  ]

  const filteredNavigation = props.authenticate && navigation.filter(nav =>
    (props.isAdmin && nav.forAdmin) ||
    (props.isCSM && nav.forCSM) ||
    (props.isCustomer && nav.forCustomer &&
      (nav.forMonitoring !== undefined ? nav.forMonitoring === props.enableMonitoring : true) &&
      (nav.forEnvironments !== undefined ? nav.forEnvironments === props.enableEnvision : true) &&
      (nav.forCSP !== undefined ? nav.forCSP === props.enableCSP : true)
    )
  )

  return (
    <div className="App">
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <NavLink to="/" >
            <Hidden smDown>
              <img src={HitachiLogo} width="213.02px" height="25px" className={classes.appLogo} alt="logo" />
            </Hidden>
            <Hidden mdUp>
              <img src={props.authenticate ? Logo : HitachiLogo} className={classes.appLogo} alt="logo" />
            </Hidden>
          </NavLink>
          {props.authenticate && (
            <Tabs className={classes.toolbarTabs}
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="navigation tabs"
            >
              {props.authenticate && filteredNavigation.map((tab, index) =>
                tab.externalLink ?
                  <Tab
                    key={index} label={<>{tab.label} <OpenInNewRoundedIcon fontSize="small" /></>}
                    value={index} component={Link} href={tab.url} target="_blank" rel="noreferrer"
                    className={classes.toolbarTab} aria-label={tab.label} {...a11yProps(index)}
                    classes={{
                      wrapper: classes.tabWrapper,
                    }}
                  /> :
                  <Tab
                    key={index} label={tab.label}
                    value={index} component={NavLink} to={tab.url}
                    className={classes.toolbarTab} aria-label={tab.label} {...a11yProps(index)}
                  />
              )}
            </Tabs>
          )}

          {props.authenticate && (
            <div>
              <Hidden smDown>
                <Button
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  size="large"
                  startIcon={<AccountCircle />}
                  className={classes.toolbarMenuButton}
                >
                  {props.name ? props.name : ""}
                </Button>
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Hidden>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose} component={NavLink} to="/profile/" className={classes.toolbarMenuItem}>Profile</MenuItem>
                {props.isAdmin && <MenuItem onClick={handleClose} component={NavLink} to="/request-user-password/" className={classes.toolbarMenuItem}>Request User Password</MenuItem>}
                <MenuItem onClick={handleClose} component={Link} href="https://hmsportalstorage.blob.core.windows.net/hmsstagingcontainer/public/Vision%20User%20Guide.pdf" target="_blank" rel="noreferrer" className={classes.toolbarMenuItem}>User Guide</MenuItem>
                <MenuItem onClick={handleClose} component={NavLink} to="/logout/" className={classes.toolbarMenuItem}>Logout</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Switch>
        <Route exact path="/" render={(props) => <Login {...props} />} />
        <Route exact path="/request-password/" user={props.authenticate} render={(props) => <RequestPassword {...props} />} />
        {props.authenticate && filteredNavigation.map((route, index) =>
          <ProtectedRoute key={index} exact={route.exact} path={route.path} user={props.authenticate} component={route.component} />
        )}
        <ProtectedRoute exact path="/cases/new/" user={props.authenticate} component={NewCase} />
        <ProtectedRoute exact path="/cases/:caseId" user={props.authenticate} component={CaseDetails} />
        {props.isAdmin && <ProtectedRoute exact path="/request-user-password/" user={props.authenticate} component={RequestUserPassword} />}
        <ProtectedRoute exact path="/profile/" user={props.authenticate} component={Profile} />
        <ProtectedRoute exact path="/change-password/" user={props.authenticate} component={ChangePassword} />
        <ProtectedRoute exact path="/logout" user={props.authenticate} handleLogout={handleLogout} component={Logout} />
        <ProtectedRoute exact path="*" user={props.authenticate} component={PageNotFound} />
      </Switch>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    authenticate: state.auth.token !== null,
    isCustomer: state.auth.role === "customer",
    isAdmin: state.auth.role === "Admin",
    isCSM: state.auth.role === "Yes",
    name: state.auth.authName,
    getrole: state.auth.role,
    customerCompanyID: state.auth.authCompany,
    companyName: state.auth.authCompanyName,
    enableMonitoring: state.auth.workspace !== null && state.auth.workspace !== "" && state.auth.monitoringUser,
    enableEnvision: state.auth.envisionAccount && state.auth.envisionUser,
    microsoftID: state.auth.microsoftId,
    enableCSP: state.auth.cspAccount && state.auth.cspUser && !!state.auth.microsoftId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
    onLogout: () => dispatch(actions.logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
