import React, { useEffect, useState } from 'react';
import { makeStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Popover from '@material-ui/core/Popover';
import { useHistory } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';
import { connect } from 'react-redux';
import axios from "../VisionServices/axios";
import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
import './Cases.css';
Quill.register('modules/imageResize', ImageResize);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  hidden: {
    display: 'none',
  },
  error: {
    color: '#ED1B34',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverPaper: {
    padding: theme.spacing(2),
    maxWidth: '600px',
    margin: theme.spacing(0, 2, 0, 0)
  },
  editor: {
    background: 'rgba(0, 0, 0, 0.09)',
    borderRadius: '4px',
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiDropzoneArea: {
      root: {
        minHeight: '177px',
      },
    },
  }
});

function bytesToSize(bytes) {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 MB';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

function NewCase(props) {
  const classes = useStyles();
  let history = useHistory();
  const [files, setFiles] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [customerGuid, setCustomerGuid] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const supportedFileTypes = [
    'image/*', 'video/*', 'audio/*',
    '.pdf', '.doc', '.docx', '.odt', '.tex', '.txt', '.wpd',
    '.xls', '.xlsm', '.xlsx', '.csv',
    '.key', '.odp', '.pps', '.ppt', '.pptx', '.ods',
    '.7z', '.arj', '.deb', '.pkg', '.rar', '.rpm', '.tar.gz', '.z', '.zip',
    '.email', '.eml', '.emlx', '.msg', '.oft', '.ost', '.pst', '.vcf',
  ]

  const { errors, control, handleSubmit, setValue } = useForm({
    defaultValues: {
      customer: "",
      project: "",
      title: "",
      type: "",
      priority: "",
      relatedURL: "",
      clientReference: "",
      description: "",
    }
  });

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleFiles = (files) => {
    const totalSize = files.reduce((total, file) => {
      return total + file.size;
    }, 0)
    setTotalFileSize(totalSize)
    setValue('totalFileSize', totalSize, { shouldValidate: true })
    setFiles(files);
  };

  const onSubmit = (data) => {
    if (props.isCustomer) {
      data.customer = props.customerCompanyID;
    }
    setLoading(true);
    let formData = new FormData()
    formData.set('_customerid_value', data.customer);
    formData.set('projectId', data.project);
    formData.set('title', data.title);
    formData.set('hsal_casetype', data.type);
    formData.set('prioritycode', data.priority);
    formData.set('hsal_relatedurl', data.relatedURL);
    formData.set('hsal_clientreference', data.clientReference);
    formData.set('description', data.description);
    for (var x = 0; x < files.length; x++) {
      formData.append('attachments', files[x]);
    }

    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        setUploadProgress(percentCompleted)
      },
    }

    axios.post("Cases/CreateCase", formData, config)
      .then((response) => {
        if (response.data === "SUCCESS") {
          props.history.push("/cases");
        }
      })
      .catch((error) => {
        console.log(error)
        setLoading(false);
      });
  }
  const handleCustomerChange = (event) => {
    setCustomerGuid(event.target.value);
  };

  const loadCustomerList = () => {
    axios
      .get("/Accounts")
      .then((response) => {
        const newCustomerList = response.data.map((c) => {
          return {
            guid: c.Id,
            name: c.Name,
          };
        });
        setCustomerList(newCustomerList);
      })
      .catch((error) => console.log(error));
  }
  const loadProjectList = () => {
    axios
      .get("/Projects", {
        params: {
          customerId: customerGuid,
          createcases: true,
        }
      })
      .then((response) => {
        const newProjectList = response.data.map((c) => {
          return {
            guid: c.value,
            name: c.name,
          };
        });
        setProjectList(newProjectList);
      })
      .catch((error) => console.log(error));
  }

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
      ]
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar'],
    }
  }

  useEffect(() => {
    document.title = "New Case | Vision Customer Portal";
  });

  useEffect(() => {
    props.isCustomer ? loadProjectList() : loadCustomerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isCustomer]);

  useEffect(() => {
    customerGuid && loadProjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerGuid]);

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Paper className={classes.paper}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(data => onSubmit(data))}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" >
                New Case
              </Typography>
            </Grid>
            {!props.isCustomer &&
              <Grid item xs={12}>
                <FormControl fullWidth variant="filled" required error={!!errors.customer}>
                  <InputLabel id="customer-label">Account</InputLabel>
                  <Controller
                    as={
                      <Select displayEmpty>
                        {customerList.map((customer, index) => (
                          <MenuItem key={index} value={customer.guid} name={customer.name}>
                            {customer.name}
                          </MenuItem>
                        ))}
                      </Select>
                    }
                    labelId="customer-label"
                    id="customer"
                    name="customer"
                    control={control}
                    onChange={([event]) => {
                      handleCustomerChange(event);
                      return event.target.value
                    }}
                    rules={{ required: "Required" }}
                  />
                  <FormHelperText>{errors?.customer?.message}</FormHelperText>
                </FormControl>
              </Grid>
            }
            <Grid item xs={12}>
              <FormControl fullWidth variant="filled" required error={!!errors.project} disabled={projectList.length === 0}>
                <InputLabel id="project-label">Project</InputLabel>
                <Controller
                  as={
                    <Select
                      labelId="project-label"
                      id="project"
                      displayEmpty
                      defaultValue=""
                    >
                      {projectList.map((project, index) => (
                        <MenuItem key={index} value={project.guid} name={project.name}>
                          {project.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  labelId="project-label"
                  id="project"
                  name="project"
                  control={control}
                  rules={{ required: "Required" }}
                />
                <FormHelperText>{errors?.project?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller as={TextField}
                required
                fullWidth
                variant="filled"
                id="title"
                name="title"
                label="Title"
                control={control}
                rules={{ required: "Required" }}
                error={!!errors.title}
                helperText={errors?.title?.message || 'Give the case an unique title you will recognize.'}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="filled" required error={!!errors.type}>
                <InputLabel id="type-label">Type</InputLabel>
                <Controller
                  as={
                    <Select>
                      <MenuItem value={607540002}>Incident / Issue (Most cases are this)</MenuItem>
                      <MenuItem value={607540001}>New Enhancement / Modification to existing functionality</MenuItem>
                      <MenuItem value={607540003}>System Maintenance / Refresh / Configuration</MenuItem>
                    </Select>
                  }
                  labelId="type-label"
                  id="type"
                  name="type"
                  control={control}
                  rules={{ required: "Required" }}
                />
                <FormHelperText>{errors?.type?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="filled" required error={!!errors.priority}>
                <InputLabel id="priority-label">Priority</InputLabel>
                <Controller
                  as={
                    <Select>
                      <MenuItem value={100000000}>P5 - Systems are working. Advice needed</MenuItem>
                      <MenuItem value={607540000}>P4 - Systems are working. Enhancements needed</MenuItem>
                      <MenuItem value={3}>P3 - Non critical service / system not working. Medium to low business impact</MenuItem>
                      <MenuItem value={2}>P2 - Service/system partially working but severely reduced. Significant business impact</MenuItem>
                      <MenuItem value={1}>P1 - Service/system down. All users affected. Business operation halted</MenuItem>
                    </Select>
                  }
                  labelId="priority-label"
                  id="priority"
                  name="priority"
                  control={control}
                  rules={{ required: "Required" }}
                />
                <FormHelperText>{errors?.priority?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Controller as={TextField}
                fullWidth
                variant="filled"
                id="related-url"
                name="relatedURL"
                label="Related URL"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller as={TextField}
                fullWidth
                variant="filled"
                id="client-reference"
                name="clientReference"
                label="Client Reference"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="filled" required error={!!errors.description}>
                <Controller
                  fullWidth
                  id="description"
                  name="description"
                  control={control}
                  rules={{ required: "Required", minLength: { value: 12, message: "Description needs to be longer" } }}
                  error={!!errors.description}
                  as={
                    <ReactQuill
                      theme="snow"
                      className={`${classes.editor} ${!!errors.description ? "error" : ""}`}
                      placeholder="Description *"
                      modules={modules}
                    />
                  }
                />
                <FormHelperText>{errors?.description?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography className={errors?.totalFileSize && classes.error}>Total File Size: {bytesToSize(totalFileSize)} / 100MB</Typography>
              <Button
                color="primary"
                size="small"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                Supported File Types
              </Button>
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.popoverPaper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography variant="subtitle2">The following file types are supported:</Typography>
                <Typography>{supportedFileTypes.join(', ')}</Typography>
              </Popover>
              <MuiThemeProvider theme={theme}>
                <DropzoneArea
                  filesLimit={10}
                  maxFileSize={50000000}
                  dropzoneClass={errors?.totalFileSize && classes.error}
                  dropzoneText={errors?.totalFileSize && errors?.totalFileSize?.message}
                  showFileNamesInPreview={true}
                  showPreviewsInDropzone={false}
                  showPreviews={true}
                  onChange={(files) => handleFiles(files)}
                  acceptedFiles={supportedFileTypes}
                />
              </MuiThemeProvider>
              <Controller as={TextField}
                required
                fullWidth
                className={classes.hidden}
                type="number"
                variant="filled"
                id="total-file-size"
                name="totalFileSize"
                label="Total File Size"
                control={control}
                rules={{ required: "Required", max: { value: 100000000, message: "Total file size exceeding 100MB." } }}
                error={!!errors.totalFileSize}
                helperText={errors?.totalFileSize?.message}
              />
            </Grid>
            <Grid item container xs={12} justify="flex-end" >
              <Button color="primary" disabled={loading} onClick={() => history.goBack()}>
                Cancel
              </Button>
              <div className={classes.wrapper}>
                <Button variant="contained" color="primary" type="submit" disabled={loading} className={classes.submit}>
                  {loading ? `${uploadProgress}%` : "Create Case"}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} variant="determinate" value={uploadProgress} />}
              </div>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    isCustomer: state.auth.role === "customer",
    customerCompanyID: state.auth.authCompany,
  };
};
export default connect(mapStateToProps, null)(NewCase);