import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  columnHeader: {
    fontWeight: 500,
  },
}));

function CasesByStatus(props) {
  const classes = useStyles();
  let customerName = props.customerName;
  let projectName = props.projectName;
  let tableData = props.tableData?.filter(status => status.text.toString() !== "Closed")
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography className={classes.columnHeader}>Status</Typography></TableCell>
            <TableCell align="right"><Typography className={classes.columnHeader}>Cases</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData === null ? (
            Array.from(Array(11).keys()).map((index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row"><Typography><Skeleton animation="wave" /></Typography></TableCell>
                <TableCell align="right"><Typography><Skeleton animation="wave" /></Typography></TableCell>
              </TableRow>
            ))
          ) : tableData?.length === 0 ? (
            <TableRow>
              <TableCell component="th" scope="row"><Typography>No Data</Typography></TableCell>
              <TableCell align="right"><Typography>No Data</Typography></TableCell>
            </TableRow>
          ) : (
                tableData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Typography>
                        <Link
                          component={RouterLink}
                          to={
                            "/cases?status=" + row.text.toString() +
                            "&startdate=null&enddate=null" +
                            (customerName === "" ? "" : ("&customer=" + encodeURIComponent(customerName))) +
                            (projectName ? "&project=" + encodeURIComponent(projectName) : "")
                          }
                        >
                          {row.text}
                        </Link>
                      </Typography>
                    </TableCell>
                    <TableCell align="right"><Typography>{row.count}</Typography></TableCell>
                  </TableRow>
                ))
              )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default CasesByStatus;