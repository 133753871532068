import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from "../VisionServices/axios";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Skeleton from "@material-ui/lab/Skeleton";
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(16),
    paddingBottom: theme.spacing(3),
  },
  avatar: {
    width: "200px",
    height: "200px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "-25%",
    boxShadow: theme.shadows[2],
  },
  card: {
    overflow: "visible",
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
}));

export default function MyCSM(props) {
  const classes = useStyles();
  const [CSMs, setCSMs] = useState(null);

  useEffect(() => {
    loadCSM()
    // eslint-disable-next-line
  }, []);


  const loadCSM = () => {
    axios
      .get("/CSMs/MyCsm")
      .then((response) => {
        setCSMs(response.data);
      })
      .catch((error) => console.log(error));
  }

  return (
    <Container component="main" maxWidth="md" className={classes.root}>
      <Grid container justify="center" spacing={3}>
        {CSMs ? (
          CSMs?.map((csm, index) => (
            <Grid key={index} item xs={6}>
              <Card className={classes.card}>
                <CardContent>
                  <Avatar
                    alt={csm.fullname}
                    src={csm.image}
                    className={classes.avatar}
                  />
                </CardContent>
                <CardContent className={classes.cardContent}>
                  <Typography variant="caption">
                    {csm.projectnames}
                  </Typography>
                  <Typography variant="h5" component="h2">
                    {csm.fullname}
                  </Typography>
                  <Typography gutterBottom variant="body1" component="p">
                    {csm.address1_city}, {csm.address1_stateorprovince}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {ReactHtmlParser(csm.hsal_aboutme)}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" href={"mailto:" + csm.internalemailaddress}>
                    {csm.internalemailaddress}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
            <Grid item xs={6}>
              <Card className={classes.card}>
                <CardContent>
                  <Skeleton animation="wave" variant="circle" className={classes.avatar} />
                </CardContent>
                <CardContent>
                  <Typography variant="caption">
                    <Skeleton animation="wave" />
                  </Typography>
                  <Typography variant="h5" component="h2">
                    <Skeleton animation="wave" />
                  </Typography>
                  <Typography gutterBottom variant="body1" component="p">
                    <Skeleton animation="wave" />
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    <Skeleton animation="wave" variant="rect" height={100} />
                  </Typography>
                </CardContent>
                <CardContent>
                  <Skeleton animation="wave" />
                </CardContent>
              </Card>
            </Grid>
          )}
      </Grid>
    </Container>
  );
}