import React,{ useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://us.hitachi-solutions.com/">
          Hitachi Solutions America
        </Link>{' , Ltd. 1994, '}
        {new Date().getFullYear()}
        {'. All rights Reserved. '}
        <br></br>
        <Link color="inherit" href="http://www.hitachi-solutions.com/terms/">
          Terms of Use
        </Link>
        {' | '}
        <Link color="inherit" href="http://www.hitachi-solutions.com/privacy/">
          Privacy Policy
        </Link>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='100%' height='100%'><defs><pattern id='pillPattern' x='10' y='10' width='116' height='60' patternUnits='userSpaceOnUse' patternTransform='rotate(-30)' ><rect x='0' y='0' width='96' height='40' ry='20' rx='20' style='stroke: none; fill:%23e2e5e7; opacity: .25' /></pattern></defs><rect x='0' y='0' width='100%25' height='100%' style='fill: url(%23pillPattern);' /></svg>")`,
    [theme.breakpoints.down('xs')]: {
      minHeight: 'calc(100vh - 56px)',
    },
    minHeight: 'calc(100vh - 64px)',
  },
  main: {
    flexGrow: 1,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(3),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  message: {
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.concrete.main,
    padding: theme.spacing(1)
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#FFFFFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  requestPassword: {
    textAlign: 'center',
  },
}));

function Login(props) {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const submitHandler = ( event ) => {
    if (!loading) {
      setLoading(true);
    }
    setLoginFailed(false);
    props.onAuth( userName, password)
  }

  let { from } = props.location.state || { from: { pathname: "/dashboard/" } };
  let authRedirect = null;
  if (props.isAuthenticated === true) {
    authRedirect = <Redirect to={from} />
  }

  if(props.error === 401){
    props.initializeError();
    setTimeout(() => {
      setLoginFailed(true);
      setLoading(false);
    }, 0);
  } 
  
  return (
    <div className={classes.root}>
    {authRedirect}
    <Container component="main" maxWidth="xs" className={classes.main}>
      <Paper className={classes.paper} elevation={3}>
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        {loginFailed && <Alert severity="error">Incorrect email or password.</Alert>}
        <form className={classes.form} onSubmit={handleSubmit(submitHandler)} noValidate>
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            type="email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={e => {setUserName(e.target.value);}}
            inputRef={register({ 
              required: { value: true, message: "Email address is required" },
              pattern: { value: /^\S+@\S+$/i, message: "Please enter a valid email address" }
            })}
            error={!!errors.email}
            helperText={errors?.email?.message}
          />
          <TextField
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => {setPassword(e.target.value);}}
            inputRef={register({ required: true })}
            error={!!errors.password}
            helperText={errors.password?.type === "required" && "Password is required"}
          />
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              className={classes.submit}
            >
              Log In
            </Button>
            {loading && <CircularProgress size={32} className={classes.buttonProgress} />}
          </div>
          <Grid container>
            <Grid item xs className={classes.requestPassword}>
              <Link 
                component={RouterLink} 
                to={{
                  pathname: "/request-password/",
                  state: { userName: userName}
                }} 
                variant="body2"
              >
                Request password
              </Link>
            </Grid>
          </Grid>
        </form>
        <Paper className={classes.message} elevation={0}>
          <Typography variant="body1" align="center" gutterBottom>
            New Here or Forgot your Password?
          </Typography>
          <Typography variant="body2" gutterBottom>
            Please click "Request Password"
          </Typography>
          <Typography variant="body2">
            {"If you have any questions, comments or feedback please email "}
            <Link href="mailto:vision@hitachisolutions.com" variant="body2">
              vision@hitachisolutions.com​
            </Link>
          </Typography>
        </Paper>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Paper>
    </Container>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      isAuthenticated: state.auth.token !== null,
      error : state.auth.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onAuth: ( email, password ) => dispatch( actions.auth( email, password ) ),
      initializeError: () => dispatch( actions.InitializeAuthError() )
  };
};

export default connect( mapStateToProps, mapDispatchToProps )(Login);