import React, { Component } from "react";
import { withStyles, createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Divider from '@material-ui/core/Divider';
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Fab from '@material-ui/core/Fab';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import RestoreRoundedIcon from '@material-ui/icons/RestoreRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { Link as RouterLink } from 'react-router-dom';
import axios from "../VisionServices/axios";
import { connect } from 'react-redux';
import PieChart from "./PieChart";
import CasesByOwner from "./CasesByOwner";
import CasesByStatus from "./CasesByStatus";
import BarChart from "./BarChart";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logoGrid: {
    order: 1,
  },
  spacerGrid: {
    flexGrow: 1,
    flexShrink: 1,
    order: 2,
  },
  createCaseGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 5,
    },
  },
  createCaseAdminGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    order: 3,
    [theme.breakpoints.up('lg')]: {
      order: 5,
    },
  },
  customerGrid: {
    order: 4,
    [theme.breakpoints.up('lg')]: {
      order: 3,
      flexGrow: 1,
    },
  },
  projectGrid: {
    order: 5,
    [theme.breakpoints.up('lg')]: {
      order: 4,
      flexGrow: 1,
    },
  },
  customerLogo: {
    maxHeight: "56px",
    maxWidth: "100%",
    [theme.breakpoints.up('md')]: {
      maxWidth: "350px",
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: "100%",
    },
  },
  logoPlaceHolder: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(1),
    height: "42px",
    whiteSpace: "pre-wrap",
    maxWidth: "300px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
  },
  customerControl: {
    minWidth: "250px",
  },
  projectControl: {
    minWidth: "250px",
  },
  orangeBackground: {
    backgroundColor: "#f26722",
  },
  greenBackground: {
    backgroundColor: "#429644",
  },
  blueBackground: {
    backgroundColor: "#2aa9e0",
  },
  purpleBackground: {
    backgroundColor: "#891d5a",
  },
  card: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    color: "#ffffff",
  },
  cardContent: {
    display: "flex",
    height: "100%",
  },
  boxRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: "1",
    textAlign: "center",
  },
  title: {
    textAlign: "center",
    color: "#616161",
    backgroundColor: "#ffffff",
  },
  heading: {
    padding: theme.spacing(2, 2, 0),
    textAlign: "center",
    color: "#616161",
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  dateFab: {
    margin: theme.spacing(1),
  },
  datePicker: {
    width: "160px",
    [theme.breakpoints.down('sm')]: {
      width: "auto",
      minWidth: "160px",
      maxWidth: "calc(50% - 56px)",
    },
  },
});

const dateRangeTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#991B1F',
    },
    secondary: {
      main: '#429644',
    },
  },
  typography: {
    fontFamily: 'Fira Sans, sans-serif',
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: localStorage.getItem('StartDate') ? localStorage.getItem('StartDate') === 'null' ? null : new Date(localStorage.getItem('StartDate')).setHours(0, 0, 0, 0) : new Date(new Date().setHours(0, 0, 0, 0)).setMonth(new Date().getMonth() - 3),
      endDate: localStorage.getItem('EndDate') ? localStorage.getItem('EndDate') === 'null' ? null : new Date(localStorage.getItem('EndDate')).setHours(23, 59, 59, 999) : new Date().setHours(23, 59, 59, 999),
      tempStartDate: localStorage.getItem('StartDate') ? localStorage.getItem('StartDate') === 'null' ? null : new Date(localStorage.getItem('StartDate')).setHours(0, 0, 0, 0) : new Date(new Date().setHours(0, 0, 0, 0)).setMonth(new Date().getMonth() - 3),
      tempEndDate: localStorage.getItem('EndDate') ? localStorage.getItem('EndDate') === 'null' ? null : new Date(localStorage.getItem('EndDate')).setHours(23, 59, 59, 999) : new Date().setHours(23, 59, 59, 999),
      customerId: localStorage.getItem('CustomerID') || "",
      customerName: localStorage.getItem('CustomerName') || "",
      projectId: localStorage.getItem('ProjectID') || "",
      projectName: localStorage.getItem('ProjectName') || "",
      logoLoaded: true,
      customerList: [],
      projectList: [],
      casesActive: null,
      casesClosed: null,
      casesCreated: null,
      withCustomer: null,
      withHitachi: null,
      withVendor: null,
      casesByPriority: null,
      casesBySource: null,
      casesByOwner: null,
      casesByStatus: null,
      casesByRequestType: null,
      busiestDay: null,
      busiestMonth: null,
      casesByPriorityTrend: null,
      casesByRequestTypeTrend: null,
      casesOpenedTrend: null,
      casesClosedTrend: null,
      showAccountDropdown: false,
    };
  }

  componentDidMount() {
    document.title = "Dashboard | Vision Customer Portal";
    this.loadCustomerList();
    this.state.customerId && this.loadProjectList();
    this.loadActiveData();
    this.loadTrendData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.userProfile !== prevProps.userProfile) {
      return null;
    }
  }

  componentWillUnmount() {
    document.title = "Vision Customer Portal";
  }

  loadActiveData = (
    startDate = this.state.startDate,
    endDate = this.state.endDate,
    customerId = this.state.customerId,
    projectId = this.state.projectId,
  ) => {
    this.loadCasesActive(null, null, customerId, projectId)
    this.loadCasesByPriority(null, null, customerId, projectId)
    this.loadCasesByRequestType(null, null, customerId, projectId)
    this.loadCasesByStatus(null, null, customerId, projectId)
    this.loadCasesByOwner(null, null, customerId, projectId)
  }

  loadTrendData = (
    startDate = this.state.startDate,
    endDate = this.state.endDate,
    customerId = this.state.customerId,
    projectId = this.state.projectId,
  ) => {
    this.loadCasesCreated(startDate, endDate, customerId, projectId)
    this.loadBusiestData(startDate, endDate, customerId, projectId)
    this.loadCasesByPriorityTrend(startDate, endDate, customerId, projectId)
    this.loadCasesByRequestTypeTrend(startDate, endDate, customerId, projectId)
    this.loadCasesOpenedTrend(startDate, endDate, customerId, projectId)
    this.loadCasesClosedTrend(startDate, endDate, customerId, projectId)
  }

  loadCustomerList = () => {
    axios
      .get("/Accounts")
      .then((response) => {
        this.setState({ customerList: response.data })
          if(this.props.isCustomer && response.data.length == 1){
            this.loadProjectList()
          }else if(this.props.isCustomer && response.data.length > 1){
            this.loadProjectList()
            this.setState({ showAccountDropdown: true });
          }
          else{
            this.setState({ showAccountDropdown: true });
          } 
      })
      .catch((error) => console.log(error))
  }

  loadProjectList = (customerId = this.state.customerId) => {
    this.setState({ projectList: [] })
    axios
      .get("/Projects", {
        params: {
          customerid: customerId,
        }
      })
      .then((response) => {
        this.setState({ projectList: response.data })
      })
      .catch((error) => console.log(error));
  }

  loadCasesActive = (startDate, endDate, customerId, projectId) => {
    this.setState({
      casesActive: null,
      withCustomer: null,
      withHitachi: null,
      withVendor: null,
    })
    axios
      .get("/cases", {
        params: {
          isActive: true,
          customerid: customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        var withCustomerSum = 0;
        var withHitachiSum = 0;
        var withVendorSum = 0;
        response.data.forEach(element => {
          switch (element.status_text) {
            case "With Customer":
            case "Awaiting Non-Prod Deployment":
            case "Awaiting Prod Deployment":
            case "Customer Testing":
            case "Deployed / Pending Sign-Off":
            case "On Hold by Customer":
              withCustomerSum++
              break;
            case "With Hitachi":
            case "New":
            case "Pending Assignment":
              withHitachiSum++
              break;
            case "With Vendor":
              withVendorSum++
              break;
            default:
              break;
          }
        });
        this.setState({
          casesActive: response.data,
          withCustomer: withCustomerSum,
          withHitachi: withHitachiSum,
          withVendor: withVendorSum,
        })
      })
      .catch((error) => console.log(error))
  }

  loadCasesByPriority = (startDate, endDate, customerId, projectId) => {
    this.setState({ casesByPriority: null });
    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          isActive: true,
          groupby: "priority",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        this.setState({
          casesByPriority: response.data,
        })
      })
      .catch((error) => { console.log(error) });
  }

  loadCasesByRequestType = (startDate, endDate, customerId, projectId) => {
    this.setState({ casesByRequestType: null });

    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          isActive: true,
          groupby: "requesttype",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        this.setState({
          casesByRequestType: response.data,
        })
      })
      .catch((error) => { console.log(error) });
  }

  loadCasesByStatus = (startDate, endDate, customerId, projectId) => {
    this.setState({ casesByStatus: null })

    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          isActive: true,
          groupby: "status",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        this.setState({
          casesByStatus: response.data,
        })
      })
      .catch((error) => { console.log(error) });
  }

  loadCasesByOwner = (startDate, endDate, customerId, projectId) => {
    this.setState({ casesByOwner: null })

    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          isActive: true,
          groupby: "owner",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        this.setState({
          casesByOwner: response.data,
        })
      })
      .catch((error) => { console.log(error) });
  }

  loadCasesCreated = (startDate, endDate, customerId, projectId) => {
    this.setState({ casesCreated: null })

    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          groupby: "status",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        let casesCreatedSum = 0
        response.data.forEach(element =>
          casesCreatedSum += element.count
        )
        this.setState({
          casesCreated: casesCreatedSum,
        })
      })
      .catch((error) => { console.log(error) });
  }

  loadBusiestData = (startDate, endDate, customerId, projectId) => {
    this.setState({
      busiestDay: null,
      busiestMonth: null,
    })

    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          groupby: "busiestdetails",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        this.setState({
          busiestDay: response.data.busiestday,
          busiestMonth: response.data.busiestmonth,
        })
      })
      .catch((error) => { console.log(error) });
  }

  loadCasesByPriorityTrend = (startDate, endDate, customerId, projectId) => {
    this.setState({ casesByPriorityTrend: null });

    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          groupby: "priority",
          listbydate: "true",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        this.setState({
          casesByPriorityTrend: response.data,
        })
      })
      .catch((error) => { console.log(error) });
  }

  loadCasesByRequestTypeTrend = (startDate, endDate, customerId, projectId) => {
    this.setState({ casesByRequestTypeTrend: null });

    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          groupby: "requesttype",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        this.setState({
          casesByRequestTypeTrend: response.data,
        })
      })
      .catch((error) => { console.log(error) });
  }

  loadCasesOpenedTrend = (startDate, endDate, customerId, projectId) => {
    this.setState({ casesOpenedTrend: null })

    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          groupby: "opened",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        let caseData = response.data?.map((caseItem) => (
          { createdon: caseItem, action: 'Opened' }
        ))
        this.setState({
          casesOpenedTrend: caseData,
        })
      })
      .catch((error) => { console.log(error) });
  }

  loadCasesClosedTrend = (startDate, endDate, customerId, projectId) => {
    this.setState({
      casesClosedTrend: null,
      casesClosed: null,
    })

    startDate = startDate || new Date('1/1/1900');
    endDate = endDate || new Date('1/1/2100');

    axios
      .get("/cases/count", {
        params: {
          groupby: "closed",
          startDate: startDate && new Date(startDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: endDate && new Date(endDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: customerId === "" ? null : customerId,
          projectid: projectId,
        }
      })
      .then((response) => {
        let caseData = response.data?.map((caseItem) => (
          { createdon: caseItem.modifiedon, action: 'Closed' }
        ))
        this.setState({
          casesClosedTrend: caseData,
          casesClosed: response.data?.length
        })
      })
      .catch((error) => { console.log(error) });
  }

  handleCustomerChange = (event) => {
    const customerId = event.target.value
    const customerName = event.target.value === "" ? "" : event.nativeEvent.target.innerText
    let startDate = this.state.startDate
    let endDate = this.state.endDate

    localStorage.removeItem('ProjectID');
    localStorage.removeItem('ProjectName');

    localStorage.setItem('CustomerName', customerName);
    localStorage.setItem('CustomerID', customerId);
    localStorage.setItem('StartDate', startDate === null ? null : new Date(startDate).toLocaleDateString());
    localStorage.setItem('EndDate', endDate === null ? null : new Date(endDate).toLocaleDateString());

    this.setState({
      customerId: customerId,
      customerName: customerName,
      projectId: "",
      projectName: "",
    })
    this.loadActiveData(startDate, endDate, customerId, null);
    this.loadTrendData(startDate, endDate, customerId, null);
    this.loadProjectList(customerId)
  }

  handleProjectChange = (event) => {
    const projectId = event.target.value
    const projectName = event.target.value === "" ? "" : event.nativeEvent.target.innerText

    let startDate = this.state.startDate
    let endDate = this.state.endDate
    let customerId = this.state.customerId

    localStorage.setItem('ProjectID', projectId);
    localStorage.setItem('ProjectName', projectName);

    this.setState({
      projectId: projectId,
      projectName: projectName,
    })

    this.loadActiveData(startDate, endDate, customerId, projectId);
    this.loadTrendData(startDate, endDate, customerId, projectId);
  }

  handleLogoLoad = (isSuccess, i) => {
    this.setState({ logoLoaded: isSuccess })
  }

  handleClearDateRange = () => {
    this.setState({
      tempStartDate: null,
      tempEndDate: null,
    });
  };

  handleStartDateChange = (date) => {
    this.setState({ tempStartDate: date === null ? null : new Date(date).setHours(0, 0, 0, 0) });
  };

  handleEndDateChange = (date) => {
    this.setState({ tempEndDate: date === null ? null : new Date(date).setHours(23, 59, 59, 999) });
  };

  handleDefaultDateRange = () => {
    this.setState({
      tempStartDate: new Date(new Date().setHours(0, 0, 0, 0)).setMonth(new Date().getMonth() - 3),
      tempEndDate: new Date().setHours(23, 59, 59, 999),
    });
  };

  handleRefresh = () => {
    let tempStartDate = this.state.tempStartDate
    let tempEndDate = this.state.tempEndDate
    localStorage.setItem("StartDate", tempStartDate === null ? null : new Date(tempStartDate).toLocaleDateString())
    localStorage.setItem("EndDate", tempEndDate === null ? null : new Date(tempEndDate).toLocaleDateString())

    this.setState({
      startDate: tempStartDate,
      endDate: tempEndDate,
    });
    this.loadTrendData(tempStartDate, tempEndDate, this.state.customerId)
  };

  render() {
    const { classes } = this.props;
    const startDate = this.state.startDate && new Date(this.state.startDate).toLocaleDateString();
    const endDate = this.state.endDate && new Date(this.state.endDate).toLocaleDateString();
    const customerName = this.state.customerName;

    let datesChanged = (this.state.tempStartDate === this.state.startDate && this.state.tempEndDate === this.state.endDate)
    let datesCleared = (this.state.tempStartDate === null && this.state.tempEndDate === null)
    let datesInvalid = (this.state.tempEndDate !== null && this.state.tempEndDate !== null && this.state.tempStartDate > this.state.tempEndDate)

    return (
      <Container component="main" maxWidth={false} className={classes.root}>
        <Grid container spacing={2} justify="space-between" alignItems="flex-end" className={classes.header}>
          {(this.props.customerCompanyID || this.state.customerId) && (
            <Grid item className={classes.logoGrid}>
              <>
                <img
                  alt={this.props.isCustomer ? this.props.companyName : this.state.customerName}
                  src={
                    "https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/logos/" +
                    (this.props.isCustomer ? this.props.customerCompanyID : this.state.customerId) + ".png"
                  }
                  onLoad={i => this.handleLogoLoad(true, i)}
                  onError={i => this.handleLogoLoad(false, i)}
                  style={{ display: this.state.logoLoaded ? "flex" : "none" }}
                  className={classes.customerLogo}
                />
                {!this.state.logoLoaded && (
                  <Paper className={classes.logoPlaceHolder}>
                    <Typography variant="body2">
                      {this.props.isCustomer ? this.props.companyName : this.state.customerName}
                    </Typography>
                  </Paper>
                )}
              </>
            </Grid>
          )}
          <Grid item className={classes.spacerGrid}>
          </Grid>
          {this.state.showAccountDropdown && 
            <Grid item xs={12} md={6} lg={3} className={classes.customerGrid}>
              <FormControl
                fullWidth
                variant="filled"
                className={classes.customerControl}
              >
                <InputLabel shrink id="customer-label">Accounts</InputLabel>
                <Select
                  labelId="customer-label"
                  id="customers"
                  displayEmpty
                  defaultValue=""
                  value={this.state.customerId}
                  onChange={this.handleCustomerChange}
                >
                  <MenuItem value="">
                    <em>All Accounts</em>
                  </MenuItem>
                  {this.state.customerList.map((customer) => (
                    <MenuItem key={customer.Id} value={customer.Id} name={customer.Name}>
                      {customer.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item xs={12} md={this.props.isCustomer ? 5 : 6} lg={3} className={classes.projectGrid}>
            <FormControl
              fullWidth
              variant="filled"
              disabled={this.state.projectList.length === 0}
              className={classes.projectControl}
            >
              <InputLabel shrink id="project-label">Projects</InputLabel>
              <Select
                labelId="project-label"
                id="projects"
                displayEmpty
                defaultValue=""
                value={this.state.projectId}
                onChange={this.handleProjectChange}
              >
                <MenuItem value="">
                  <em>All Projects</em>
                </MenuItem>
                {this.state.projectList.map((project) => (
                  <MenuItem key={project.value} value={project.value} name={project.name}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={this.props.isCustomer ? 'auto' : 6} lg={'auto'} className={this.props.isCustomer ? classes.createCaseGrid : classes.createCaseAdminGrid}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.createCase}
              component={RouterLink}
              to="/cases/new/"
            >
              Create Case
                </Button>
          </Grid>
        </Grid>
        <Typography variant="h4">Active Cases</Typography>
        <Grid container spacing={2} className={classes.dashboard}>
          <Grid item xs={12} sm={3} >
            <Card className={`${classes.card} ${classes.orangeBackground}`}>
              <CardActionArea
                component={RouterLink}
                to={"/cases?startdate=null&enddate=null" +
                  (!this.props.isCustomer && customerName !== "" ? ("&customer=" + encodeURIComponent(customerName)) : "") +
                  (this.state.projectName ? "&project=" + encodeURIComponent(this.state.projectName) : "")
                }
              >
                <CardContent className={classes.cardContent}>
                  <Box className={classes.boxRoot}>
                    <Box>
                      <Typography variant="h3">
                        {this.state.casesActive === null ? (
                          <Skeleton animation="wave" width="3ch" />
                        ) : (
                            this.state.casesActive?.length
                          )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Active Cases
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={9} >
            <Card className={`${classes.card} ${classes.greenBackground}`}>
              <CardActionArea
                component={RouterLink}
                to={"/cases?startdate=null&enddate=null" +
                  "&status=With Customer|Awaiting Non-Prod Deployment|Awaiting Prod Deployment|Customer Testing|Deployed / Pending Sign-Off|On Hold by Customer" +
                  (!this.props.isCustomer && customerName !== "" ? ("&customer=" + encodeURIComponent(customerName)) : "") +
                  (this.state.projectName ? "&project=" + encodeURIComponent(this.state.projectName) : "")
                }
              >
                <CardContent className={classes.cardContent}>
                  <Box className={classes.boxRoot}>
                    <Box>
                      <Typography variant="h3">
                        {this.state.withCustomer === null ? (
                          <Skeleton animation="wave" width="3ch" />
                        ) : (
                            this.state.withCustomer
                          )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Active w/ Account
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
              <Divider orientation="vertical" flexItem />
              <CardActionArea
                component={RouterLink}
                to={"/cases?startdate=null&enddate=null&status=With Hitachi|New|Pending Assignment" +
                  (!this.props.isCustomer && customerName !== "" ? ("&customer=" + encodeURIComponent(customerName)) : "") +
                  (this.state.projectName ? "&project=" + encodeURIComponent(this.state.projectName) : "")
                }
              >
                <CardContent className={classes.cardContent}>
                  <Box className={classes.boxRoot}>
                    <Box>
                      <Typography variant="h3">
                        {this.state.withHitachi === null ? (
                          <Skeleton animation="wave" width="3ch" />
                        ) : (
                            this.state.withHitachi
                          )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Active w/ Hitachi
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
              <Divider orientation="vertical" flexItem />
              <CardActionArea
                component={RouterLink}
                to={"/cases?startdate=null&enddate=null&status=With Vendor" +
                  (!this.props.isCustomer && customerName !== "" ? ("&customer=" + encodeURIComponent(customerName)) : "") +
                  (this.state.projectName ? "&project=" + encodeURIComponent(this.state.projectName) : "")
                }
              >
                <CardContent className={classes.cardContent}>
                  <Box className={classes.boxRoot}>
                    <Box>
                      <Typography variant="h3">
                        {this.state.withVendor === null ? (
                          <Skeleton animation="wave" width="3ch" />
                        ) : (
                            this.state.withVendor
                          )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Active w/ Vendor
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.title}>
              <Typography
                className={classes.heading}
                variant="h6"
              >
                Active Cases by Priority
              </Typography>
              {!this.state.casesByPriority ? (
                <Skeleton animation="wave" variant="rect" width="100%" height="40vh" />
              ) : (
                  <PieChart
                    chartData={this.state.casesByPriority}
                    customerName={this.state.customerName}
                    projectName={this.state.projectName}
                    groupBy="priority"
                  />
                )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.title}>
              <Typography
                className={classes.heading}
                variant="h6"
              >
                Active Cases by Request Type
              </Typography>
              {!this.state.casesByRequestType ? (
                <Skeleton animation="wave" variant="rect" width="100%" height="40vh" />
              ) : (
                  <PieChart
                    chartData={this.state.casesByRequestType}
                    customerName={this.state.customerName}
                    projectName={this.state.projectName}
                    groupBy="requesttype"
                  />
                )}
            </Paper>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Paper className={classes.title}>
              <Typography
                className={classes.heading}
                variant="h6"
              >
                Active Cases by Status
              </Typography>
              <CasesByStatus
                tableData={this.state.casesByStatus}
                customerName={this.state.customerName}
                projectName={this.state.projectName}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CasesByOwner
              tableData={this.state.casesByOwner}
              customerName={this.state.customerName}
              projectName={this.state.projectName}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography variant="h4">Trends</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2} justify="space-between" alignItems="flex-end" className={classes.header}>
            <Grid item>
              <Box display="flex" flexWrap="wrap" justifyContent="flex-start" alignItems="flex-end" flexGrow={1}>
                <Box px={1} flexGrow={1} className={classes.datePicker}>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    showTodayButton
                    fullWidth
                    id="start-date"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    maxDate={this.state.tempEndDate || undefined}
                    maxDateMessage="Start Date cannot be later than End Date."
                    value={this.state.tempStartDate}
                    onChange={this.handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'start date',
                    }}
                  />
                </Box>
                <Box px={1} flexGrow={1} className={classes.datePicker}>
                  <KeyboardDatePicker
                    autoOk
                    clearable
                    showTodayButton
                    fullWidth
                    id="end-date"
                    label="End Date"
                    format="MM/dd/yyyy"
                    minDate={this.state.tempStartDate || undefined}
                    minDateMessage="End Date cannot be earlier than Start Date"
                    value={this.state.tempEndDate}
                    onChange={this.handleEndDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'end date',
                    }}
                  />
                </Box>
                <Box display="flex">
                  <ThemeProvider theme={dateRangeTheme}>
                    <Tooltip title={datesCleared ? "Default Date Range" : "Clear Date Range"}>
                      <Fab
                        size="small"
                        color={datesCleared ? "primary" : "inherit"}
                        className={classes.dateFab}
                        onClick={datesCleared ? this.handleDefaultDateRange : this.handleClearDateRange}
                        aria-label="clear date range"
                      >
                        {datesCleared ? <RestoreRoundedIcon /> : <ClearRoundedIcon />}
                      </Fab>
                    </Tooltip>
                    <Tooltip title={datesChanged ? "Refresh Cases" : "Retrieve Cases"}>
                      <Fab
                        disabled={datesInvalid}
                        size="small"
                        color={datesChanged ? "primary" : "secondary"}
                        className={classes.dateFab}
                        onClick={this.handleRefresh}
                        aria-label="clear date range"
                      >
                        {datesChanged ? <UpdateRoundedIcon /> : <ArrowForwardRoundedIcon />}
                      </Fab>
                    </Tooltip>
                  </ThemeProvider>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <Grid container spacing={2} className={classes.dashboard}>
          <Grid item xs={12} md={6}>
            <Card className={`${classes.card} ${classes.blueBackground}`}>
              <CardActionArea
                component={RouterLink}
                to={"/cases?" +
                  "&startdate=" + startDate +
                  "&enddate=" + endDate +
                  (!this.props.isCustomer && customerName !== "" ? ("&customer=" + encodeURIComponent(customerName)) : "") +
                  (this.state.projectName ? "&project=" + encodeURIComponent(this.state.projectName) : "") +
                  "&activeonly=false"
                }
              >
                <CardContent className={classes.cardContent}>
                  <Box className={classes.boxRoot}>
                    <Box>
                      <Typography variant="h3">
                        {this.state.casesCreated === null ? (
                          <Skeleton animation="wave" width="3ch" />
                        ) : (
                            this.state.casesCreated
                          )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Cases Created
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
              <Divider orientation="vertical" flexItem />
              <CardActionArea>
                <CardContent className={classes.cardContent}>
                  <Box className={classes.boxRoot}>
                    <Box>
                      <Typography variant="h3">
                        {this.state.casesClosed === null ? (
                          <Skeleton animation="wave" width="3ch" />
                        ) : (
                            this.state.casesClosed || "0"
                          )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Cases Closed
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={`${classes.card} ${classes.purpleBackground}`}>
              <CardActionArea>
                <CardContent className={classes.cardContent}>
                  <Box className={classes.boxRoot}>
                    <Box>
                      <Typography variant="h4">
                        {this.state.busiestDay === null ? (
                          <Skeleton animation="wave" width="5ch" />
                        ) : (
                            this.state.busiestDay
                          )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Busiest Day
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
              <Divider orientation="vertical" flexItem />
              <CardActionArea>
                <CardContent className={classes.cardContent}>
                  <Box className={classes.boxRoot}>
                    <Box>
                      <Typography variant="h4">
                        {this.state.busiestMonth === null ? (
                          <Skeleton animation="wave" width="5ch" />
                        ) : (
                            this.state.busiestMonth
                          )}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        Busiest Month
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Typography
                className={classes.heading}
                variant="h6"
              >
                Monthly Cases By Priority
              </Typography>
              {!this.state.casesByPriorityTrend ? (
                <Skeleton animation="wave" variant="rect" width="100%" height="40vh" />
              ) : this.state.casesByPriorityTrend?.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><Typography>No Data</Typography></Box>
              ) : (
                    <BarChart
                      tableData={this.state.casesByPriorityTrend}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      customerName={this.state.customerName}
                      projectName={this.state.projectName}
                      dateKey="createdon"
                      categoryKey="priority"
                      urlParam="priority"
                    />
                  )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Typography
                className={classes.heading}
                variant="h6"
              >
                Monthly Cases By Request Type
              </Typography>
              {!this.state.casesByRequestTypeTrend ? (
                <Skeleton animation="wave" variant="rect" width="100%" height="40vh" />
              ) : this.state.casesByRequestTypeTrend?.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><Typography>No Data</Typography></Box>
              ) : (
                    <BarChart
                      tableData={this.state.casesByRequestTypeTrend}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      customerName={this.state.customerName}
                      projectName={this.state.projectName}
                      dateKey="createdon"
                      categoryKey="requestType"
                      urlParam="requesttype"
                    />
                  )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Typography
                className={classes.heading}
                variant="h6"
              >
                Monthly Cases Opened
              </Typography>
              {!this.state.casesOpenedTrend ? (
                <Skeleton animation="wave" variant="rect" width="100%" height="40vh" />
              ) : this.state.casesOpenedTrend?.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><Typography>No Data</Typography></Box>
              ) : (
                    <BarChart
                      tableData={this.state.casesOpenedTrend}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      customerName={this.state.customerName}
                      projectName={this.state.projectName}
                      dateKey="createdon"
                      categoryKey="action"
                    />
                  )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Typography
                className={classes.heading}
                variant="h6"
              >
                Monthly Cases Closed
              </Typography>
              {!this.state.casesClosedTrend ? (
                <Skeleton animation="wave" variant="rect" width="100%" height="40vh" />
              ) : this.state.casesClosedTrend?.length === 0 ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="40vh"><Typography>No Data</Typography></Box>
              ) : (
                    <BarChart
                      tableData={this.state.casesClosedTrend}
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      customerName={this.state.customerName}
                      projectName={this.state.projectName}
                      dateKey="createdon"
                      categoryKey="action"
                      clickable={false}
                    />
                  )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCustomer: state.auth.role === "customer",
    name: state.auth.authName,
    customerCompanyID: state.auth.authCompany,
    companyName: state.auth.authCompanyName,
    userProfile: state,
  };
};

export default connect(mapStateToProps, null)(withStyles(useStyles)(Dashboard));
