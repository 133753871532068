import CryptoJS from "crypto-js";

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const encryptObject = (obj) => {
    return CryptoJS.AES.encrypt(JSON.stringify(obj), 'ms-hs-key').toString()
}

export const decryptObject = () => {
    try{
        if(localStorage.getItem("hms") != null && localStorage.getItem("hms") !="")
        {
        let bytes = CryptoJS.AES.decrypt(localStorage.getItem("hms"), 'ms-hs-key');
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
        }
    }
    catch(ex){}
   return null;
}