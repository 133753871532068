import React, { useState, useEffect } from 'react';
import axios from '../VisionServices/axios'
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import FilledInput from '@material-ui/core/FilledInput';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  alignRight: {
    textAlign: 'right',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ChangePassword(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  
  useEffect(() => {
    document.title = "Change Password | Vision Customer Portal";
  });

  const { errors, register, handleSubmit, getValues, watch, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      currentpassword: "",
      newpassword: "",
      confirmpassword: "",
    },
    validateCriteriaMode: "all",
  });
  const newPasswordHasValue = watch("newpassword", false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    setLoading(true)

    const authData = {
      oldPassword: data.currentpassword,
      password: data.newpassword,
    };

    axios.post("/Profile/ChangePassword", authData)
      .then((response) => {
        if(response.status === 200 || response.status === 201){
          if(response.data === "SUCCESS"){
            setOpenSuccess(true);
          }
          if(response.data === "FAILED"){
            setOpenError(true);
          }
        }
        setLoading(false);
        reset();
      })
      .catch((error) => { 
        reset();
        setLoading(false);
        setOpenError(true);
      });
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false)
    setOpenError(false)
  };

  return (
    <Container component="main" maxWidth="sm" className={classes.root}>
      <Paper className={classes.paper}>
        <form noValidate autoComplete="off" onSubmit={handleSubmit(data => onSubmit(data))}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" >
                Change Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Password must:</Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    {errors.newpassword?.types?.minLength ? <CheckCircleIcon style={{ color: "#ED1B34" }}/>
                      : newPasswordHasValue ? <CheckCircleIcon style={{ color: "#82C341"}}/>
                      : <CheckCircleOutlineRoundedIcon/>
                    }
                  </ListItemIcon>
                  <ListItemText
                    primary="be at least 8 characters"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {((errors.newpassword?.types?.isLower || errors.newpassword?.types?.isUpper) && newPasswordHasValue) ? <CheckCircleIcon style={{ color: "#ED1B34" }}/>
                      : newPasswordHasValue ? <CheckCircleIcon style={{ color: "#82C341"}}/>
                      : <CheckCircleOutlineRoundedIcon/>
                    }
                  </ListItemIcon>
                  <ListItemText
                    primary="include uppercase and lowercase"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {(errors.newpassword?.types?.isNumeric && newPasswordHasValue) ? <CheckCircleIcon style={{ color: "#ED1B34" }}/>
                      : newPasswordHasValue ? <CheckCircleIcon style={{ color: "#82C341"}}/>
                      : <CheckCircleOutlineRoundedIcon/>
                    }
                  </ListItemIcon>
                  <ListItemText
                    primary="include a numeric character"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {(errors.newpassword?.types?.isSpecial && newPasswordHasValue) ? <CheckCircleIcon style={{ color: "#ED1B34" }}/>
                      : newPasswordHasValue ? <CheckCircleIcon style={{ color: "#82C341"}}/>
                      : <CheckCircleOutlineRoundedIcon/>
                    }
                  </ListItemIcon>
                  <ListItemText
                    primary="include a special character"
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="filled" required fullWidth error={!!errors.currentpassword}>
                <InputLabel htmlFor="current-password">Current Password</InputLabel>
                <FilledInput
                  type={showPassword ? 'text' : 'password'}
                  id="current-password"
                  name="currentpassword"
                  inputRef={register({required: "Required"})}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="current-password-helper-text">{errors.currentpassword?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="filled" required fullWidth error={!!errors.newpassword}>
                <InputLabel htmlFor="new-password">New Password</InputLabel>
                <FilledInput
                  type={showPassword ? 'text' : 'password'}
                  id="new-password"
                  name="newpassword"
                  inputRef={register({ 
                    required: "Required",
                    validate: {
                      differsFromPreviousPassword: value => {
                        const { currentpassword } = getValues();
                        return currentpassword !== value || "Cannot be the current password.";
                      },
                      isUpper: value => {return /[A-Z]/.test(value) || "Requires uppercase"},
                      isLower: value => {return /[a-z]/.test(value) || "Requires lowercase"},
                      isNumeric: value => {return /[0-9]/.test(value) || "Requires numeric"},
                      isSpecial: value => {return /[`~!@#$%^&*()\-_+={}[\]\\|;:'",.<>?/]/.test(value) || "Requires a special character"},
                    },
                    minLength: {value: 8, message: "At least 8 characters."},
                  })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="current-password-helper-text">{errors.newpassword?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="filled" required fullWidth error={!!errors.confirmpassword}>
                <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
                <FilledInput
                  type={showPassword ? 'text' : 'password'}
                  id="confirm-password"
                  name="confirmpassword"
                  inputRef={register({ 
                    required: "Please confirm password!",
                    validate: {
                      matchesPreviousPassword: value => {
                        const { newpassword } = getValues();
                        return newpassword === value || "Passwords should match!";
                      }
                    }
                   })}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText id="current-password-helper-text">{errors.confirmpassword?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} className={classes.alignRight}>
              <Button component={RouterLink} to="/profile/" color="primary">
                Cancel
              </Button>
              <span className={classes.wrapper}>
                <Button type="submit" variant="contained" color="primary" disabled={loading}>
                  Change Password
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              </span>
              <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  Password changed successfully!
                </Alert>
              </Snackbar>
              <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                  Current password is incorrect!
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
}

export default ChangePassword;