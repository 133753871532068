import React from "react";
import Chart from "react-google-charts";
import Skeleton from "@material-ui/lab/Skeleton";

const LineChart = (props) => {
  const data = props.tableData;
  let startDate = new Date(props.startDate);
  let endDate = new Date(props.endDate);
  let csmName = props.csmName || "";
  let customerName = props.customerName || "";
  let status = "";
  let activeOnly = false;
  const options = {
    fontName: "Fira Sans",
    legend: {
      position: "top",
      maxLines: 3,
      alignment: "center",
    },
    colors: [
      "#991B1F",
      "#F26722",
      "#FFD500",
      "#82C341",
      "#2aa9e0",
      "#1E4799",
      "#9C4A70",
      "#​ED1B34",
      "#FBAA23",
      "#FEE180",
      "#429644",
      "#B7D433",
      "#16214B",
      "#891D5A",
      "#A53E23",
      "#F493AD",
      "#E1EBAE",
      "#1F4A23",
      "#E2E5E7",
      "#16214B",
      "#3A1126",
    ],
    chartArea: { width: "85%" },
    orientation: "horizontal",
  };
  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper.getDataTable();
          const { row, column } = selectedItem;
          if (row !== null) {
            startDate = new Date(dataTable.getValue(row, 0))
            endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0)
          }
          switch (dataTable.getColumnLabel(column)) {
            case 'Active Cases':
              activeOnly = true;
              break;
            case 'Closed Cases':
              activeOnly = false;
              status = "Closed"
              break;
            case 'Total Cases':
              activeOnly = false;
              break;
            default:
              activeOnly = false;
              break;
          }
          console.log(dataTable)
          console.log(selectedItem)
          console.log(dataTable.getValue(row||1, column||0))
          console.log(dataTable.getColumnLabel(column))
          console.log(activeOnly)

          window.location.href =
            "/cases?status=" + status +
            "&startdate=" + startDate.toLocaleDateString() + 
            "&enddate=" + endDate.toLocaleDateString() +
            "&csm=" + csmName +
            "&customer=" + customerName +
            "&activeonly=" + activeOnly;
        }
      },
    },
  ];

  return data === null ? (
    <Skeleton animation="wave" variant="rect" width="100%" height="40vh" />
  ) : (
    <Chart
      chartType="LineChart"
      width="100%"
      height="40vh"
      data={data}
      options={options}
      chartEvents={chartEvents}
      loader={<Skeleton animation="wave" variant="rect" width="100%" height="40vh" />}
    />
  )
};

export default LineChart;
