import axios from '../../VisionServices/axios';
import * as actionTypes from './actiontypes';
import { encryptObject, decryptObject } from '../utility';

const _timeoutsec = 3600;
let timeout = null;
export const authSuccess = (token, userId, role) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId,
    role: role
  };
};

export const authProfile = (
  name,
  company,
  companyName,
  image,
  role,
  workspaceid,
  envisionaccount,
  lcsprojectid,
  lcsprojecttype,
  envisionuser,
  monitoringuser,
  microsoftid,
  cspaccount,
  cspuser,
  csppurchasinguser,
) => {
  return {
    type: actionTypes.SET_AUTH_PROFILE,
    authName: name,
    authCompany: company,
    authCompanyName: companyName,
    authImage: image,
    workspace: workspaceid,
    envisionAccount: envisionaccount,
    lcsProjectId: lcsprojectid,
    lcsProjectType: lcsprojecttype,
    envisionUser: envisionuser,
    monitoringUser: monitoringuser,
    microsoftId: microsoftid,
    cspAccount: cspaccount,
    cspUser: cspuser,
    cspPurchasingUser: csppurchasinguser,
  };
};

export const setWorkspace = (workspace) => {
  return {
    type: actionTypes.SET_WORKSPACE,
    workspace: workspace
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};

export const logout = () => {
  localStorage.clear()
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = () => {
  return dispatch => {
    clearTimeout(timeout)
    timeout = null;
    timeout = setTimeout(() => {
      dispatch(logout());
    }, _timeoutsec * 1000);
  };
};

export const updatePortalExpiry = () => {
  checkAuthTimeout();
}

export const InitializeAuthError = () => {
  return dispatch => {
    dispatch(authFail("empty"));
  };
}

export const auth = (email, password) => {
  return dispatch => {
    const authData = {
      userName: email,
      password: password,
    };
    axios.post("/Security/Login", authData)
      .then((response) => {
        const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        response.data.expirationDate = expirationDate;
        const tokendata = encryptObject(response.data);
        localStorage.setItem('hms', tokendata);
        dispatch(authSuccess(response.data.access_token, response.data.user, response.data.role));
        dispatch(getProfile());
        dispatch(checkAuthTimeout());
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 400) {
          dispatch(authFail(401))
        }
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const getProfile = () => {
  return dispatch => {
    axios.get("/profile/Details")
      .then((response) => {
        if (response.data.Role !== "customer") {
          response.data.WorkspaceId = true;
        }
        dispatch(
          authProfile(
            response.data.Name,
            response.data.CompanyId,
            response.data.CompanyName,
            response.data.Image,
            response.data.Role,
            response.data.WorkspaceId,
            response.data.Envisionaccount,
            response.data.lcsprojectid,
            response.data.lcsprojecttype,
            response.data.Envisionuser,
            response.data.Monitoringuser,
            response.data.Microsoftid,
            response.data.CSPaccount,
            response.data.CSPuser,
            response.data.CSPpurchasinguser,
          )
        );
      })
      .catch((error) => console.log(error));
  };
};

export const authCheckState = () => {
  updatePortalExpiry();
  return dispatch => {
    const data = decryptObject();
    const token = data?.access_token;
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(data?.expirationDate);
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        const userId = data.user;
        const role = data.role;
        dispatch(authSuccess(token, userId, role));
        dispatch(getProfile());
        dispatch(checkAuthTimeout());
      }
    }
  };
};

