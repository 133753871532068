import React from "react";
import Chart from "react-google-charts";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";

const options = {
  fontName: "Fira Sans",
  fontSize: 16,
  colors: [
    "#991B1F",
    "#F26722",
    "#FFD500",
    "#82C341",
    "#2aa9e0",
    "#1E4799",
    "#9C4A70",
    "#​ED1B34",
    "#FBAA23",
    "#FEE180",
    "#429644",
    "#B7D433",
    "#16214B",
    "#891D5A",
    "#A53E23",
    "#F493AD",
    "#E1EBAE",
    "#1F4A23",
    "#E2E5E7",
    "#16214B",
    "#3A1126",
  ],
  legend: { 
    position: "top", 
    maxLines: 3,
    alignment: "center",
  },
  vAxis: {
    format: '#',
  },
  chartArea: {width: "85%"},
  orientation: "horizontal",
}

export default function BarChart(props) {
  const history = useHistory();
  const data = props.tableData;
  const startDate = new Date(props.startDate);
  const endDate = new Date(props.endDate);
  const dateKey = props.dateKey;
  const categoryKey = props.categoryKey;
  const urlParam = props.urlParam || undefined;
  const clickable = props.clickable ?? true;
  // Normalize createdon date to the first day of the month for aggregation
  const casesByMonth = data?.map((row) => 
    [(new Date(new Date(row[dateKey]).getFullYear(), new Date(row[dateKey]).getMonth(), 1)).getTime(), row[categoryKey]]
  )
  
  // Get distinct Category
  const category = new Set()
  casesByMonth.map((caseItem, index) => (
    category.add(caseItem[1])
  ))
  // Sort distinct Category
  const sortedCategory = Array.from(category).sort()

  // Get distinct normalized dates
  const date = new Set()
  casesByMonth.map((caseItem, index) => (
    date.add(caseItem[0])
  ))
  // Sort distinct normalized dates
  const sortedDate = Array.from(date).sort()

  // Create cases data table for cases groupby Month and Category
  const caseGrid = []
  sortedDate.forEach((date, y) => {
    caseGrid.push([new Date(date).toLocaleDateString('en-US', {month: 'short', year: 'numeric'})])
    const casesThisMonth = casesByMonth.filter(caseItem => caseItem[0] === date)
    sortedCategory.forEach((category, x) => {
      const currentCategory = casesThisMonth.filter(caseItem => caseItem[1] === category)
      caseGrid[y].splice(x+1,1,currentCategory.length)
    })
  })
  // Create column headers for caseGrid
  const columns = [
    { type: 'string', label: 'Month' },
  ]
  sortedCategory.forEach((category) => {
    columns.push({type:'number', label: category})
  })

  // Combine column headers with cases data table
  const chartData = [columns, ...caseGrid]

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 1) {
          const [selectedItem] = selection;
          const dataTable = chartWrapper.getDataTable();
          const maxRow = dataTable.cache.length - 1;
          const { row, column } = selectedItem;
          let eventStartDate = startDate;
          let eventEndDate = endDate;

          if (row !== null) {
            const eventDate = new Date(dataTable.getValue(row, 0));
            eventStartDate = (row === 0 ? startDate : eventDate)
            eventEndDate = (row === maxRow ? endDate :new Date(eventDate.getFullYear(), eventDate.getMonth() + 1, 0))
          }

          history.push(
            "/cases?" + 
            (urlParam ? (urlParam + "=" + dataTable.getColumnLabel(column)) : "") +
            "&startdate=" + eventStartDate.toLocaleDateString() + 
            "&enddate=" + eventEndDate.toLocaleDateString() +
            (props.customerName === "" ? "" : "&customer=" + encodeURIComponent(props.customerName)) +
            (props.projectName ? "&project=" + encodeURIComponent(props.projectName) : "") +
            "&activeonly=false"
          )
        }
      },
    },
  ];

  return data === null ? (
    <Skeleton animation="wave" variant="rect" width="100%" height="40vh" />
  ) : (
    <Chart
        chartType="BarChart"
        width="100%"
        height="40vh"
        data={chartData}
        options={options}
        chartEvents={clickable && chartEvents}
        loader={<Skeleton animation="wave" variant="rect" width="100%" height="40vh" />}
    />
  )
}