import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles, ThemeProvider, createMuiTheme, useTheme, } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar, MTableBody, MTableHeader, MTableBodyRow } from "material-table";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from '@material-ui/core/IconButton';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import FirstPageRoundedIcon from '@material-ui/icons/FirstPageRounded';
import LastPageRoundedIcon from '@material-ui/icons/LastPageRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ViewColumnRoundedIcon from '@material-ui/icons/ViewColumnRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from "../VisionServices/axios";
import Link from '@material-ui/core/Link';
import {
  Link as RouterLink,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";

const tableTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#991B1F',
    },
    secondary: {
      main: '#991B1F',
    },
  },
  typography: {
    fontFamily: 'Fira Sans, sans-serif',
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddRoundedIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <CheckRoundedIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearRoundedIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutlineRoundedIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRightRoundedIcon {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <EditRoundedIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAltRoundedIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterListRoundedIcon {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPageRoundedIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageRoundedIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightRoundedIcon {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftRoundedIcon {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearRoundedIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchRoundedIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownwardRoundedIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <RemoveRoundedIcon {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumnRoundedIcon {...props} ref={ref} />),
  Refresh: forwardRef((props, ref) => <RefreshRoundedIcon {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  grid: {
    padding: theme.spacing(2),
  },
  logoGrid: {
    order: 1,
  },
  spacerGrid: {
    order: 2,
    flexGrow: 1,
    flexShrink: 1,
  },
  customerGrid: {
    order: 3,
    flexGrow: 1,
  },
  customerControl: {
    minWidth: "250px",
  },
  customerLogo: {
    maxHeight: "56px",
    [theme.breakpoints.down('md')]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: "100%",
    },
  },
  logoPlaceHolder: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(1),
    height: "56px",
    whiteSpace: "pre-wrap",
    maxWidth: "230px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
  },
  toolbarRoot: {
    justifyContent: "flex-end",
    flexWrap: "wrap",
    padding: 0,
  },
  toolbarSpacer: {
    display: "none",
  },
  toolbarActions: {
    flexShrink: 0,
  },
  toolbarSearchField: {
    padding: 0,
  },
  headerHeader: {
    verticalAlign: "bottom",
    whiteSpace: "nowrap",
  },
  rowRoot: {
    '&:hover': {
      background: theme.palette.action.selected + " !important",
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  environmentTitle: {
    display: "flex",
    alignItems: "center",
  },
}));

function Environments(props) {
  const classes = useStyles();
  const history = useHistory();
  let { environmentName, environmentId } = useParams();
  let location = useLocation();

  const [environments, setEnvironments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toolbarHeight, setToolbarHeight] = useState(0);
  const [customerList, setCustomerList] = useState([]);
  const [customerId, setCustomerId] = useState(localStorage.getItem('CustomerID') || "");
  const [customerName, setCustomerName] = useState(localStorage.getItem('CustomerName') || "");
  const [lcsProjectId, setLCSProjectId] = useState(localStorage.getItem('LCSProjectID') || "");
  const [lcsProjectType, setLCSProjectType] = useState(localStorage.getItem('LCSProjectType') || "");
  const [logoLoaded, setLogoLoaded] = useState(true);
  const [isRetailDeployment, setIsRetailDeployment] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null);
  const toolbarEl = useRef(null);
  const environmentColumns = [
    {
      title: "Name", field: "EnvironmentName",
      render: (rowData) => (
        <Link component={RouterLink} to={"/environments/" + rowData.EnvironmentName?.toString() + "/" + rowData.EnvironmentId?.toString().toLowerCase()}>{rowData.EnvironmentName}</Link>
      )
    },
    { title: "Application Version", field: "CurrentApplicationBuildVersion", },
    { title: "Platform Version", field: "CurrentPlatformVersion", },
    { title: "RCSU Version", field: "BaseVersionSemanticVersion", hidden: !isRetailDeployment, },
    { title: "Retail Package", field: "RetailPackageName", hidden: !isRetailDeployment, cellStyle: { minWidth: '200px' }, },
    {
      title: "Retail Package Date", field: "RetailPackageDeploymentDate", hidden: !isRetailDeployment, type: "date",
      customFilterAndSearch: (term, rowData) => new Date(term).toDateString() === new Date(rowData.RetailPackageDeploymentDate).toDateString()
    },
    { title: "Name", field: "Name", },
    { title: "Package Description", field: "PackageDescription", cellStyle: { whiteSpace: 'pre-wrap', minWidth: '500px' }, },
    {
      title: "Start Date", field: "StartDate", type: "date",
      customFilterAndSearch: (term, rowData) => new Date(term).toDateString() === new Date(rowData.StartDate).toDateString()
    },
    {
      title: "Start Time", field: "StartDate", type: "time", filtering: false,
      render: (rowData) => (
        rowData.StartDate && new Date(rowData.StartDate).toLocaleTimeString("en-US", { timeZoneName: 'short' })
      )
    },
    {
      title: "Completion Date", field: "CompletionDate", type: "date",
      customFilterAndSearch: (term, rowData) => new Date(term).toDateString() === new Date(rowData.CompletionDate).toDateString()
    },
    {
      title: "Completion Time", field: "CompletionDate", type: "time", filtering: false,
      render: (rowData) => (
        rowData.CompletionDate && new Date(rowData.CompletionDate).toLocaleTimeString("en-US", { timeZoneName: 'short' })
      )
    },
    { title: "Status", field: "ActionStatusText", headerStyle: { minWidth: '160px' }, },
    { title: "Package Type", field: "PackageType", },
  ]

  const environmentHistoryColumns = [
    { title: "Name", field: "Name", },
    {
      title: "Start Date", field: "StartDate", type: "date",
      customFilterAndSearch: (term, rowData) => new Date(term).toDateString() === new Date(rowData.StartDate).toDateString()
    },
    {
      title: "Start Time", field: "StartDate", type: "time", filtering: false, export: false,
      render: (rowData) => (
        rowData.StartDate && new Date(rowData.StartDate).toLocaleTimeString("en-US", { timeZoneName: 'short' })
      )
    },
    {
      title: "Completion Date", field: "CompletionDate", type: "date",
      customFilterAndSearch: (term, rowData) => new Date(term).toDateString() === new Date(rowData.CompletionDate).toDateString()
    },
    {
      title: "Completion Time", field: "CompletionDate", type: "time", filtering: false, export: false,
      render: (rowData) => (
        rowData.CompletionDate && new Date(rowData.CompletionDate).toLocaleTimeString("en-US", { timeZoneName: 'short' })
      )
    },
    { title: "Status", field: "ActionStatusText", headerStyle: { minWidth: '160px' }, },
    { title: "Package Type", field: "PackageType", },
    { title: "Package Description", field: "PackageDescription", cellStyle: { whiteSpace: 'pre-wrap', minWidth: '500px' }, },
  ]

  useEffect(() => {
    document.title = "Environments | Vision Customer Portal";
    setToolbarHeight(toolbarEl.current?.clientHeight);
    return () => document.title = "Vision Customer Portal";
  });

  useEffect(() => {
    const handleResize = () => setToolbarHeight(toolbarEl.current.clientHeight);
    if (environments.length > 0) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [environments]);

  useEffect(() => {
    if (props.isCustomer) {
      setLCSProjectId(props.lcsProjectId);
      setLCSProjectType(props.lcsProjectType);
      loadData(props.lcsProjectId, props.lcsProjectType);
    }

    if (!props.isCustomer) {
      loadCustomerList();
    }

    setToolbarHeight(toolbarEl.current?.clientHeight);
    // eslint-disable-next-line
  }, [location, props.lcsProjectId]);

  const loadCustomerList = () => {
    axios
      .get("/Accounts")
      .then((response) => {

        let envisionCustomerList = response.data.filter(customer => customer.Envisionaccount === true)
        let currentCustomer = envisionCustomerList.filter(customer => customer.Id === customerId);
        if (currentCustomer.length === 0) {
          localStorage.removeItem('LCSProjectID');
          localStorage.removeItem('LCSProjectType');

          setCustomerId("");
          setLCSProjectId("");
          setLCSProjectType("");
        } else {
          let lcsId = currentCustomer[0]?.lcsprojectid;
          let lcsType = currentCustomer[0]?.lcsprojecttype;

          localStorage.setItem('LCSProjectID', lcsId);
          localStorage.setItem('LCSProjectType', lcsType);

          setLCSProjectId(lcsId)
          setLCSProjectType(lcsType)

          loadData(lcsId, lcsType);
        }
        setCustomerList(envisionCustomerList);

      })
      .catch((error) => console.log(error))
  }

  const loadData = (lcsId = lcsProjectId, lcsType = lcsProjectType, refresh = false) => {
    setEnvironments([])
    environmentId ? loadEnvironmentHistory(lcsId, lcsType, refresh) : loadAllData(lcsId, lcsType, refresh);
  }

  const loadAllData = (lcsId, lcsType, refresh) => {
    setEnvironments([])
    if (lcsId) {
      setIsLoading(true)
      let historyData = [];
      axios
        .get("/D365Monitoring", {
          params: {
            projectId: lcsId,
            projectType: lcsType,
            refresh: refresh,
          }
        })
        .then((response) => {
          setIsRetailDeployment(response.data.some((environment) => environment.IsRetailDeployment === true))
          let environmentData = response.data;
          let apiList = [];
          environmentData.forEach(row => {
            let currentEnvironmentId = row.EnvironmentId;
            apiList.push(axios.get(
              "/Environment", {
              params: {
                projectId: lcsId,
                environmentID: currentEnvironmentId,
                refresh: refresh,
              }
            }
            ))
          })
          Promise.all(apiList)
            .then((results) => {
              environmentData.forEach(row => historyData.push({ Id: row.EnvironmentId, ...row, RetailPackageDeploymentDate: new Date(row.RetailPackageDeploymentDate).toISOString() }))
              results.forEach(response => response.data.forEach((row, index) => historyData.push({ Id: row.EnvironmentID + index, ...row, StartDate: new Date(row.StartDate + "+0000").toISOString(), CompletionDate: new Date(row.CompletionDate + "+0000").toISOString() })))
              setEnvironments(historyData)
            })
            .catch((error) => {
              console.log(error)
            })
            .then(() => {
              setIsLoading(false)
            })
        })
        .catch((error) => {
          console.log(error)
          setIsLoading(false)
        })
    }
  }

  const loadEnvironmentHistory = (lcsId, lcsType, refresh) => {
    setEnvironments([])
    setIsLoading(true)
    axios
      .get("/Environment", {
        params: {
          projectId: lcsId,
          environmentID: environmentId,
          refresh: refresh,
        }
      })
      .then((response) => {
        let data = response.data
        let convertedData = []
        data.forEach(row => {
          let startDate = new Date(row.StartDate + "+0000")
          let completionDate = new Date(row.CompletionDate + "+0000")
          convertedData.push({
            ...row,
            StartDate: startDate.toISOString(),
            CompletionDate: completionDate.toISOString()
          })
        })
        setEnvironments(convertedData)
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        setIsLoading(false)
      });
  }

  const handleCustomerChange = (event) => {
    const customerId = event.target.value;
    const customerName = event.target.value === "" ? "" : event.nativeEvent.target.innerText;
    const lcsId = event.nativeEvent.srcElement.attributes.lcsprojectid?.nodeValue ?? null;
    const lcsType = event.nativeEvent.srcElement.attributes.lcsprojecttype?.nodeValue ?? null;

    localStorage.removeItem('ProjectID');
    localStorage.removeItem('ProjectName');
    localStorage.removeItem('LCSProjectID');
    localStorage.removeItem('LCSProjectType');

    localStorage.setItem('CustomerID', customerId);
    localStorage.setItem('CustomerName', customerName);
    localStorage.setItem('LCSProjectID', lcsId);
    localStorage.setItem('LCSProjectType', lcsType);

    setCustomerId(customerId);
    setCustomerName(customerName);
    setLCSProjectId(lcsId);
    setLCSProjectType(lcsType);

    environmentId ? history.push("/environments/") : loadData(lcsId, lcsType);
  }

  const handleLogoLoad = (isSuccess, i) => {
    setLogoLoaded(isSuccess);
  }

  const refresh = () => {
    let refresh = true;
    loadData(lcsProjectId, lcsProjectType, refresh);
  }

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <Grid container spacing={2} justify="space-between" alignItems="flex-end" className={classes.header}>
        {(props.customerCompanyID || (customerId && customerList.length !== 0)) && (
          <Grid item className={classes.logoGrid}>
            <>
              <img
                alt={props.isCustomer ? props.companyName : customerName}
                src={
                  "https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/logos/" +
                  (props.isCustomer ? props.customerCompanyID : customerId) + ".png"
                }
                onLoad={i => handleLogoLoad(true, i)}
                onError={i => handleLogoLoad(false, i)}
                style={{ display: logoLoaded ? "flex" : "none" }}
                className={classes.customerLogo}
              />
              {!logoLoaded && (
                <Paper className={classes.logoPlaceHolder}>
                  <Typography variant="body2">
                    {props.isCustomer ? props.companyName : customerName}
                  </Typography>
                </Paper>
              )}
            </>
          </Grid>
        )}
        <Grid item zeroMinWidth className={classes.spacerGrid}>
        </Grid>
        {!props.isCustomer &&
          <Grid item xs={12} md={5} lg={3} className={classes.customerGrid}>
            <FormControl
              fullWidth
              variant="filled"
              className={classes.customerControl}
            >
              <InputLabel shrink id="customer-label">Accounts</InputLabel>
              <Select
                labelId="customer-label"
                id="customers"
                displayEmpty
                defaultValue=""
                value={customerId}
                onChange={handleCustomerChange}
              >
                <MenuItem value="">
                  <em>Choose an Account</em>
                </MenuItem>
                {customerList.map((customer) => (
                  <MenuItem key={customer.Id} value={customer.Id} name={customer.Name} lcsprojectid={customer.lcsprojectid} lcsprojecttype={customer.lcsprojecttype}>
                    {customer.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        }
      </Grid>
      { (props.isCustomer || (environments && (!!lcsProjectId || !!customerId) && customerList.length > 0)) &&
        <ThemeProvider theme={tableTheme}>
          <MaterialTable
            icons={tableIcons}
            isLoading={isLoading}
            options={{
              exportButton: true,
              exportAllData: true,
              exportFileName: `Environments ${new Date().toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}`,
              filtering: true,
              sorting: true,
              draggable: false,
              grouping: false,
              pageSize: 20,
              pageSizeOptions: [],
              paging: mdUp,
              maxBodyHeight: mdUp ? `calc(100vh - ${toolbarHeight + 272}px)` : null,
              emptyRowsWhenPaging: false,
              doubleHorizontalScroll: mdUp,
              showTitle: false,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) && '#EEE'
              }),
            }}
            actions={[
              {
                icon: tableIcons.Refresh,
                tooltip: 'Refresh Environments',
                isFreeAction: true,
                onClick: () => {
                  refresh();
                },
              },
            ]}
            components={{
              Toolbar: (props) => (
                <div
                  className={classes.grid}
                  ref={toolbarEl}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3} md={6}>
                      {environmentName ? (
                        <Typography variant="h4" className={classes.environmentTitle}>
                          <IconButton size="small" component={RouterLink} to={"/environments/"}>
                            <ArrowBackRoundedIcon />
                          </IconButton>
                          {environmentName}
                        </Typography>
                      ) : (
                        <Typography variant="h4">Environments</Typography>
                      )}
                      <Typography variant="subtitle1">{environmentName && "Environment History"}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9} md={6}>
                      <MTableToolbar
                        classes={{
                          root: classes.toolbarRoot,
                          spacer: classes.toolbarSpacer,
                          actions: classes.toolbarActions,
                          searchField: classes.toolbarSearchField,
                        }}
                        {...props}
                      />
                    </Grid>
                  </Grid>
                </div>
              ),
              Header: (props) => (
                <MTableHeader
                  classes={{
                    header: classes.headerHeader,
                  }}
                  {...props}
                />
              ),
              Body: props => (<MTableBody {...props} onFilterChanged={(columnId, value) => {
                props.onFilterChanged(columnId, value);
              }} />),
              Row: (props) => (
                <MTableBodyRow
                  classes={{
                    root: classes.rowRoot,
                  }}
                  {...props}
                />
              ),
            }}
            columns={environmentId ? environmentHistoryColumns : environmentColumns}
            data={environments}
            parentChildData={(row, rows) => rows.find(a => a.Id === row.EnvironmentID)}
            onRowClick={((evt, clickedRow) => setSelectedRow(selectedRow === clickedRow.tableData.id ? null : clickedRow.tableData.id))}
          />
        </ThemeProvider>
      }
    </Container>
  );
}

const mapStateToProps = state => {
  let lcsId = state.auth.role === "customer" ? state.auth.lcsProjectId : null;
  let lcsType = state.auth.role === "customer" ? state.auth.lcsProjectType : null;
  return {
    isCustomer: state.auth.role === "customer",
    customerCompanyID: state.auth.authCompany,
    companyName: state.auth.authCompanyName,
    envisionAccount: state.auth.envisionAccount,
    lcsProjectId: lcsId,
    lcsProjectType: lcsType,
  };
};

export default connect(mapStateToProps, null)(Environments);