import React, { forwardRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles, ThemeProvider, createMuiTheme, useTheme } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar, MTableBody, MTableHeader, MTableBodyRow } from "material-table";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Fab from '@material-ui/core/Fab';
import Button from "@material-ui/core/Button";
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import RestoreRoundedIcon from '@material-ui/icons/RestoreRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import FirstPageRoundedIcon from '@material-ui/icons/FirstPageRounded';
import LastPageRoundedIcon from '@material-ui/icons/LastPageRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ViewColumnRoundedIcon from '@material-ui/icons/ViewColumnRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import axios from "../VisionServices/axios";
import queryString from 'query-string';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  logoGrid: {
    order: 1,
    maxWidth: "calc(100% - 165px)",
    [theme.breakpoints.up('md')]: {
      maxWidth: "unset",
    },
  },
  spacerGrid: {
    flexGrow: 1,
    order: 2,
    [theme.breakpoints.up('lg')]: {
      order: 3,
    },
  },
  createCaseGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
    order: 3,
    [theme.breakpoints.up('lg')]: {
      order: 7,
    },
  },
  customerGrid: {
    order: 4,
    [theme.breakpoints.up('md')]: {
      order: 6,
      flexGrow: 1,
    },
  },
  dateGrid: {
    order: 5,
    [theme.breakpoints.up('lg')]: {
      order: 2,
    },
  },
  customerDateGrid: {
    order: 5,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  dateFab: {
    margin: theme.spacing(1),
  },
  customerControl: {
    minWidth: "250px",
  },
  customerLogo: {
    maxHeight: "42px",
    maxWidth: "230px",
    [theme.breakpoints.down('md')]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: "100%",
    },
  },
  datePicker: {
    width: "160px",
    [theme.breakpoints.down('sm')]: {
      width: "auto",
      minWidth: "160px",
      maxWidth: "calc(50% - 56px)",
    },
  },
  logoPlaceHolder: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(1),
    height: "42px",
    whiteSpace: "pre-wrap",
    maxWidth: "230px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
  },
  grid: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  toolbarRoot: {
    justifyContent: "flex-end",
    flexWrap: "wrap",
    padding: 0,
    minHeight: 0,
  },
  toolbarSpacer: {
    display: "none",
  },
  toolbarActions: {
    flexShrink: 0,
  },
  toolbarSearchField: {
    padding: 0,
    minWidth: "160px",
    maxWidth: "100%",
    width: "calc(100% - 96px)",
    [theme.breakpoints.up('sm')]: {
      maxWidth: "200px",
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: "210px",
    },
  },
  tableBody: {
    maxHeight: "100vh"
  },
  lastComment: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
  },
  rowRoot: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover + " !important",
    },
    '&:hover': {
      background: theme.palette.action.selected + " !important",
    },
  },
}));

const dateRangeTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#991B1F',
    },
    secondary: {
      main: '#429644',
    },
  },
  typography: {
    fontFamily: 'Fira Sans, sans-serif',
  },
});

const tableTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#991B1F',
    },
    secondary: {
      main: '#991B1F',
    },
  },
  typography: {
    fontFamily: 'Fira Sans, sans-serif',
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddRoundedIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <CheckRoundedIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearRoundedIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutlineRoundedIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRightRoundedIcon {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <EditRoundedIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAltRoundedIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterListRoundedIcon {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPageRoundedIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageRoundedIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightRoundedIcon {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftRoundedIcon {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearRoundedIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchRoundedIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownwardRoundedIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <RemoveRoundedIcon {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumnRoundedIcon {...props} ref={ref} />),
  Refresh: forwardRef((props, ref) => <RefreshRoundedIcon {...props} ref={ref} />),
};

const transform = (node, index) => {
  if (node.type === 'tag' && node.name === 'img') {
    return null;
  }
}

const options = {
  decodeEntities: true,
  transform
};

function Cases(props) {
  const classes = useStyles();

  const values = queryString.parse(props.location.search);
  values.startdate === "null" ? localStorage.setItem('StartDate', null) : !isNaN(Date.parse(values.startdate)) && localStorage.setItem('StartDate', new Date(values.startdate).toLocaleDateString());
  values.enddate === "null" ? localStorage.setItem('EndDate', null) : !isNaN(Date.parse(values.enddate)) && localStorage.setItem('EndDate', new Date(values.enddate).toLocaleDateString());

  const [caseData, setCaseData] = useState([]);
  const [startDate, setStartDate] = useState(localStorage.getItem('StartDate') ? localStorage.getItem('StartDate') === 'null' ? null : new Date(localStorage.getItem('StartDate')).setHours(0, 0, 0, 0) : new Date(new Date().setHours(0, 0, 0, 0)).setMonth(new Date().getMonth() - 3));
  const [endDate, setEndDate] = useState(localStorage.getItem('EndDate') ? localStorage.getItem('EndDate') === 'null' ? null : new Date(localStorage.getItem('EndDate')).setHours(23, 59, 59, 999) : new Date().setHours(23, 59, 59, 999));
  const [tempStartDate, setTempStartDate] = useState(localStorage.getItem('StartDate') ? localStorage.getItem('StartDate') === 'null' ? null : new Date(localStorage.getItem('StartDate')).setHours(0, 0, 0, 0) : new Date(new Date().setHours(0, 0, 0, 0)).setMonth(new Date().getMonth() - 3));
  const [tempEndDate, setTempEndDate] = useState(localStorage.getItem('EndDate') ? localStorage.getItem('EndDate') === 'null' ? null : new Date(localStorage.getItem('EndDate')).setHours(23, 59, 59, 999) : new Date().setHours(23, 59, 59, 999));
  const [customerId, setCustomerId] = useState(localStorage.getItem('CustomerID') || "");
  const [customerName, setCustomerName] = useState(localStorage.getItem('CustomerName') || "");
  const [logoLoaded, setLogoLoaded] = useState(true);
  const [customerList, setCustomerList] = useState([]);
  const [activeCasesOnly, setActiveCasesOnly] = useState(values.activeonly?.toLowerCase() === "false" ? false : true);
  const [isLoading, setIsLoading] = useState(true);
  const [customerLookup, setCustomerLookup] = useState({});
  const [projectLookup, setProjectLookup] = useState({});
  const [csmLookup, setCsmLookup] = useState({});
  const [statusLookup, setStatusLookup] = useState({});
  const [sourceLookup, setSourceLookup] = useState({});
  const [priorityLookup, setPriorityLookup] = useState({});
  const [customerPriorityLookup, setCustomerPriorityLookup] = useState({});
  const [requestTypeLookup, setRequestTypeLookup] = useState({});
  const [ownerLookup, setOwnerLookup] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);

  const [defaultCaseId, setDefaultCaseId] = useState(values.caseid ? values.caseid.toString() : null);
  const [defaultTitle, setDefaultTitle] = useState(values.title ? values.title.toString() : null);
  const [defaultCreatedOn, setDefaultCreatedOn] = useState(values.createdon ? new Date(values.createdon) : null);
  const [defaultCustomer, setDefaultCustomer] = useState(values.customer ? values.customer.toString().split('|') : []);
  const [defaultProject, setDefaultProject] = useState(values.project ? values.project.toString().split('|') : []);
  const [defaultContact, setDefaultContact] = useState(values.contact ? values.contact.toString() : null);
  const [defaultStatus, setDefaultStatus] = useState(values.status ? values.status.toString().split('|') : []);
  const [defaultSource, setDefaultSource] = useState(values.source ? values.source.toString().split('|') : []);
  const [defaultCsm, setDefaultCsm] = useState(values.csm ? values.csm.toString().split('|') : []);
  const [defaultPriority, setDefaultPriority] = useState(values.priority ? values.priority.toString().split('|') : []);
  const [defaultCustomerPriority, setDefaultCustomerPriority] = useState(values.customerpriority ? values.customerpriority.toString().split('|') : []);
  const [defaultTimeBooked, setDefaultTimeBooked] = useState(values.timebooked ? values.timebooked.toString() : null);
  const [defaultRequestType, setDefaultRequestType] = useState(values.requesttype ? values.requesttype.toString().split('|') : []);
  const [defaultThirdPartyReference, setDefaultThirdPartyReference] = useState(values.thirdpartyreference ? values.thirdpartyreference.toString() : null);
  const [defaultClientReference, setDefaultClientReference] = useState(values.clientreference ? values.clientreference.toString() : null);
  const [defaultLastUpdated, setDefaultLastUpdated] = useState(values.lastupdated ? new Date(values.lastupdated) : null);
  const [defaultOwner, setDefaultOwner] = useState(values.owner ? values.owner.toString().split('|') : []);
  const [defaultLastComment, setDefaultLastComment] = useState(values.lastcomment ? values.lastcomment.toString() : null);
  const [defaultCaseAge, setDefaultCaseAge] = useState(values.caseage ? values.caseage : null);
  const [defaultDevOpsWorkItem, setDefaultDevOpsWorkItem] = useState(values.devopsworkitem ? values.devopsworkitem : null);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  let filterCaseId = defaultCaseId;
  let filterTitle = defaultTitle;
  let filterCustomer = defaultCustomer;
  let filterProject = defaultProject;
  let filterContact = defaultContact;
  let filterCsm = defaultCsm;
  let filterCreatedOn = defaultCreatedOn;
  let filterStatus = defaultStatus;
  let filterSource = defaultSource;
  let filterPriority = defaultPriority;
  let filterCustomerPriority = defaultCustomerPriority;
  let filterTimeBooked = defaultTimeBooked;
  let filterRequestType = defaultRequestType;
  let filterThirdPartyReference = defaultThirdPartyReference;
  let filterClientReference = defaultClientReference;
  let filterLastUpdated = defaultLastUpdated;
  let filterCaseAge = defaultCaseAge;
  let filterOwner = defaultOwner;
  let filterLastComment = defaultLastComment;
  let filterDevOpsWorkItem = defaultDevOpsWorkItem;

  useEffect(() => {
    document.title = "Cases | Vision Customer Portal";
    return () => {
      document.title = "Vision Customer Portal";
      defaultCaseId !== filterCaseId && setDefaultCaseId(filterCaseId);
      defaultTitle !== filterTitle && setDefaultTitle(filterTitle);
      defaultCustomer !== filterCustomer && setDefaultCustomer(filterCustomer);
      defaultProject !== filterProject && setDefaultProject(filterProject);
      defaultContact !== filterContact && setDefaultContact(filterContact);
      defaultCsm !== filterCsm && setDefaultCsm(filterCsm);
      defaultCreatedOn !== filterCreatedOn && setDefaultCreatedOn(filterCreatedOn);
      defaultStatus !== filterStatus && setDefaultStatus(filterStatus);
      defaultSource !== filterSource && setDefaultSource(filterSource);
      defaultPriority !== filterPriority && setDefaultPriority(filterPriority);
      defaultCustomerPriority !== filterCustomerPriority && setDefaultCustomerPriority(filterCustomerPriority);
      defaultTimeBooked !== filterTimeBooked && setDefaultTimeBooked(filterTimeBooked);
      defaultRequestType !== filterRequestType && setDefaultRequestType(filterRequestType);
      defaultThirdPartyReference !== filterThirdPartyReference && setDefaultThirdPartyReference(filterThirdPartyReference);
      defaultClientReference !== filterClientReference && setDefaultClientReference(filterClientReference);
      defaultLastUpdated !== filterLastUpdated && setDefaultLastUpdated(filterLastUpdated);
      defaultCaseAge !== filterCaseAge && setDefaultCaseAge(filterCaseAge);
      defaultOwner !== filterOwner && setDefaultOwner(filterOwner);
      defaultLastComment !== filterLastComment && setDefaultLastComment(filterLastComment);
      defaultDevOpsWorkItem !== filterDevOpsWorkItem && setDefaultDevOpsWorkItem(filterDevOpsWorkItem);
    }
  });

  useEffect(() => {
    loadCustomerList();
    refresh();
    // eslint-disable-next-line
  }, []);

  const handleActiveToggle = (event) => {
    setActiveCasesOnly(event.target.checked);
    refresh(undefined, undefined, undefined, event.target.checked);
  }

  const clearFilter = () => {
    setDefaultCaseId(null)
    setDefaultTitle(null)
    setDefaultCreatedOn(null)
    setDefaultCustomer([])
    setDefaultProject([])
    setDefaultContact(null)
    setDefaultStatus([])
    setDefaultSource([])
    setDefaultCsm([])
    setDefaultPriority([])
    setDefaultCustomerPriority([])
    setDefaultTimeBooked(null)
    setDefaultRequestType([])
    setDefaultThirdPartyReference(null)
    setDefaultClientReference(null)
    setDefaultLastUpdated(null)
    setDefaultOwner([])
    setDefaultLastComment(null)
    setDefaultCaseAge(null)
    setDefaultDevOpsWorkItem(null)

    filterCaseId = null;
    filterTitle = null;
    filterCustomer = [];
    filterProject = [];
    filterContact = null;
    filterCsm = [];
    filterCreatedOn = null;
    filterStatus = [];
    filterSource = [];
    filterPriority = [];
    filterCustomerPriority = [];
    filterTimeBooked = null;
    filterRequestType = [];
    filterThirdPartyReference = null;
    filterClientReference = null;
    filterLastUpdated = null;
    filterCaseAge = null;
    filterOwner = null;
    filterLastComment = null;
    filterDevOpsWorkItem = null;
  }

  const refresh = (
    localStartDate = startDate,
    localEndDate = endDate,
    localCustomerId = customerId || "",
    activeOnly = activeCasesOnly,
  ) => {
    setIsLoading(true);
    setCaseData([]);

    localStartDate = localStartDate || new Date('1/1/1900');
    localEndDate = localEndDate || new Date('1/1/2100');

    axios
      .get("/cases", {
        params: {
          isActive: activeOnly,
          startDate: localStartDate && new Date(localStartDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: localEndDate && new Date(localEndDate).toLocaleString('en-US', { timeZone: "UTC" }),
          customerid: localCustomerId === "" ? null : localCustomerId,
        }
      })
      .then((response) => {
        let distinctCustomer = new Set();
        let distinctProject = new Set();
        let distinctCSM = new Set();
        let distinctStatus = new Set();
        let distinctSource = new Set();
        let distinctPriority = new Set();
        let distinctCustomerPriority = new Set();
        let distinctRequestType = new Set();
        let distinctOwner = new Set();

        response.data.map((data) => {
          data.customer_text && distinctCustomer.add(data.customer_text)
          data.project_text && distinctProject.add(data.project_text)
          data.projectManager && distinctCSM.add(data.projectManager)
          data.status_text && distinctStatus.add(data.status_text)
          data.source_text && distinctSource.add(data.source_text)
          data.priority_text && distinctPriority.add(data.priority_text)
          data.customer_severity && distinctCustomerPriority.add(data.customer_severity)
          data.casetype_text && distinctRequestType.add(data.casetype_text)
          data.fullname && distinctOwner.add(data.fullname)
          return null
        })

        let sortedCustomer = Array.from(distinctCustomer).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        let sortedProject = Array.from(distinctProject).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        let sortedCSM = Array.from(distinctCSM).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        let sortedStatus = Array.from(distinctStatus).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        let sortedSource = Array.from(distinctSource).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        let sortedPriority = Array.from(distinctPriority).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        let sortedCustomerPriority = Array.from(distinctCustomerPriority).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        let sortedRequestType = Array.from(distinctRequestType).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        let sortedOwner = Array.from(distinctOwner).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        let customerList = {};
        let projectList = {};
        let csmList = {};
        let statusList = {};
        let sourceList = {};
        let priorityList = {};
        let customerPriorityList = {};
        let requestTypeList = {};
        let ownerList = {};

        sortedCustomer.map((i) => {
          customerList[i] = i;
          return null
        })
        sortedProject.map((i) => {
          projectList[i] = i;
          return null
        })
        sortedCSM.map((i) => {
          csmList[i] = i;
          return null
        })
        sortedStatus.map((i) => {
          statusList[i] = i;
          return null
        })
        sortedSource.map((i) => {
          sourceList[i] = i;
          return null
        })
        sortedPriority.map((i) => {
          priorityList[i] = i;
          return null
        })
        sortedCustomerPriority.map((i) => {
          customerPriorityList[i] = i;
          return null
        })
        sortedRequestType.map((i) => {
          requestTypeList[i] = i;
          return null
        })
        sortedOwner.map((i) => {
          ownerList[i] = i;
          return null
        })

        setCustomerLookup(customerList);
        setProjectLookup(projectList);
        setCsmLookup(csmList);
        setStatusLookup(statusList);
        setSourceLookup(sourceList);
        setPriorityLookup(priorityList);
        setCustomerPriorityLookup(customerPriorityList);
        setRequestTypeLookup(requestTypeList);
        setOwnerLookup(ownerList);
        setCaseData(response.data);
      })
      .catch((error) => {
        console.log(error)
      })
      .then(() => {
        setIsLoading(false);
      })
  }

  const loadCustomerList = () => {
    axios
      .get("/Accounts")
      .then((response) => {
        setCustomerList(response.data);
        props.isCustomer && response.data.length == 1 ? setShowAccountDropdown(false): setShowAccountDropdown(true);
      })
      .catch((error) => console.log(error))
  }

  const handleCustomerChange = (event) => {
    const customerId = event.target.value;
    const customerName = event.target.value === "" ? "" : event.nativeEvent.target.innerText;

    clearFilter();
    localStorage.removeItem('CustomerID');
    localStorage.removeItem('CustomerName');
    localStorage.removeItem('ProjectID');
    localStorage.removeItem('ProjectName');

    localStorage.setItem('CustomerID', customerId);
    localStorage.setItem('CustomerName', customerName);

    setCustomerId(customerId);
    setCustomerName(customerName);

    refresh(startDate, endDate, customerId);
  }

  const handleClearDateRange = () => {
    setTempStartDate(null);
    setTempEndDate(null);
  }

  const handleLogoLoad = (isSuccess, i) => {
    setLogoLoaded(isSuccess);
  }

  const handleStartDateChange = (date) => {
    setTempStartDate(date === null ? null : new Date(date).setHours(0, 0, 0, 0));
  }

  const handleEndDateChange = (date) => {
    setTempEndDate(date === null ? null : new Date(date).setHours(23, 59, 59, 999));
  }

  const handleDefaultDateRange = () => {
    setTempStartDate(new Date(new Date().setHours(0, 0, 0, 0)).setMonth(new Date().getMonth() - 3));
    setTempEndDate(new Date().setHours(23, 59, 59, 999));
  }

  const handleRefresh = () => {
    localStorage.setItem("StartDate", tempStartDate === null ? null : new Date(tempStartDate).toLocaleDateString());
    localStorage.setItem("EndDate", tempEndDate === null ? null : new Date(tempEndDate).toLocaleDateString());

    setStartDate(tempStartDate);
    setEndDate(tempEndDate);

    refresh(tempStartDate, tempEndDate, customerId)
  }

  let datesChanged = (tempStartDate === startDate && tempEndDate === endDate)
  let datesCleared = (tempStartDate === null && tempEndDate === null)
  let datesInvalid = (tempEndDate !== null && tempEndDate !== null && tempStartDate > tempEndDate)

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={2} justify="space-between" alignItems="flex-end" className={classes.header}>
          {(props.customerCompanyID || customerId) && (
            <Grid item className={classes.logoGrid}>
              <>
                <img
                  alt={props.isCustomer ? props.companyName : customerName}
                  src={
                    "https://hmsportalstorage.blob.core.windows.net/hmsportalcontainer/logos/" +
                    (props.isCustomer ? props.customerCompanyID : customerId) + ".png"
                  }
                  onLoad={i => handleLogoLoad(true, i)}
                  onError={i => handleLogoLoad(false, i)}
                  style={{ display: logoLoaded ? "flex" : "none" }}
                  className={classes.customerLogo}
                />
                {!logoLoaded && (
                  <Paper className={classes.logoPlaceHolder}>
                    <Typography variant="body2">
                      {props.isCustomer ? props.companyName : customerName}
                    </Typography>
                  </Paper>
                )}
              </>
            </Grid>
          )}
          <Grid item zeroMinWidth className={classes.spacerGrid}>
          </Grid>
          <Grid item sm={props.isCustomer ? 6 : 'auto'} md={props.isCustomer ? 'auto' : 6} lg={'auto'} className={classes.createCaseGrid}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.createCase}
              component={RouterLink}
              to="/cases/new/"
            >
              Create Case
            </Button>
          </Grid>
          {showAccountDropdown &&
            <Grid item xs={12} sm={12} md={6} lg={3} className={classes.customerGrid}>
              <FormControl
                fullWidth
                variant="filled"
                className={classes.customerControl}
              >
                <InputLabel shrink id="customer-label">Accounts</InputLabel>
                <Select
                  labelId="customer-label"
                  id="customers"
                  displayEmpty
                  defaultValue=""
                  value={customerId}
                  onChange={handleCustomerChange}
                >
                  <MenuItem value="">
                    <em>All Accounts</em>
                  </MenuItem>
                  {customerList.map((customer) => (
                    <MenuItem key={customer.Id} value={customer.Id} name={customer.Name}>
                      {customer.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item md={6} lg={5} xl={3} className={props.isCustomer ? classes.customerDateGrid : classes.dateGrid}>
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start" alignItems="flex-end" flexGrow={1}>
              <Box px={1} flexGrow={1} className={classes.datePicker}>
                <KeyboardDatePicker
                  autoOk
                  clearable
                  showTodayButton
                  fullWidth
                  id="start-date"
                  label="Start Date"
                  format="MM/dd/yyyy"
                  maxDate={tempEndDate || undefined}
                  maxDateMessage="Start Date cannot be later than End Date."
                  value={tempStartDate}
                  onChange={handleStartDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'start date',
                  }}
                />
              </Box>
              <Box px={1} flexGrow={1} className={classes.datePicker}>
                <KeyboardDatePicker
                  autoOk
                  clearable
                  showTodayButton
                  fullWidth
                  id="end-date"
                  label="End Date"
                  format="MM/dd/yyyy"
                  minDate={tempStartDate || undefined}
                  minDateMessage="End Date cannot be earlier than Start Date"
                  value={tempEndDate}
                  onChange={handleEndDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'end date',
                  }}
                />
              </Box>
              <Box display="flex">
                <ThemeProvider theme={dateRangeTheme}>
                  <Tooltip title={datesCleared ? "Default Date Range" : "Clear Date Range"}>
                    <Fab
                      size="small"
                      color={datesCleared ? "primary" : "inherit"}
                      className={classes.dateFab}
                      onClick={datesCleared ? handleDefaultDateRange : handleClearDateRange}
                      aria-label="clear date range"
                    >
                      {datesCleared ? <RestoreRoundedIcon /> : <ClearRoundedIcon />}
                    </Fab>
                  </Tooltip>
                  <Tooltip title={datesChanged ? "Refresh Cases" : "Retrieve Cases"}>
                    <Fab
                      disabled={datesInvalid}
                      size="small"
                      color={datesChanged ? "primary" : "secondary"}
                      className={classes.dateFab}
                      onClick={handleRefresh}
                      aria-label="clear date range"
                    >
                      {datesChanged ? <UpdateRoundedIcon /> : <ArrowForwardRoundedIcon />}
                    </Fab>
                  </Tooltip>
                </ThemeProvider>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
      <ThemeProvider theme={tableTheme}>
        <MaterialTable
          icons={tableIcons}
          isLoading={isLoading}
          options={{
            exportButton: true,
            exportAllData: true,
            exportFileName: `All Cases ${new Date().toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}`,
            filtering: true,
            sorting: true,
            draggable: false,
            padding: 'dense',
            pageSize: 10,
            pageSizeOptions: [10, 20, 40],
            paging: !smDown,
            maxBodyHeight: smDown ? false : props.isCustomer ? 'calc(100vh - 300px)' : (lgUp ? 'calc(100vh - 300px)' : 'calc(100vh - 360px)'),
            emptyRowsWhenPaging: false,
            doubleHorizontalScroll: !smDown,
            showTitle: false,
            rowStyle: rowData => ({
              backgroundColor: (selectedRow === rowData.tableData.id) ? '#E8E8E8' : '#FFF'
            }),
          }}
          onRowClick={smDown ? false : ((evt, selectedRow) => {
            let selectedRowId = selectedRow.tableData.id
            let currentSelectedRow = selectedRow
            setSelectedRow(currentSelectedRow === selectedRowId ? null : selectedRowId);
          })}
          actions={[
            {
              icon: tableIcons.Clear,
              tooltip: 'Clear Filter',
              isFreeAction: true,
              onClick: (event) => clearFilter(),
            },
          ]}
          components={{
            Toolbar: (props) => (
              <Grid container spacing={2} justify="space-between" alignItems="flex-start" className={classes.grid}>
                <Grid item xs={12} sm={'auto'}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={activeCasesOnly}
                        onChange={handleActiveToggle}
                        name="activeCasesOnly"
                        color="primary"
                      />
                    }
                    label="Active Cases Only"
                  />
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                  <MTableToolbar
                    classes={{
                      root: classes.toolbarRoot,
                      spacer: classes.toolbarSpacer,
                      actions: classes.toolbarActions,
                      searchField: classes.toolbarSearchField,
                    }}
                    {...props}
                  />
                </Grid>
              </Grid>
            ),
            Header: (props) => (
              <MTableHeader
                classes={{
                  header: classes.headerHeader,
                }}
                {...props}
              />
            ),
            Body: (props) => (
              <MTableBody {...props} onFilterChanged={(columnId, value) => {
                props.onFilterChanged(columnId, value);
                props.columns[columnId].field === "ticketnumber" && (filterCaseId = value)
                props.columns[columnId].field === "title" && (filterTitle = value)
                if(showAccountDropdown) { props.columns[columnId].field === "customer_text" && (filterCustomer = value) }
                props.columns[columnId].field === "project_text" && (filterProject = value)
                props.columns[columnId].field === "contact" && (filterContact = value)
                props.columns[columnId].field === "projectManager" && (filterCsm = value)
                props.columns[columnId].field === "createdon" && (filterCreatedOn = value)
                props.columns[columnId].field === "status_text" && (filterStatus = value)
                props.columns[columnId].field === "source_text" && (filterSource = value)
                props.columns[columnId].field === "priority_text" && (filterPriority = value)
                props.columns[columnId].field === "customer_severity" && (filterCustomerPriority = value)
                props.columns[columnId].field === "timebooked" && (filterTimeBooked = value)
                props.columns[columnId].field === "casetype_text" && (filterRequestType = value)
                props.columns[columnId].field === "hsal_3rdpartyreference" && (filterThirdPartyReference = value)
                props.columns[columnId].field === "hsal_clientreference" && (filterClientReference = value)
                props.columns[columnId].field === "modifiedon" && (filterLastUpdated = value)
                props.columns[columnId].field === "caseAge" && (filterCaseAge = value)
                props.columns[columnId].field === "fullname" && (filterOwner = value)
                props.columns[columnId].field === "externalcomment" && (filterLastComment = value)
                props.columns[columnId].field === "devopsworkitem" && (filterDevOpsWorkItem = value)
                if (props.columns[columnId].field === "status_text" && value.includes("Closed")) {
                  activeCasesOnly === true && setActiveCasesOnly(false)
                }
              }} />
            ),
            Row: (props) => (
              <MTableBodyRow
                classes={{
                  root: classes.rowRoot,
                }}
                {...props}
              />
            ),
          }}
          columns={[
            {
              title: "Case ID",
              field: "ticketnumber",
              headerStyle: {
                whiteSpace: "nowrap",
                minWidth: "160px",
              },
              render: (rowData) => (
                <Link component={RouterLink} to={"/cases/" + rowData.ticketnumber.toString().toLowerCase()}>{rowData.ticketnumber}</Link>
              ),
              defaultFilter: defaultCaseId,
              defaultSort: "desc",
            },
            {
              title: "Title",
              field: "title",
              headerStyle: {
                minWidth: "250px",
                maxWidth: "500px",
              },
              cellStyle: {
                overflowWrap: "anywhere",
              },
              render: (rowData) => (
                rowData.hasAttachments ? <><AttachFileRoundedIcon /> {rowData.title}</> : rowData.title
              ),
              defaultFilter: defaultTitle,
            },
            { title: "Account", field: "customer_text", lookup: customerLookup, hidden: !showAccountDropdown , defaultFilter: defaultCustomer } ,
            {
              title: "Project",
              field: "project_text",
              headerStyle: {
                minWidth: "250px",
                maxWidth: "500px",
              },
              lookup: projectLookup,
              defaultFilter: defaultProject
            },
            { title: "Account Contact", field: "contact", defaultFilter: defaultContact },
            { title: "Owner", field: "fullname", lookup: ownerLookup, defaultFilter: defaultOwner },
            { title: "CSM", field: "projectManager", lookup: csmLookup, hidden: props.isCustomer, defaultFilter: defaultCsm },
            { title: "Status", field: "status_text", lookup: statusLookup, defaultFilter: defaultStatus },
            { title: "Source", field: "source_text", lookup: sourceLookup, hidden: props.isCustomer, defaultFilter: defaultSource },
            { title: "Priority", field: "priority_text", lookup: priorityLookup, defaultFilter: defaultPriority },
            { title: "Account Priority", field: "customer_severity", lookup: customerPriorityLookup, defaultFilter: defaultCustomerPriority },
            { title: "Time Booked", field: "timebooked", type: "numeric", defaultFilter: defaultTimeBooked },
            { title: "Request Type", field: "casetype_text", lookup: requestTypeLookup, defaultFilter: defaultRequestType },
            { title: "3rd Party reference", field: "hsal_3rdpartyreference", defaultFilter: defaultThirdPartyReference },
            { title: "Client Reference", field: "hsal_clientreference", defaultFilter: defaultClientReference },
            {
              title: "DevOps Work Item", field: "hsl_devopsworkitemid",
              headerStyle: {
                minWidth: "140px",
              },
              render: (rowData) => (
                <Link href={rowData.hsl_devopsworkitemurl} target="_blank" rel="noreferrer">{rowData.hsl_devopsworkitemid}</Link>
              ),
              defaultFilter: defaultDevOpsWorkItem
            },
            {
              title: "Created On",
              field: "createdon",
              headerStyle: {
                minWidth: "150px",
              },
              type: "date",
              defaultFilter: defaultCreatedOn
            },
            {
              title: "Last Updated",
              field: "modifiedon",
              headerStyle: {
                minWidth: "150px",
              },
              type: "date",
              defaultFilter: defaultLastUpdated
            },
            {
              title: "Case Age (Days)", field: "caseAge", type: "numeric", defaultFilter: defaultCaseAge, export: false,
              headerStyle: { minWidth: "130px" },
              render: rowData => <div>{
                rowData.status_text === "Closed" ?
                  Math.floor((new Date(rowData.modifiedon) - new Date(rowData.createdon)) / 86400000) :
                  Math.floor((new Date() - new Date(rowData.createdon)) / 86400000)
              }</div>,
              customFilterAndSearch: (term, rowData) =>
              (rowData.status_text === "Closed" ?
                Number(term) === Math.floor((new Date(rowData.modifiedon) - new Date(rowData.createdon)) / 86400000) :
                Number(term) === Math.floor((new Date() - new Date(rowData.createdon)) / 86400000)),
              customSort: (a, b) =>
                (a.status_text === "Closed" ?
                  Math.floor((new Date(a.modifiedon) - new Date(a.createdon)) / 86400000) :
                  Math.floor((new Date() - new Date(a.createdon)) / 86400000)) -
                (b.status_text === "Closed" ?
                  Math.floor((new Date(b.modifiedon) - new Date(b.createdon)) / 86400000) :
                  Math.floor((new Date() - new Date(b.createdon)) / 86400000))
            },
            {
              title: "Last Comment",
              field: "externalcomment",
              headerStyle: {
                minWidth: "250px",
                maxWidth: "750px",
              },
              render: (rowData) => (
                <p className={classes.lastComment}>{ReactHtmlParser(rowData.externalcomment, options)}</p>
              ),
              defaultFilter: defaultLastComment,
            },
            { title: "Case State", field: "state_text", hidden: true, defaultFilter: activeCasesOnly ? "Active" : "" },
          ]}
          data={caseData}
        />
      </ThemeProvider>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    isCustomer: state.auth.role === "customer",
    customerCompanyID: state.auth.authCompany,
    companyName: state.auth.authCompanyName,
  };
};
export default connect(mapStateToProps, null)(Cases);