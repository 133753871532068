import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const TableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell component="th" scope="row"><Skeleton animation="wave" /></TableCell>
      <TableCell align="right"><Skeleton animation="wave" /></TableCell>
    </TableRow>
  )
}

const CasesByStatus = (props) => {
  let startDate = new Date(props.startDate).toLocaleDateString();
  let endDate = new Date(props.endDate).toLocaleDateString();
  let csmName = props.csmName || "";
  let customerName = props.customerName || "";

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell align="right">Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.tableData ? (
            props.tableData.map((status,index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Link 
                    component={RouterLink} 
                    to={
                      "/cases?status=" + status.text.toString() +
                      "&startdate=" + startDate + 
                      "&enddate=" + endDate +
                      "&csm=" + csmName +
                      "&customer=" + customerName +
                      "&activeonly=false"
                    }
                  >
                    {status.text}
                  </Link>
                </TableCell>
                <TableCell align="right">{status.count}</TableCell>
              </TableRow>
            ))) : (
              <>
                <TableRowSkeleton />
                <TableRowSkeleton />
                <TableRowSkeleton />
                <TableRowSkeleton />
                <TableRowSkeleton />
                <TableRowSkeleton />
                <TableRowSkeleton />
                <TableRowSkeleton />
                <TableRowSkeleton />
                <TableRowSkeleton />
                <TableRowSkeleton />
              </>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default CasesByStatus;