import * as actionTypes from '../actions/actiontypes';
import { updateObject } from '../utility';

const initialState = {
    token: null,
    userId: null,
    role: null,
    error: null,
    authRedirectPath: '/',
    authName: null,
    authCompany: null,
    authCompanyName: null,
    authImage: null,
    workspace: null,
    envisionAccount: null,
    lcsProjectId: null,
    lcsProjectType: null,
    envisionUser: null,
    monitoringUser: null,
    microsoftId: null,
    cspAccount: null,
    cspUser: null,
    cspPurchasingUser: null,
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.idToken,
        userId: action.userId,
        role: action.role
    });
};

const authProfile = (state, action) => {
    return updateObject(state, {
        authName: action.authName,
        authCompany: action.authCompany,
        authCompanyName: action.authCompanyName,
        authImage: action.authImage,
        workspace: action.workspace,
        envisionAccount: action.envisionAccount,
        lcsProjectId: action.lcsProjectId,
        lcsProjectType: action.lcsProjectType,
        envisionUser: action.envisionUser,
        monitoringUser: action.monitoringUser,
        microsoftId: action.microsoftId,
        cspAccount: action.cspAccount,
        cspUser: action.cspUser,
        cspPurchasingUser: action.cspPurchasingUser,
    });
};

const setWorkspace = (state, action) => {
    return updateObject(state, {
        workspace: action.workspace
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userId: null, role: null });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.SET_AUTH_PROFILE: return authProfile(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        case actionTypes.SET_WORKSPACE: return setWorkspace(state, action);
        default:
            return state;
    }
};

export default reducer;