import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EmojiFoodBeverageRoundedIcon from '@material-ui/icons/EmojiFoodBeverageRounded';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
}));

function PageNotFound(props) {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center" >
        <Grid item>
          <EmojiFoodBeverageRoundedIcon style={{ fontSize: 72 }} />
          <Typography>
            Oops! Looks like something went wrong here.
          </Typography>
          <Typography>
            Take a moment and relax...
          </Typography>
          <Typography>
            {"Once you're ready, start from the "}
            <Link component={RouterLink} to="/dashboard/" >
              Dashboard
            </Link>
            {" page or if the problem persists contact "}
            <Link href="mailto:vision@hitachisolutions.com">
              vision@hitachisolutions.com
            </Link>
            {"."}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default PageNotFound;