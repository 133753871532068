import axios from 'axios';
import { decryptObject} from '../store/utility';

const instance = axios.create({
    baseURL: 'https://hmsservices.azurewebsites.net/'
});

//instance.defaults.headers.common['Authorization'] =  localStorage.getItem('token');
instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(request => {
    const data = decryptObject();
    request.headers.authorization = data?.access_token;
    return request;
}, error => {
    console.log(error);
    return Promise.reject(error);
});

instance.interceptors.response.use(response => {
    return response;
},error =>{
    console.log(error);
    return Promise.reject(error);
});


export default instance;