import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from "../VisionServices/axios";
import PowerBIReports from "./PowerBIReports";
import BatchJobHistory from "./BatchJobHistory";

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Container component="main" maxWidth={false} className={classes.root}>
          {children}
        </Container>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  customerGrid: {
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  tabsGrid: {
    order: 2,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    height: "100%",
  },
  iframe: {
    width: "100%",
    border: "none",
  },
  monitorTab: {
    maxWidth: 'none',
  },
  timezoneIndicator: {
    fontStyle: 'italic',
    textAlign: 'end',
  }
}));

function Monitoring(props) {
  const classes = useStyles();
  const [value, setValue] = useState(false);
  const [monitors, setMonitors] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [customerId, setCustomerId] = useState(localStorage.getItem('CustomerID') || props.customerCompanyID || "")
  const [customer, setCustomer] = useState(null);
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    !props.isCustomer && loadCustomerList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    props.isCustomer ? loadCustomerMonitors() : loadMonitors();
  }, [customerId, customerList]);

  useEffect(() => {
    (value !== 999) && loadWidgets();
  }, [value]);

  const loadCustomerList = () => {
    axios
      .get("/Accounts")
      .then((response) => {
        setCustomerList(response.data.filter(customer => customer.workspaceId !== null));
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const loadCustomerMonitors = () => {
    setMonitors([])
    axios
      .get("/monitoring", {
        params: {
          customerid: props.customerCompanyID,
          workspaceId: props.workspaceID,
          allReportsData: props.workspaceID === "LMEnabled" ? false : true,
        }
      })
      .then((response) => {
        let monitorList = props.workspaceID === "LMEnabled" ? (
          response.data.value?.filter(monitor => monitor.hsl_active === true).sort((a, b) => {
            return a.hsl_order - b.hsl_order;
          })
        ) : (
          response.data
        )
        setMonitors(monitorList)
        setValue(props.workspaceID === "LMEnabled" ? monitorList[0].hsl_monitorid : monitorList[0].id)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const loadMonitors = () => {
    setMonitors([])
    let customer = customerList.filter(customer => customer.Id === customerId);
    customer.length > 0 && setCustomer(customer[0])

    customer.length > 0 && axios
      .get("/monitoring", {
        params: {
          customerid: customer[0]?.workspaceId === "LMEnabled" ? customer[0]?.Id : null,
          workspaceId: customer[0]?.workspaceId,
          allReportsData: customer[0]?.workspaceId === "LMEnabled" ? false : true,
        }
      })
      .then((response) => {
        let monitorList = customer[0]?.workspaceId === "LMEnabled" ? (
          response.data.value?.filter(monitor => monitor.hsl_active === true).sort((a, b) => {
            return a.hsl_order - b.hsl_order;
          })
        ) : (
          response.data
        )
        setMonitors(monitorList)
        setValue(customer[0]?.workspaceId === "LMEnabled" ? monitorList[0].hsl_monitorid : monitorList[0].id)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const loadWidgets = () => {
    axios
      .get("/monitoring/" + value)
      .then((response) => {
        setWidgets(response.data.value?.filter(widget => widget.hsl_active === true).sort((a, b) => {
          return a.hsl_ordernumber - b.hsl_ordernumber;
        }))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCustomerChange = (event) => {
    const customerId = event.target.value;
    const customerName = event.target.value === "" ? "" : event.nativeEvent.target.innerText;

    localStorage.removeItem('CustomerID');
    localStorage.removeItem('CustomerName');

    localStorage.setItem('CustomerID', customerId);
    localStorage.setItem('CustomerName', customerName);

    setCustomerId(customerId);

  }

  return (
    <div>
      <Toolbar variant="dense" />
      {(!props.isCustomer && smDown) && <Toolbar />}
      <AppBar position="fixed" color="default">
        <Toolbar />
        <Grid container spacing={1}>
          {!props.isCustomer &&
            <Grid item xs={12} md={3} className={classes.customerGrid}>
              <FormControl
                fullWidth
                variant="filled"
                size="small"
                className={classes.customerControl}
              >
                <InputLabel shrink id="customer-label">Accounts</InputLabel>
                <Select
                  labelId="customer-label"
                  id="customers"
                  displayEmpty
                  defaultValue=""
                  value={customerId}
                  onChange={handleCustomerChange}
                >
                  <MenuItem value="">
                    <em>Choose an Account</em>
                  </MenuItem>
                  {customerList.map((customer) => (
                    <MenuItem key={customer.Id} value={customer.Id} name={customer.Name}>
                      {customer.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item xs={12} md={props.isCustomer ? 12 : 9} className={classes.tabsGrid}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="monitorings"
            >
              {(customer?.workspaceId === "LMEnabled" || props.workspaceID === "LMEnabled") ? (
                monitors?.map((monitor) => (
                  <Tab key={monitor.hsl_monitorid} label={monitor.hsl_monitorname} value={monitor.hsl_monitorid} {...a11yProps(monitor.hsl_monitorid)} className={classes.monitorTab} />
                ))
              ) : (customer === null && !props.isCustomer) ? (
                <Tab disabled />
              ) : (
                monitors?.map((monitor) => (
                  <Tab key={monitor.id} label={monitor.name} value={monitor.id} {...a11yProps(monitor.id)} className={classes.monitorTab} />
                ))
              )}
              {/* {(monitors?.length > 0) &&
                <Tab label="Batch Job History" value={999} {...a11yProps("batchjobhistory")} className={classes.monitorTab} />
              } */}
            </Tabs>
          </Grid>
        </Grid>
      </AppBar>

      {(customer?.workspaceId === "LMEnabled" || props.workspaceID === "LMEnabled") ? (
        monitors?.map((monitor) => (
          <TabPanel key={monitor.hsl_monitorid} value={value} index={monitor.hsl_monitorid}>
            <Grid container spacing={2}>
              {widgets?.filter(widget => widget._hsl_monitorid_value === monitor.hsl_monitorid).map((widget) => (
                <Grid item key={widget.hsl_widgetid} xs={12} md={widget.hsl_gridsize}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6">{widget.hsl_widgetname}</Typography>
                    <iframe
                      title={widget.hsl_widgetname}
                      className={classes.iframe}
                      style={{height:`${widget.hsal_gridheight}vh`}}
                      id={widget.hsl_widgetid}
                      sandbox="allow-same-origin allow-scripts"
                      src={widget.hsl_url} />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        ))) : (
        monitors?.map((item) => (
          <TabPanel key={item.id} value={value} index={item.id}>
            <PowerBIReports ReportId={item.id} WorkSpaceId={props.isCustomer ? props.workspaceID : customer.workspaceId} />
          </TabPanel>
        ))
      )
      }
      {/* {(monitors?.length > 0) &&
        <TabPanel value={value} index={999}>
          <BatchJobHistory />
        </TabPanel>
      } */}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isCustomer: state.auth.role === "customer",
    name: state.auth.authName,
    customerCompanyID: state.auth.authCompany,
    companyName: state.auth.authCompanyName,
    workspaceID: state.auth.workspace,
  };
};
export default connect(mapStateToProps, null)(Monitoring);