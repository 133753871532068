import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles, createMuiTheme, ThemeProvider, useTheme } from '@material-ui/core/styles';
import MaterialTable, { MTableToolbar, MTableBody, MTableHeader, MTableBodyRow } from "material-table";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import UpdateRoundedIcon from '@material-ui/icons/UpdateRounded';
import RestoreRoundedIcon from '@material-ui/icons/RestoreRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import FirstPageRoundedIcon from '@material-ui/icons/FirstPageRounded';
import LastPageRoundedIcon from '@material-ui/icons/LastPageRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import SaveAltRoundedIcon from '@material-ui/icons/SaveAltRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ViewColumnRoundedIcon from '@material-ui/icons/ViewColumnRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import axios from "../VisionServices/axios";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddRoundedIcon {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <CheckRoundedIcon {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <ClearRoundedIcon {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutlineRoundedIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRightRoundedIcon {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <EditRoundedIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAltRoundedIcon {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterListRoundedIcon {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPageRoundedIcon {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPageRoundedIcon {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRightRoundedIcon {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeftRoundedIcon {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <ClearRoundedIcon {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <SearchRoundedIcon {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownwardRoundedIcon {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <RemoveRoundedIcon {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumnRoundedIcon {...props} ref={ref} />),
  Refresh: forwardRef((props, ref) => <RefreshRoundedIcon {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(2),
  },
  datePicker: {
    width: "160px",
    [theme.breakpoints.down('sm')]: {
      width: "auto",
      minWidth: "160px",
      maxWidth: "calc(50% - 56px)",
    },
  },
  toolbarRoot: {
    justifyContent: "flex-end",
    flexWrap: "wrap",
    padding: 0,
  },
  toolbarSpacer: {
    display: "none",
  },
  toolbarActions: {
    flexShrink: 0,
  },
  toolbarSearchField: {
    padding: 0,
  },
  headerHeader: {
    verticalAlign: "bottom",
    whiteSpace: "nowrap",
    width: "auto !important",
  },
  rowRoot: {
    '&:hover': {
      background: theme.palette.action.selected + " !important",
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&[level="1"] > td:nth-child(2)': {
      paddingLeft: theme.spacing(4),
    },
  },
  dateFab: {
    margin: theme.spacing(1),
  },
}));

const tableTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#991B1F',
    },
    secondary: {
      main: '#429644',
    },
  },
  typography: {
    fontFamily: 'Fira Sans, sans-serif',
  },
});

const dateRangeTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#991B1F',
    },
    secondary: {
      main: '#429644',
    },
  },
  typography: {
    fontFamily: 'Fira Sans, sans-serif',
  },
});

function BatchJobHistory(props) {
  const classes = useStyles();
  let { microsoftId } = props;

  const toolbarEl = useRef(null);
  const [startDate, setStartDate] = useState(localStorage.getItem('StartDate') ? localStorage.getItem('StartDate') === 'null' ? null : new Date(localStorage.getItem('StartDate')).setHours(0, 0, 0, 0) : new Date(new Date().setHours(0, 0, 0, 0)).setMonth(new Date().getMonth() - 3));
  const [endDate, setEndDate] = useState(localStorage.getItem('EndDate') ? localStorage.getItem('EndDate') === 'null' ? null : new Date(localStorage.getItem('EndDate')).setHours(23, 59, 59, 999) : new Date().setHours(23, 59, 59, 999));
  const [tempStartDate, setTempStartDate] = useState(localStorage.getItem('StartDate') ? localStorage.getItem('StartDate') === 'null' ? null : new Date(localStorage.getItem('StartDate')).setHours(0, 0, 0, 0) : new Date(new Date().setHours(0, 0, 0, 0)).setMonth(new Date().getMonth() - 3));
  const [tempEndDate, setTempEndDate] = useState(localStorage.getItem('EndDate') ? localStorage.getItem('EndDate') === 'null' ? null : new Date(localStorage.getItem('EndDate')).setHours(23, 59, 59, 999) : new Date().setHours(23, 59, 59, 999));

  const [toolbarHeight, setToolbarHeight] = useState(0);
  const [customerId, setCustomerId] = useState(localStorage.getItem('CustomerID') || props.customerCompanyID || "")
  const [isLoading, setIsLoading] = useState(false);
  const [batchJobHistory, setBatchJobHistory] = useState([]);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const batchJobHistoryColumns = [
    { title: "Batch Job ID", field: "BatchJobID" },
    { title: "Job Description", field: "JobDescription", },
    { title: "Company", field: "Company", },
    { title: "Client ID", field: "ClientID" },
    { title: "Start Date Time", field: "StartDateTime", type: "datetime" },
    { title: "End Date Time", field: "EndDateTime", type: "datetime" },
    { title: "Status", field: "Status" },
  ]

  useEffect(() => {
    document.title = "Batch Job History | Vision Customer Portal";
    setToolbarHeight(toolbarEl.current?.clientHeight);
    return () => document.title = "Vision Customer Portal";
  });

  useEffect(() => {
    const handleResize = () => setToolbarHeight(toolbarEl.current.clientHeight);
    if (batchJobHistory.length > 0) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [batchJobHistory]);

  useEffect(() => {
    props.isCustomer && setCustomerId(props.customerCompanyID)
  }, [props.customerCompanyID, props.isCustomer]);

  useEffect(() => {
    loadBatchJobHistory();
    // eslint-disable-next-line
  }, [microsoftId]);

  const loadBatchJobHistory = (
    localStartDate = startDate,
    localEndDate = endDate,
  ) => {
    setIsLoading(true)
    setBatchJobHistory([])

    localStartDate = localStartDate || new Date('1/1/1900');
    localEndDate = localEndDate || new Date('1/1/2100');

    axios
      .get("/BatchJobHistory", {
        params: {
          startDate: localStartDate && new Date(localStartDate).toLocaleString('en-US', { timeZone: "UTC" }),
          endDate: localEndDate && new Date(localEndDate).toLocaleString('en-US', { timeZone: "UTC" }),
          CustomerID: customerId,
        }
      })
      .then((response) => {
        setBatchJobHistory(response.data)
      })
      .catch((error) => console.log(error))
      .then(() => {
        setIsLoading(false)
      })
  }

  const handleDefaultDateRange = () => {
    setTempStartDate(new Date(new Date().setHours(0, 0, 0, 0)).setMonth(new Date().getMonth() - 3));
    setTempEndDate(new Date().setHours(23, 59, 59, 999));
  }

  const handleStartDateChange = (date) => {
    setTempStartDate(date === null ? null : new Date(date).setHours(0, 0, 0, 0));
  }

  const handleEndDateChange = (date) => {
    setTempEndDate(date === null ? null : new Date(date).setHours(23, 59, 59, 999));
  }

  const handleClearDateRange = () => {
    setTempStartDate(null);
    setTempEndDate(null);
  }

  const handleRefresh = () => {
    localStorage.setItem("StartDate", tempStartDate === null ? null : new Date(tempStartDate).toLocaleDateString());
    localStorage.setItem("EndDate", tempEndDate === null ? null : new Date(tempEndDate).toLocaleDateString());

    setStartDate(tempStartDate);
    setEndDate(tempEndDate);

    loadBatchJobHistory(tempStartDate, tempEndDate)
  }

  let datesChanged = (tempStartDate === startDate && tempEndDate === endDate)
  let datesCleared = (tempStartDate === null && tempEndDate === null)
  let datesInvalid = (tempEndDate !== null && tempEndDate !== null && tempStartDate > tempEndDate)

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2} justify="space-between" alignItems="flex-end">
        <Grid item md={6} xl={3}>
          <Box display="flex" flexWrap="wrap" justifyContent="flex-start" alignItems="flex-end" flexGrow={1}>
            <Box px={1} flexGrow={1} className={classes.datePicker}>
              <KeyboardDatePicker
                autoOk
                clearable
                showTodayButton
                fullWidth
                id="start-date"
                label="Start Date"
                format="MM/dd/yyyy"
                maxDate={tempEndDate || undefined}
                maxDateMessage="Start Date cannot be later than End Date."
                value={tempStartDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'start date',
                }}
              />
            </Box>
            <Box px={1} flexGrow={1} className={classes.datePicker}>
              <KeyboardDatePicker
                autoOk
                clearable
                showTodayButton
                fullWidth
                id="end-date"
                label="End Date"
                format="MM/dd/yyyy"
                minDate={tempStartDate || undefined}
                minDateMessage="End Date cannot be earlier than Start Date"
                value={tempEndDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'end date',
                }}
              />
            </Box>
            <Box display="flex">
              <ThemeProvider theme={dateRangeTheme}>
                <Tooltip title={datesCleared ? "Default Date Range" : "Clear Date Range"}>
                  <Fab
                    size="small"
                    color={datesCleared ? "primary" : "inherit"}
                    className={classes.dateFab}
                    onClick={datesCleared ? handleDefaultDateRange : handleClearDateRange}
                    aria-label="clear date range"
                  >
                    {datesCleared ? <RestoreRoundedIcon /> : <ClearRoundedIcon />}
                  </Fab>
                </Tooltip>
                <Tooltip title={datesChanged ? "Refresh Cases" : "Retrieve Cases"}>
                  <Fab
                    disabled={datesInvalid}
                    size="small"
                    color={datesChanged ? "primary" : "secondary"}
                    className={classes.dateFab}
                    onClick={handleRefresh}
                    aria-label="clear date range"
                  >
                    {datesChanged ? <UpdateRoundedIcon /> : <ArrowForwardRoundedIcon />}
                  </Fab>
                </Tooltip>
              </ThemeProvider>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ThemeProvider theme={tableTheme}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MaterialTable
                  icons={tableIcons}
                  isLoading={isLoading}
                  options={{
                    exportButton: true,
                    exportAllData: true,
                    exportFileName: `Batch Job History ${new Date().toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" })}`,
                    filtering: true,
                    sorting: true,
                    draggable: false,
                    grouping: false,
                    pageSize: 20,
                    pageSizeOptions: [20,40,80],
                    paging: mdUp,
                    maxBodyHeight: mdUp ? `calc(100vh - ${toolbarHeight + 310}px)` : null,
                    emptyRowsWhenPaging: false,
                    doubleHorizontalScroll: mdUp,
                    showTitle: false,
                  }}
                  actions={[
                    {
                      icon: tableIcons.Refresh,
                      tooltip: 'Refresh Batch Job History',
                      isFreeAction: true,
                      onClick: () => {
                        loadBatchJobHistory();
                      },
                    },
                  ]}
                  components={{
                    Toolbar: (props) => (
                      <div
                        className={classes.grid}
                        ref={toolbarEl}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={3} md={6}>
                            <Typography variant="h4">Batch Job History</Typography>
                          </Grid>
                          <Grid item xs={12} sm={9} md={6}>
                            <MTableToolbar
                              classes={{
                                root: classes.toolbarRoot,
                                spacer: classes.toolbarSpacer,
                                actions: classes.toolbarActions,
                                searchField: classes.toolbarSearchField,
                              }}
                              {...props}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    ),
                    Header: (props) => (
                      <MTableHeader
                        classes={{
                          header: classes.headerHeader,
                        }}
                        {...props}
                      />
                    ),
                    Body: props => (<MTableBody {...props} onFilterChanged={(columnId, value) => {
                      props.onFilterChanged(columnId, value);
                    }} />),
                    Row: (props) => (
                      <MTableBodyRow
                        classes={{
                          root: classes.rowRoot,
                        }}
                        {...props}
                      />
                    ),
                  }}
                  columns={batchJobHistoryColumns}
                  data={batchJobHistory} />
              </Grid>
            </Grid>
          </ThemeProvider>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}

const mapStateToProps = state => {
  state.auth.microsoftId && localStorage.setItem('MicrosoftID', state.auth.microsoftId);
  return {
    isCustomer: state.auth.role === "customer",
    name: state.auth.authName,
    customerCompanyID: state.auth.authCompany,
    companyName: state.auth.authCompanyName,
    workspaceID: state.auth.workspace,
    microsoftID: state.auth.microsoftId,
  };
};
export default connect(mapStateToProps, null)(BatchJobHistory);