import React from "react";
import axios from "../VisionServices/axios";
import DateFnsUtils from "@date-io/date-fns";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardRoundedIcon from "@material-ui/icons/ArrowForwardRounded";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import CSMStatsCasesByStatus from "./CSMStatsCasesByStatus";
import CSMStatsCasesByOwner from "./CSMStatsCasesByOwner";
import CSMStatsLineChart from "./CSMStatsLineChart";
import CSMStatsBarChart from "./CSMStatsBarChart";
import ReactHtmlParser  from 'react-html-parser';

const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  innerContainer: {
    margin: 0,
    padding: "0 !important",
    alignContent: "flex-start",
  },
  paper: {
    padding: theme.spacing(2),
  },
  avatarImage: {
    margin: "auto",
    width: "100%",
    maxWidth: "128px",
    height: "auto",
    boxShadow: theme.shadows[2],
  },
  heading: {
    textAlign: "center",
    padding: theme.spacing(2),
  },
});

class CSMStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: localStorage.getItem('StartDate') ? localStorage.getItem('StartDate') : new Date(new Date().setHours(0,0,0,0)).setMonth(new Date().getMonth() - 3),
      endDate: localStorage.getItem('EndDate') ? localStorage.getItem('EndDate') :new Date().setHours(23,59,59,999),
      tempStartDate: localStorage.getItem('StartDate') ? localStorage.getItem('StartDate')  : new Date(new Date().setHours(0,0,0,0)).setMonth(new Date().getMonth() - 3),
      tempEndDate: localStorage.getItem('EndDate') ? localStorage.getItem('EndDate') : new Date().setHours(23,59,59,999),
      csmList: null,
      customerList: null,
      casesByStatusData: null,
      casesByOwnerData: null,
      lineChartData: null,
      barChartData: null,
      fullName: null,
      email: null,
      phone: null,
      address: null,
      city: null,
      stateCode: null,
      aboutMe: null,
      csmPhoto: null,
      csmName: null,
      csmAboutMe: null,
      csmId : "",
      customerName: null,
      customerId: "",
    };
  }

  componentDidMount() {
    document.title = "CSM Stats | Vision Customer Portal";
    this.loadCSMList()
    this.loadAllData()
  }

  componentWillUnmount() {
    document.title = "Vision Customer Portal";
  }

  loadCSMList = () => {
    axios
      .get("/CSMs")
      .then((response) => {
        this.setState({
          csmList: response.data,
        })
      })
      .catch((error) => console.log(error));
  }

  handleCSMChange = (event) => {
    var csmId = event.target.value;
    this.setState({
      customerId: "",
      customerName: null,
      customerList: null,
      csmPhoto: null,
      fullName: null,
      email: null,
      phone: null,
      address: null,
      csmId: csmId,
      csmName: csmId === "" ? null : event.nativeEvent.target.innerText,
    })
    if ( csmId ) {
      this.loadCustomerList(csmId);
      this.loadCSMProfile(csmId);
    }
    this.loadAllData(undefined,undefined,csmId);
  }

  loadCustomerList = (csmId) => {
    axios
      .get("CSMs/" + csmId + "/Accounts")
      .then((response) => {
        this.setState({
          customerList: response.data,
        })
      })
      .catch((error) => console.log(error));
  }

  handleCustomerChange = (event) => {
    var customerId = event.target.value;
    this.setState({
      customerId: customerId,
      customerName: customerId === "" ? null : event.nativeEvent.target.innerText,
    })
    this.loadAllData(undefined,undefined,this.state.csmId,customerId);
  }

  handleStartDateChange = (date) => {
    this.setState({
      tempStartDate: new Date(date).setHours(0,0,0,0)
    });
    // localStorage.removeItem('StartDate');
    // localStorage.setItem('StartDate', new Date(date).toLocaleDateString());
  };

  handleEndDateChange = (date) => {
    this.setState({
      tempEndDate: new Date(date).setHours(23,59,59,999)
    });
    // localStorage.removeItem('EndDate');
    // localStorage.setItem('EndDate', new Date(date).toLocaleDateString());
  };

  handleDateRange = () => {
    var tempStartDate = this.state.tempStartDate
    var tempEndDate = this.state.tempEndDate
    localStorage.setItem('StartDate', new Date(tempStartDate).toLocaleDateString());
    localStorage.setItem('EndDate', new Date(tempEndDate).toLocaleDateString());
    this.setState({
      startDate: tempStartDate,
      endDate: tempEndDate,
    })
    this.loadAllData(tempStartDate, tempEndDate, this.state.csmId, this.state.customerId);
  }

  loadAllData = (
    startDate=this.state.startDate,
    endDate=this.state.endDate, 
    csmId=this.state.csmId || "", 
    customerId=this.state.customerId || ""
  ) => {
    startDate = new Date(startDate).toLocaleString('en-US', {timeZone: "UTC"});
    endDate = new Date(endDate).toLocaleString('en-US', {timeZone: "UTC"});
    this.loadCasesByStatusData(startDate, endDate, csmId, customerId)
    this.loadCasesByOwnerData(startDate, endDate, csmId, customerId)
    this.loadLineChartData(startDate, endDate, csmId, customerId)
    this.loadBarChartData(startDate, endDate, csmId, customerId)
  }

  loadCSMProfile = (csmId) => {
    axios
    .get("/CSMs/" + csmId)
    .then((response) => {
        var csm = response.data;
        this.setState({
          csmPhoto: csm.image,
          fullName: csm.fullname,
          email: csm.internalemailaddress,
          phone: csm.mobilephone,
          address: csm.address1_composite,
          csmAboutMe: csm.hsal_aboutme,
        })
      })
      .catch((error) => console.log(error));
  }

  loadCasesByStatusData = (startDate,endDate,csmId,customerId) => {
    this.setState({ casesByStatusData: null });
    axios
      .get(
        "/CSMs/" + csmId + 
        "/cases/count?groupby=status" +
        "&startdate=" + startDate +
        "&enddate=" + endDate +
        "&customerid=" + customerId
      )
      .then((response) => {
        this.setState({ casesByStatusData: response.data })
      })
      .catch((error) => console.log(error));
  }

  loadCasesByOwnerData = (startDate,endDate,csmId,customerId) => {
    this.setState({ casesByOwnerData: null });
    axios
      .get(
        "/CSMs/" + csmId +
        "/cases/count?groupby=owner" + 
        "&startdate=" + startDate +
        "&enddate=" + endDate +
        "&customerid=" + customerId
      )
      .then((response) => {
        this.setState({ casesByOwnerData: response.data })
      })
      .catch((error) => console.log(error));
  }

  loadLineChartData = (startDate,endDate,csmId,customerId) => {
    this.setState({ lineChartData: null });
    axios
      .get(
        "/CSMs/" + csmId +
        "/cases/count?groupby=casetrend" + 
        "&startdate=" + startDate +
        "&enddate=" + endDate +
        "&customerid=" + customerId
      )
      .then((response) => {
        this.setState({ lineChartData: response.data })
      })
      .catch((error) => console.log(error));
  }

  loadBarChartData(startDate,endDate,csmId,customerId) {
    this.setState({ barChartData: null });
    axios
      .get(
        "/CSMs/" + csmId + 
        "/cases/count?groupby=priority" +
        "&startdate=" + startDate +
        "&enddate=" + endDate +
        "&customerid=" + customerId
      )
      .then((response) => {
        this.setState({ barChartData: response.data })
      })
      .catch((error) => console.log(error));
  }

  render() {
    const { classes } = this.props;

    return (
      <Container component="main" maxWidth={false} className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth size="small" variant="filled">
              <InputLabel shrink id="csm-label">CSM</InputLabel>
              <Select
                labelId="csm-label"
                id="csm"
                displayEmpty
                defaultValue=""
                onChange={this.handleCSMChange}
              >
                <MenuItem value="">
                  <em>All CSM's</em>
                </MenuItem>
                {this.state.csmList?.map((csm, index) => (
                  <MenuItem key={index} value={csm.Id}>
                    {csm.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={3}>
            <Fade in={!!this.state.customerList} mountOnEnter unmountOnExit>
              <FormControl fullWidth size="small" variant="filled">
                <InputLabel shrink id="customer-label">Customers</InputLabel>
                <Select
                  labelId="customer-label"
                  id="customers"
                  displayEmpty
                  defaultValue=""
                  onChange={this.handleCustomerChange}
                >
                  <MenuItem value="">
                    <em>All Customers</em>
                  </MenuItem>
                  {this.state.customerList?.map((customer, index) => (
                    <MenuItem key={index} value={customer.Id}>
                      {customer.Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Fade>
          </Grid>
          <Hidden smDown>
            <Grid item md={1} lg={2}>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={5} lg={4}>
            <Box display="flex" justifyContent="flex-start">
              <Box px={1} flexGrow={1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    maxDate={this.state.tempEndDate}
                    fullWidth
                    id="start-date"
                    label="Start Date"
                    value={this.state.tempStartDate}
                    onChange={this.handleStartDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box px={1} flexGrow={1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    minDate={this.state.tempStartDate}
                    fullWidth
                    id="end-date"
                    label="End Date"
                    value={this.state.tempEndDate}
                    onChange={this.handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box>
                <Tooltip title="Set Date Range">
                  <IconButton
                    onClick={() => this.handleDateRange()}
                    className={classes.alignIconButton}
                    aria-label="filter date range"
                  >
                    <ArrowForwardRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} container className={classes.innerContainer} spacing={2}>
            {!!this.state.fullName && (
              <Fade in={!!this.state.fullName}>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Avatar
                          variant="rounded"
                          className={classes.avatarImage}
                          src={this.state.csmPhoto}
                          alt={this.state.fullName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h6" gutterBottom>
                          {this.state.fullName}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          Cell: {this.state.phone}
                          <br />
                          Email: {this.state.email}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {this.state.address}
                        </Typography>
                        <Typography variant="body2">
                          { ReactHtmlParser(this.state.csmAboutMe) }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Fade>
            )}
            <Grid item xs={12}>
              <Paper>
                <Typography className={classes.heading} variant="h6" component="h6">
                  Cases by Status
                </Typography>
                <CSMStatsCasesByStatus 
                  tableData={this.state.casesByStatusData}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  csmName={this.state.csmName}
                  customerName={this.state.customerName}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <CSMStatsCasesByOwner 
                tableData={this.state.casesByOwnerData}
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                csmName={this.state.csmName}
                customerName={this.state.customerName}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8} container className={classes.innerContainer} spacing={2}>
            <Grid item xs={12}>
              <Paper className={classes.title}>
                <Typography className={classes.heading} variant="h6" component="h6" >
                  Case Trend
                </Typography>
                  <CSMStatsLineChart 
                    tableData={this.state.lineChartData}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    csmName={this.state.csmName}
                    customerName={this.state.customerName} 
                  />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.titlebarchart}>
                <Typography className={classes.heading} variant="h6" component="h6" >
                  Priority
                </Typography>
                <CSMStatsBarChart 
                  tableData={this.state.barChartData}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  csmName={this.state.csmName}
                  customerName={this.state.customerName} 
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(useStyles)(CSMStats);